// ========================================= //
// Magento/ checkoutCartItems
// ========================================= //

.checkoutCartItems {

    // E L E M E N T S
    ul {
        padding-left: 0;
        list-style: none;
        margin: 0;
        li {
            padding-left: 0;
            &::before {
                content: none;
            }
        }
    }

    .table-wrapper {
        width: 100%;
        overflow-y: auto;
        margin-bottom: rem(40);

        &::-webkit-scrollbar
        {
            -webkit-appearance: none;
            width: 12px;
            height: 12px;
        }

        &::-webkit-scrollbar-thumb
        {
            border-radius: 8px;
            border: 3px solid $white;
            background-color: $corail;
        }
    }

    .table-caption {
        display: none;
    }

    .items {

        thead th {
            white-space: nowrap;
            text-align: left;
        }

        .checkoutCartItem__quantity {
            min-width: 9rem;
        }

    }

    // M E D I A   Q U E R I E S
    @include bp-gt(768px)
    {

    };

    @include bp-gt(1024px)
    {

    };

    @include bp-gt(1440px)
    {

    };

}
