// ========================================= //
// alert
// ========================================= //

.alert-box {
    max-width: $layoutWidth;
    border: none;
    border-radius: rem(5);
    background: $mainBranding !important;
    min-height: rem(75);
    margin: 0 auto;
    margin-bottom: rem(20);

    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-start;

    &:before {
        content: '\EA20';
        position: absolute;
        border-radius: 50%;
        border: rem(2) solid $white;
        display: block;
        width: rem(50);
        height: rem(50);
        padding: rem(1) rem(8);
        font-size: rem(30);
        font-family: "iconfont";
        color: $white;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    /*&.success { background: $altBranding; }
    &.alert { background: $corail; }*/

    &.success:before { content: '\EA1F'; }
    &.error:before { content: '\EA1E'; }
    &.notice:before { content: '\EA1E'; }
    &.alert:before { content: '\EA1E'; }

    &.secondary {
        color: $white;

        &:before {
            content: '\EA20';
        }
    }

    .c-fonticon__icon--delete {
        color: $white;
    }

    .c-wysiwyg {
        padding-left: rem(50);
    }

    .close {
        opacity: 1;
    }

    ul {
        margin-bottom: 0 !important;
        color: $white;

        li:last-of-type {
            margin-bottom: 0 !important;
        }

        li:before {
            display: none !important;
        }
    }
}
