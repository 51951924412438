// ========================================= //
// tabPro
// ========================================= //

.c-tabs__pro {
    position: fixed;
    z-index: $zindex--fixed;
    top: 50%;
    right: 0;
    width: rem(705);
    background: $subBranding;
    transform: translateY(-50%) translateX(100%);
    transition: $ease;
    box-shadow: rem(1) 0 rem(7) rgba($black, .35);

    @include bp-lt($tablet) {
        width: 80%;
    }

    @include bp-lt($small) {
        display: none;
    }

    &.is-visible {
        transform: translateY(-50%) translateX(0);
    }
}

.c-product__nav + .c-tabs__pro {
    transform: translateY(rem(-30)) translateX(100%);

    @include bp-lt($small) {
        top: 45%;
    }

    &.is-visible {
        transform: translateY(rem(-30)) translateX(0);
    }
}

.c-tabs__pro--inner {
    position: relative;
}

.c-tabs__pro__toggle {
    position: absolute;
    width: rem(51);
    height: rem(244);
    transform: translateX(rem(-51));
    background: $white;
    box-shadow: rem(-2) 0 rem(2) rgba($black, .05);
    cursor: pointer;

    @include bp-lt($tablet) {
        transform: translateX(rem(-50));
    }

    .c-title {
        position: absolute;
        width: rem(244);
        height: rem(51);
        text-align: center;
        transform: rotate(-90deg) translateX(-100%);
        transform-origin: 0 0 0;
        font-family: $maven;
        font-weight: 700;
        font-size: rem(14);
        color: $colorFont;
        letter-spacing: rem(1);
        text-transform: uppercase;
    }

    [class*='c-fonticon__icon'] {
        position: relative;
        top: rem(2);
        color: $corail;
        font-size: rem(18);
        margin-right: rem(20);
    }
}

.c-tabs__pro__holder {
    padding: rem(34) rem(40);
    position: relative;
    z-index: 30;

    @include bp-lt($small) {
        height: 50vh;
        overflow-x: hidden;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        overflow-scrolling: touch;
        -ms-overflow-style: none;
        &::-webkit-scrollbar { width: rem(6) !important }

        &::-webkit-scrollbar
        {
            -webkit-appearance: none;
            width: rem(14);
            height: rem(8);
        }

        &::-webkit-scrollbar-thumb
        {
            border-radius: rem(8);
            border: none;
            background-color: rgba($mainBranding, .6);
        }
    }

    p {
        text-align: center;
        color: $white;
    }

    .c-wysiwyg {
        margin-bottom: rem(35);

        *:last-of-type { margin-bottom: 0; }
    }

    .c-cta {
        text-align: center;
        margin-bottom: rem(48);

        @include bp-lt($small) {
            margin-bottom: rem(24);
        }

        [class*='c-button'] {
            margin-left: rem(8);
            margin-right: rem(8);
        }
    }
}

.c-tabs__pro__close {
    $side : 38;

    position: absolute;
    z-index: 40;
    top: 0;
    left: 0;
    display: block;
    width: #{$side}px;
    height: #{$side}px;
    background: url(#{$img}c-icon__tabs--close.svg) no-repeat $mainBranding--lighten--03;
    font-size: 0;
    transition: $ease;
    cursor: pointer;

    &:hover {
        background-color: $mainBranding;
    }
}
