// ========================================= //
// global
// ========================================= //

$img                    : '../../media/images/' !default;
$bezierValue            : cubic-bezier(.7, 0, .175, 1);
$bezier                 : all .4s $bezierValue;
$ease                   : all .4s ease;
$ease--long             : all .6s ease;
$layoutWidth            : 78.5em !default;       //1256px
$gutter                 : rem(10);

$border--grey--02 : solid #{rem(1)} $grey--02;
$border--grey--03 : solid #{rem(1)} $grey--03;

$grid-columns:        12 !default;
$grid-gutter-width:   10px !default;
$grid-row-columns:    6 !default;

@mixin sr-only {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important; // Fix for https://github.com/twbs/bootstrap/issues/25686
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
}

@mixin make-row($gutter: $grid-gutter-width) {
    display: flex;
    flex-wrap: wrap;
    margin-right: -$gutter / 2;
    margin-left: -$gutter / 2;
}

@mixin make-col($size, $columns: $grid-columns) {
    flex: 0 0 percentage($size / $columns);
    max-width: percentage($size / $columns);
}

@mixin make-col-offset($size, $columns: $grid-columns) {
    $num: $size / $columns;
    margin-left: if($num == 0, 0, percentage($num));
}