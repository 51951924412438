// ========================================= //
// reassurance
// ========================================= //

.c-reassurance {
    padding-top: rem(30);
    padding-bottom: rem(30);
    background: $mainBranding;

    .columns.medium-3 {
        border-left: solid rem(1) $mainBranding--darken--02;
        border-right: solid rem(1) $mainBranding--lighten--01;

        @include bp-lt($tablet) { width: 50%; }

        @include bp-lt($mobileL) {
            width: 100%;
            border-left: none;
            border-right: none;
            border-bottom: solid rem(1) $mainBranding--lighten--01;
            border-top: solid rem(1) $mainBranding--darken--01;
        }


        &:nth-child(1), &:nth-child(2) {
            @include bp-lt($tablet) {
                border-bottom: solid rem(1) $mainBranding--lighten--01;
            }
        }

        &:nth-child(3), &:nth-child(4) {
            @include bp-lt($tablet) {
                border-top: solid rem(1) $mainBranding--darken--01;
            }
        }

        &:first-of-type {
            border-left: none;

            @include bp-lt($mobileL) {
                border-top: none;
            }
        }

        &:last-of-type {
            border-right: none;

            @include bp-lt($mobileL) {
                border-bottom: none;
            }
        }
    }
}

.c-reassurance__item {
    height: rem(80);
    text-align: center;

    @include bp-lt($mobileL) {
        text-align: left;
        padding-left: rem(80);
    }
}

.c-reassurance__item--icon,
.c-reassurance__item--content {
    display: inline-block;
    vertical-align: middle;
}

.c-reassurance__item--icon {
    margin-right: rem(24);

    @include bp-lt($medium) {
        margin-right: rem(14);
    }

    @include bp-lt($mobileL) {
        max-width: rem(49);
    }
}

.c-reassurance__item--content {
    text-align: left;

    p,
    span,
    strong,
    a {
        color: $white;
        text-align: left;
    }

    p {
        display: block;
        font-family: $oswald;
        font-size: rem(18);
        font-weight: 300;
        letter-spacing: 0.5px;
        margin: 0;

        display: flex;
        flex-flow: column wrap;
        align-items: flex-start;
    }

    strong,
    a {
        font-family: $maven;
        font-size: rem(14);
        font-weight: 700;
        letter-spacing: rem(.6);
        text-transform: uppercase;
        display: block;
    }

    strong {
        @include bp-lt($medium) {
            font-size: rem(12);
        }
    }

    a {
        position: relative;
        display: inline-block;

        @include links($white, $white, 1);
    }
}
