// ========================================= //
// keyframesList
// ========================================= //

@include keyframes(displayNoneToFadeIn) {
    0% {
        display: none;
        opacity: 0;
    }
    0.001% {
        display: block;
        opacity: 0;
        transform: translateY(0);
    }
    100% {
        display: block;
        opacity: 1;
        transform: translateY(10);
    }
}
