// ========================================= //
// productGrid
// ========================================= //

.c-grid__product {
    margin-bottom: rem(48);

    .column+.column:last-child,
    .columns+.column:last-child,
    .column+.columns:last-child,
    .columns+.columns:last-child {
        float: left;
    }

    ol.products.product-items {
        list-style: none;
    }

}

#layer-product-list .row.c-grid__product.products.wrapper.grid.products-grid {
    @media screen and (max-width: 1200px){
        padding: 0 16px;
    }
}

.c-grid__product__item .product-item-details {
    width: 100%;
}


@media all and (min-width: 768px), print {
    div#layered-horizontal-container {
        margin-bottom: 0;
    }
}

.c-grid__product--related {
    margin-bottom: 0;
    padding-bottom: rem(40);

    &.row .row {
        @media screen and (max-width: 1135px){
            margin: 0;
        }
    }

    .columns {
        width: 20%;

        @include bp-lt($medium) {
            width: 25%;
        }

        @include bp-lt($tablet) {
            width: percentage(1/3);
        }

        @include bp-lt($small) {
            width: 50%;
        }

        + .columns:last-of-type {
            @include bp-lt($medium) {
                display: none;
            }
        }

        &:nth-child(4) {
            @include bp-lt($tablet) {
                display: none;
            }

            @include bp-lt($small) {
                display: block;
            }
        }
    }

    .c-grid__product__item--hover {
        @include bp-gt($medium) {
            padding-top: rem(15);
            padding-bottom: rem(15);
        }
    }

    .c-grid__product__item--quantity .c-title {
        font-size: rem(12);
    }

    .c-grid__product__item--quantity,
    .c-grid__product__item--addToCart {
        // height: rem(45);
    }
}

.catalog-category-view {
    .please-wait {
        position: absolute;
        width: 100%;
        height: 100%;
        text-align: center;
        top: 50%;
        transform: translateY(50%);
        z-index: 999;
    }
}
