// ========================================= //
// content
// ========================================= //

@include bp-lt($small) {
    .account-login .buttons-set {
        display: flex;
        flex-direction: column;
        align-items: center;

        a.f-left {
            order: 2;
        }
    }
}

@include bp-lt($small) {
    .account-login .buttons-set {
        .button,
        a.f-left {
            width: 245px;
        }
    }
}

.customer-account-login {
    .c-page__header--account--bkgCorail {
        padding-top: rem(20);
    }

    .c-section__title {
        margin-bottom: rem(20);
        padding-bottom: 0;

        &:before {
            display: none;
        }
    }

    .account-login .content {
        padding-top: rem(10);
        padding-bottom: rem(10);
    }

    .buttons-set {
        padding-top: rem(20);

        button.button {
            padding-top: 0;
            padding-bottom: 0;
            width: 245px;
        }
    }

    .account-login h2 {
        font-size: rem(15);
        margin-left: 0;
        margin-bottom: rem(21);
    }

    .account-login .content p.required {
        margin-bottom: 0;
    }
}

.customer-account-forgotpassword .wrapper {
    background: #fff;
}

.customer-account-forgotpassword .c-page__content--noPadding--marginBtm {
    margin-bottom: 200px;

    @include bp-lt($small){
        margin-bottom: 120px;
    }
}

.customer-account-create {
    .wide,
    .row .columns {
        padding: 0;
    }

    .fields.taxvat > .row {
        margin: 0;
    }

    #maincontent .columns.medium-6 {
        width: 100%;
        padding: 0;
    }

    .buttons-set {
        position: static;
    }

    .form-list .c-input__holder p {
        font-size: rem(9);
        color: #000000;
        letter-spacing: rem(0.27px);
    }

    .c-input__holder.type_boutique {
        margin-bottom: rem(45);
    }

    .form-list .field.name-prefix {
        max-width: rem(150);

        @include bp-lt($small){
            max-width: 100%;
        }
    }

    #maincontent .columns.medium-6 {
        width: 100%;
        padding: 0;
    }

    .fieldset.c-condition__check {
        border: 0;
        padding: 0 rem(50);

        @include bp-lt($small) {
            padding: 0 rem(20);
        }

        + .fieldset.c-condition__check {
            padding-top: rem(25);
            padding-bottom: rem(25);
        }

        label,
        .c-input__holder,
        .field {
            margin-bottom: 0;
        }

        .control label,
        .columns p,
        label {
            font-size: rem(15px);
            color: #000000;
            font-family: $maven;
            letter-spacing: rem(0.45px);
            font-weight: 400;

            a {
                color: $corail;
            }
        }
    }

    .account-create .legend, .account-create h2, .account-login .legend, .account-login h2 {
        font-size: rem(24px);
        color: #463B41;
        letter-spacing: rem(1.6px);
        margin-left: 0;
    }

    .form-list {
        input[type="checkbox"]:checked+label,
        input[type="checkbox"]:not(:checked)+label,
        input[type="radio"]:checked+label,
        input[type="radio"]:not(:checked)+label {
            letter-spacing: rem(0.45px);
        }
    }

    .check-newsletter p {
        font-size: rem(12px) !important;
        color: #463B41;
        letter-spacing: rem(0.36px);
        line-height: rem(20px);
        margin-top: rem(20);
    }

    div.o-page--bkgGrey--paddingBtm,
    div.o-page--bkgGrey {
        background-color: $white;
    }

    .c-page__header--account--bkgCorail {
        padding: 0;
        background: none;
    }

    .c-section__title {
        position: relative;
        text-transform: uppercase;
        color: $purple--dark !important;
        text-align: center;
        letter-spacing: rem(8);
        font-size: rem(30);
        margin: 0;
        padding: rem(13) 0 0;
        padding-bottom: rem(20px) !important;
        margin-bottom: rem(46px);

        @include bp-lt($medium) { font-size: rem(28); }
        @include bp-lt($small) { font-size: rem(26); }
        @include bp-lt($mobileL) { font-size: rem(22); }

        &::before {
            display: none;
        }

        &:after {
            content: '';
            position: absolute;
            left: 50%;
            bottom: 0 !important;
            transform: translateX(-50%);
            display: block;
            width: rem(50);
            height: rem(1);
            background: $corail;
        }
    }

    .c-page__content--noPadding--marginBtm {
        margin: 0 auto;
        border: none;
        max-width: rem(760);
    }

    .fieldset.c-condition__check,
    .fieldset {
        border-left: solid .062rem #ebe7e7;
        border-right: solid .062rem #ebe7e7;

        &:first-of-type {
            border-top: solid .062rem #ebe7e7;
        }

        &.last {
            border-bottom: 0;
            padding-bottom: 1px;
        }
    }

    p.required {
        border-left: solid .062rem #ebe7e7;
        border-right: solid .062rem #ebe7e7;
        border-bottom: solid .062rem #ebe7e7;
        font-size: rem(12px);
        font-family: $maven;
        color: #000000;
        letter-spacing: rem(0.36px);
        text-align: left;
        padding: 0 rem(50);
        margin: 0;
        padding-bottom: rem(40);

        @include bp-lt($small) {
            padding: 0 rem(20);
            padding-bottom: rem(50);
        }
    }

    .buttons-set .back-link a.back-link {
        background: #F9F9F9;

        &:hover {
            background: $purple--dark
        }
    }
}

.customer-account-createpassword,
.contact-index-index{
    .c-contact__content form .columns{
        width: 100%;
        padding: 0;
    }

    .columns.c-condition__check {
        border: 0;
        padding: 0 rem(50);

        @include bp-lt($small) {
            padding: 0 rem(20);
        }

        label,
        .c-input__holder,
        .field {
            margin-bottom: 0;
        }

        .control label,
        .columns p,
        label {
            font-size: rem(15px);
            color: #000000;
            font-family: $maven;
            letter-spacing: rem(0.45px);
            font-weight: 400;

            a {
                color: $corail;
            }
        }
    }

    .c-form__holder .c-input__holder .c-label label,
    .form-list .c-input__holder label {
        font-family: $maven;
        font-size: rem(10);
        color: #000000;
        font-weight: 400;
        letter-spacing: rem(0.71px);
        white-space: normal;
        text-align: left;
        left: rem(21);
    }
}

.customer-account-createpassword form.form.password.reset {
    width: 100%;
}

.contact-index-index #contact-form .c-condition__check {
    margin-bottom: rem(20);
}

.contact-index-index #contact-form .c-info {
    margin-bottom: rem(20);
}