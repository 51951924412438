.o-section--bkgGradientCorail.c-cta__pro {
    position: relative;
    padding: 38px 0 42px;

    &::after {
        content:'';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background-image: url(#{$img}refonte/c-bkg--footer.png);
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: cover;
        pointer-events: none;
    }

    .c-section__title--white {
        margin-bottom: 30px;
    }

    .c-wysiwyg {
        margin-bottom: rem(30);
    }
}

footer .o-socials {
    padding: 0;
    margin: 0;
    margin-left: 60px;
    margin-top: 15px;

    @include bp-lt($medium){
        margin-left: 0;
        text-align: center;
    }

    @include bp-lt($tablet){
        padding-left: 60px;
    }

    @include bp-lt($mobileXl){
        padding-left: 0;
    }

    li {
        display: inline-block;
        font-size: 24px;
        margin-left: 10px;
        line-height: 1;

        a {
            color: $corail;
            transition: $ease;

            &:hover {
                opacity: 0.75;
            }
        }

        &:first-child {
            margin-left: 0;
        }
    }
}