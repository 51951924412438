// ========================================= //
// header
// ========================================= //

.o-header {
    position: relative;
    z-index: $zindex--hover + 1;

    @include bp-lt($medium) {
        z-index: initial;
    }
}

.c-header__logo,
.c-header__customer,
[class*='c-header__customer__item'] {
    height: rem($headerMainH);

    @include bp-lt($tablet) {
        height: rem($headerMainH--tablet);
    }

    @include bp-lt($mobileXl) {
        height: rem($headerMainH--mobileXl);
    }
}

.c-header__topline {
    // height: rem($topLineH);
    background: $mainBranding;
    color: $white;
    text-align: center;

    @include bp-lt($small) {
        text-align: left;
    }
}

.c-header__topline--info {
    display: inline-block;
    // height: rem($topLineH);
    vertical-align: middle;
    padding-top: rem(10);
    padding-bottom: rem(10);

    @include bp-lt($small) {
        width: calc(100% - 70px);
        padding-left: $gutter;
        padding-right: $gutter;
    }

    &, p {
        text-align: center;
        font-family: $maven;
        font-weight: 700;
        text-transform: uppercase;
        font-size: rem(13);
        letter-spacing: rem(1);

        @include bp-lt($small) {
            text-align: left;
            font-size: rem(11);
        }
    }

    p {
        margin: 0;
        line-height: 1.5;
    }

    a {
        position: relative;
        display: inline-block;
        color: $white;
        @include links($white, $white, 1);
    }
}

.c-header__main {
    position: relative;
    border-bottom: $border--grey--02;
    @include clearfix;

    &.is-logged {
        .c-header__logo {
            position: absolute;
            left: 50%;
            top: 0;
            transform: translateX(-50%);
            width: calc(100% - (#{$headerCustomerW * 2}px));

            @include bp-lt($desktop) {
                position: static;
                transform: translateX(0);
                width: rem(200);
            }

            @include bp-lt($tablet) {
                text-align: left;
            }

            .o-table {
                display: flex;
                flex-flow: row wrap;
                align-items: center;
                justify-content: center;

                @include bp-lt($medium) {
                    justify-content: flex-start;
                }
            }
        }

        .c-header__customer {
            width: rem($headerCustomerW);
            transition: $ease;

            @include bp-lt($large) { width: rem($headerCustomerW--large); }
            @include bp-lt($tablet) { width: rem($headerCustomerW--tablet); }
            @include bp-lt($mobileXl) { width: rem($headerCustomerW--mobileXl); }
        }

        [class*='c-header__customer__item'] {
            width: percentage(1/3);

            @include bp-lt($tablet) {
                width: 50%;
            }
        }
    }
}

.c-header__logo {
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    @include bp-lt($medium) {
        margin-left: rem(20);
        margin-right: initial;
    }

    @include bp-lt($mobileXl) {
        text-align: left;
    }

    h1.o-table__cell--valignMiddle {
        margin: 0;

        @include bp-gt($tablet) {
            margin: 0;
        }
    }

    img {
        @include bp-lt($tablet) {
            height: rem($headerMainH--tablet - 20);
            position: relative;
            top: rem(-2);
        }

        @include bp-lt($mobileXl) {
            height: rem($headerMainH--mobileXl - 20);
        }
    }
}

.c-header__customer {
    float: right;
    border-left: $border--grey--03;
}

[class*='c-header__customer__item'] {
    float: left;
    text-align: center;

    &[class*='--resume'] {
        position: relative;
        font-family: $maven;
        font-size: rem(14);
        line-height: rem(19);
        color: $colorFont;
        letter-spacing: rem(.5);

        @include bp-lt($tablet) {
            display: none;
        }

        &:after {
            content: '';
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            display: block;
            width: rem(1);
            height: rem(48);
            background: $grey--03;
        }

        strong {
            display: block;
            font-size: rem(16);
            font-weight: 700;
        }

        span {
            display: inline-block;
            margin-bottom: rem(14);
        }

        a {
            position: relative;
            display: inline-block;
            color: $colorLink;
            @include links($colorLink, $colorLink, 1);
        }
    }

    &[class*='--account'],
    &[class*='--cart'] {
        [class*='c-fonticon__icon'] {
            font-size: rem(21);
            color: $colorFont;
            margin-bottom: rem(12);

            @include bp-lt($mobileXl) {
                margin-bottom: 0;
                position: relative;
                top: rem(5);
            }
        }

        .c-label {
            font-family: $maven;
            font-size: rem(12);
            color: $colorFont;
            text-transform: uppercase;
            font-weight: 700;
            letter-spacing: rem(.5);

            @include bp-lt($mobileXl) {
                display: none;
            }

            span {
                position: relative;
                display: inline-block;

                &:after {
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: rem(-10);
                    width: 100%;
                    height: rem(1);
                    background: $mainBranding;
                    opacity: 0;
                    transition: $ease;
                    pointer-events: none;
                }
            }
        }

        &:hover {
            .c-label span:after {
                opacity: 1;
                bottom: 0;
            }
        }
    }

    &[class*='--cart'] {
        border-left: $border--grey--03;

        [class*='c-fonticon__icon'] {
            position: relative;

            .c-count {
                $side : 17;
                $borderRadius : rem($side / 2);

                position: absolute;
                bottom: rem(-3);
                right: rem(-7);
                display: block;
                min-width: rem($side);
                height: rem($side);
                padding-left: rem(5);
                padding-right: rem(5);
                color: $white;
                font-family: $maven;
                font-size: rem(12);
                line-height: rem($side);
                text-align: center;
                background: $subBranding;
                border-radius: $borderRadius;
            }
        }
    }
}

.c-header__navSearch {
    position: relative;
    border-bottom: $border--grey--02;
    background: $white;
    @include clearfix;

    .o-nav,
    .c-search {
        height: rem($navH);
    }

    .o-nav {
        $y : calc((100% - #{$layoutWidth}) / 2);

        float: left;
        margin-left: $y;

        @include bp-lt($desktop) {
            margin-left: 0;
        }
    }

    .c-search {
        float: right;
        width: 100%;
        border-left: $border--grey--02;

        @include bp-lt($large) { width: 100%; }
        @include bp-lt($mobileL) { width: 100%; }
    }

    .c-logo__small {
        display: none;
        width: rem($navH--isStuck);
        height: rem($navH--isStuck);
        float: left;
    }

    #search_mini_form {
        width: 100%;

        @include bp-gt($tablet) {
            width: 100%;
        }
    }

    .block-search {
        float: right;
        width: rem($headerCustomerW);
        padding-left:0;

        @include bp-lt($large) { width: rem($headerCustomerW--large) }
        @include bp-lt($tablet) { width: calc(100% - #{rem($navH)}) }
    }

    .c-account__shortcut {
        display: none;
        float: right;
        width: rem(2*$navH--isStuck);

        [class*='c-header__customer__item'] {
            &[class*='--cart'] {
                .c-label {
                    display: none;
                }
            }
        }
    }

    .c-search .c-search__input {
        &[aria-haspopup="false"] + .search-autocomplete {
            border-bottom: 0;
        }
    }

    &.is-stuck {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: rem($navH--isStuck);
        z-index: 101;
        padding-left: calc((100% - 1256px) / 2);
        padding-right: calc((100% - 1256px) / 2);

        .c-logo__small {
            display: block;
        }

        .c-search,
        .c-search .c-search__submit,
        .c-search .c-search__input,
        .o-nav .c-nav__level0>li>a,
        .c-nav__icon {
            height: rem($navH--isStuck);
        }

        .c-nav__icon span {
            top: rem(25);
        }

        .c-nav__megaMenu {
            top: rem($navH--isStuck);
        }

        .c-search {
            width: 100%;
            border-right: $border--grey--02;
            border-bottom: $border--grey--02;

            @include bp-lt($mobileL) { width: 100%; }
        }

        .block-search {
            float: right;
            width: calc(36% - #{rem(2*$navH--isStuck)} - #{rem($navH--isStuck)});

            padding-left: 0;

            @include bp-lt($large) { width: calc(44% - #{rem(2*$navH--isStuck)} - #{rem($navH--isStuck)}); }
            @include bp-lt($desktop) { width: calc(39% - #{rem(2*$navH--isStuck)} - #{rem($navH--isStuck)}); }
            @include bp-lt($medium) { width: calc(100% - #{rem(2*$navH--isStuck)} - #{rem($navH--isStuck)} - #{rem($navH)}); }
        }

        .form .minisearch {
            width: 100%;
        }

        .c-search .c-search__submit {
            width: #{$navH--isStuck}px;

            @include bp-lt($small) {
                width: #{43}px;
            }
        }

        .c-search .c-search__input {
            width: calc(100% - #{$navH--isStuck}px);

            @include bp-lt($small) {
                width: calc(100% - #{43}px);
                padding-left: 0;
            }

            &[aria-haspopup="false"] + .search-autocomplete {
                border-bottom: 0;
            }
        }

        .c-account__shortcut {
            display: block;
        }

        [class*='c-header__customer__item'] {
            width: rem($navH--isStuck);
            height: rem($navH--isStuck);
            border: none;

            [class*='c-fonticon__icon'] {
                margin-bottom: 0;
            }
        }
    }
}

.c-header__navSearch.is-stuck {
    position: fixed;
    top: 0;
    left: 0;
    transform: translateY(-100%);
    z-index: 110;

    &.is-visible {
        transition: $ease;
        transform: translateY(0);
    }
}