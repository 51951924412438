// ========================================= //
// pageContent
// ========================================= //

[class*='c-page__content'] {
    max-width: rem(1060);
    width: 100%;
    padding: rem(24) rem(44) rem(48);
    margin-left: auto;
    margin-right: auto;
    background: $white;
    border: solid rem(1) $grey--08;
	margin-bottom: rem(50);
	
    @include bp-lt($medium) {
        width: 90%;
    }

    &[class*='--noPadding'] {
        padding: 0;
    }

    &[class*='--marginBtm'] {
        margin-bottom: rem(110);
    }
}

.c-page__error--content,
.c-page__emptyCart--content {
    padding-top: rem(48);

    img {
        margin-bottom: rem(72);
    }

    .c-wysiwyg {
        margin-bottom: rem(48);
    }
}

.c-page__error--content {
    img {
        position: relative;
        left: rem(22);
    }
}

.c-category__content {
    .c-grid__product {
        margin-bottom: 0;
    }

    .c-grid__product__item {
        margin-bottom: rem(60);

        @include bp-lt($medium) { margin-bottom: rem(40); }
        @include bp-lt($small) { margin-bottom: rem(20); }
    }
}


.c-page__maintenance {
    padding-top: rem(96);

    img {
        display: inline-block;
        margin-bottom: rem(24);
    }

    [class*="c-section__title"] {
        margin-bottom: rem(48);
    }
}
