// ========================================= //
// Magento/ table
// ========================================= //

table.table {

    th {
        text-align: left;
        white-space: nowrap;
    }

    .col {
        width: auto;
    }

    .actions {

        font-size: rem(13);
        line-height: 1;

        ul {
            padding-left: 0;
            list-style: none;
            margin: 0;

            li {
                margin-bottom: 0;
                padding-left: 0;

                &::before { content: none; }
            }
        }

        .action {
            display: flex;
            align-items: center;

            color: $white;
            text-decoration: none;

            &:hover, &:focus {
                span {
                    text-decoration: underline;
                }
            }

            span {
                color: black;
            }

            &::before {
                font-family: "iconfont";
                font-style: normal;
                font-weight: normal;
                line-height: 1;
                color: $red;
                width: 1.5em;
            }

            &.view::before   { content: "\EA41"; }
            &.print::before  { content: "\EA40"; }
            &.track::before  { content: "\EA38"; }
            &.edit::before   { content: "\EA28"; }

            &.order::before  { content: "\EA39"; }
            &.delete::before { content: "\EA1E"; }
        }
    }

    // M E D I A   Q U E R I E S
    @include bp-gt(768px)
    {

    };

    @include bp-gt(1024px)
    {

    };

    @include bp-gt(1440px)
    {

    };

}
