.account .files {
    > p {
        font-size: rem(18);
        font-family: $fontBody;
        font-weight: 700;
        position: relative;
        text-align: center;
        padding-bottom: 15px;

        &::after {
            content: '';
            position: absolute;
            left: 50%;
            bottom: 0;
            -ms-transform: translateX(-50%);
            transform: translateX(-50%);
            display: block;
            width: 1.937rem;
            height: .125rem;
            background: #ce5959;
        }
    }

    .table-wrapper {
        margin-bottom: rem(40);
        width: 100%;
        overflow-y: auto;
        scrollbar-width: thin;
        scrollbar-color: $corail $white;

        &::-webkit-scrollbar
        {
            -webkit-appearance: none;
            width: 12px;
            height: 12px;
        }

        &::-webkit-scrollbar-thumb
        {
            border-radius: 8px;
            border: 3px solid $white;
            background-color: $corail;
        }
    }

    table {
        width: 100%;
        background-color: $white;
        margin-bottom: 0;

        @include bp-lt($small){
            min-width: 600px;
        }

        tr th,
        tr td {
            background-color: $white;
            font-size: rem(14);
            font-family: $fontBody;
            font-weight: 300;
            border-bottom: 1px solid #ddd;
            height: rem(50);
            text-align: center;
        }

        tr th:last-child {
            font-size: 0;
        }

        tr td a {
            transition: color 0.3s ease-in-out;
            color: $corail;
            line-height:1;

            &::before {
                font-family: $iconFont;
                content:'\EA0B';
                line-height:2;
                vertical-align: middle;
                margin-right: 5px;
            }

            &:hover {
                color: $black;
            }
        }
    }

    table {
        width: 100%;
        font-size: rem(14);
        font-family: $fontBody;
        font-weight: 300;
        margin-bottom: rem(50);

        @include bp-lt($small){
            min-width: 600px;
            margin-bottom: 0;
        }

        .table-caption {
            display: table-caption;
            width: 100%;
            text-align: center;
            font-family: $fontBody;
            color: $white;
            background: $purple--dark;

            font-size: rem(16px);
            letter-spacing: rem(1.14px);
            line-height: rem(28px);
            text-transform: uppercase;
            font-weight: 400;
            padding: rem(10) 0;

            a, a:hover {
                color: $mainBranding;
                text-decoration: underline;
            }
        }

        thead tr th {
            font-size: rem(14);
            vertical-align: middle;
            color: $black;
            border-color: $grey--table--thead;
            letter-spacing: rem(1);
            padding: rem(10);
            font-weight: 400;
        }

        tbody tr td {
            font-size: rem(15);
            vertical-align: middle;
            color: $black;
            border-color: $grey--table--thead;
            padding: rem(10);
            letter-spacing: rem(0.45px);
            line-height: rem(28);
        }

        thead tr th,
        tbody tr td {
            &:not(:last-child){
                border-right: 1px solid $grey--02 !important;
            }
        }
    }
}

.sales-order-shipment,
.sales-order-creditmemo,
.sales-order-invoice,
.sales-order-view {
    ul {
        margin: 0;
        padding: 0;
        list-style: none none;
    }

    .order-links {
        margin: 0;
        margin-bottom: 30px;
        padding: 10px 0;
        text-align: center;
        background: #F9F9F9;
        box-shadow: 35vw 0 0 0 #F9F9F9, -35vw 0 0 0 #F9F9F9;
        overflow: hidden;

        .item {
            display: inline-block;

            @include bp-lt($small){
                width: 100%;
            }
        }

        .item:not(:last-child) {
            margin-right: 50px;
        }

        .nav.item strong,
        .nav.item a {
            color: $black;
            font-size: rem(18);
            font-family: $fontBody;
            position: relative;
            transition: color 0.2s ease-in-out;
        }

        .nav.item a:hover,
        .nav.item.current strong {
            color: $subBranding;
        }
    }

    .order-details-items.ordered > .order-title {
        display: none;
    }

    table .col .product {
        text-align: left;
    }

    .table-wrapper table {
        width: 100%;
        font-size: rem(14);
        font-family: $fontBody;
        background-color: $white;

        @include bp-lt($small){
            min-width: 600px;
        }

        .table-caption {
            display: table-caption;
            width: 100%;
            text-align: center;
            font-family: $fontBody;
            color: $white;
            background: $purple--dark;

            font-size: rem(16px);
            letter-spacing: rem(1.14px);
            line-height: rem(28px);
            text-transform: uppercase;
            font-weight: 400;
            padding: rem(10) 0;

            a, a:hover {
                color: $mainBranding;
                text-decoration: underline;
            }
        }

        strong {
            font-weight: 400;
        }

        tr th,
        tr td {
            background-color: $white;
            font-size: rem(14)!important;
            font-family: $fontBody;
            font-weight: 400;
            border-bottom: 1px solid #ddd;
            height: rem(50);
            text-align: center;
            vertical-align: middle;
            color: $black;
            border-color: $grey--table--thead;
            padding: rem(10);
            line-height: (28/15);
        }

        tr th span,
        tr td span,
        tr td.amount span.price {
            font-size: rem(14) !important;
        }

        td.col.subtotal,
        td.col.total,
        tr td.amount {
            text-align: right!important;
            padding-right: 10px !important;
        }

        thead tr th {
            font-size: rem(14);
            letter-spacing: rem(1);
        }

        tbody tr td {
            font-size: rem(15);
            letter-spacing: rem(0.45px);
        }

        thead tr th,
        tbody tr td {
            &:not(:last-child){
                border-right: 1px solid $grey--02 !important;
            }
        }
    }

    .table-wrapper table tr.totals-tax-summary,
    .table-wrapper table tr.totals.tax.details,
    .table-wrapper table tr.totals-tax {
        .mark {
            border-top: 1px solid #e6e8ea;
            background: #fff;
            font-size: 0px;
            padding: 10px;
            padding-right: 30px;
            word-break: break-word;
            text-align: right;

            margin-left: 20px;
            color: #463b41;
            font-family: Ubuntu, Helvetica, Arial;
            font-size: 16px;
            line-height: 22px;
            font-weight: 300;
        }

        .amount {
            border-top: 1px solid #e6e8ea;
            background: #fff;
            font-size: 0px;
            padding: 10px;
            padding-right: 30px;
            word-break: break-word;
            text-align: left;

            span {
                color: #ce5959;
                font-family: Ubuntu, Helvetica, Arial;
                font-size: 21px;
                line-height: 22px;
            }
        }
    }

    .table-wrapper {
        overflow-x: auto;

        width: 100%;
        overflow-y: auto;
        padding-bottom: 15px;

        scrollbar-width: thin;
        scrollbar-color: $corail $white;

        &::-webkit-scrollbar
        {
            -webkit-appearance: none;
            width: 12px;
            height: 12px;
        }

        &::-webkit-scrollbar-thumb
        {
            border-radius: 8px;
            border: 3px solid $white;
            background-color: $corail;
        }
    }

    .block.block-order-details-view .block-content {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: rem(20);
        margin-bottom: rem(40);

        @include bp-lt($small){
            grid-template-columns: repeat(1, 1fr);
        }

        .box {
            margin-bottom: 0 !important;
        }

        .box .box-content address,
        .box .box-content {
            display: table-cell;
            vertical-align: middle;
            font-family: $fontAlt;
            font-weight: 300;
            color: $grey--account--01;
            font-size: rem(14);
            font-style: normal;
            line-height: 1.5;

            dt {
                font-weight: 300;
            }
        }

        .box.box-order-shipping-address {
            order: 0;
        }

        .box.box-order-billing-address {
            order: 1;
        }

        .box.box-order-shipping-method {
            order: 2;
        }

        .box.box-order-billing-method {
            order: 3;
        }
    }

    .actions-toolbar a.action.back {
        background: $corail;
        color: $white;
        border-bottom-color: $corail--dark;
        width: auto;
    }

    .page-title-wrapper .actions-toolbar.order-actions-toolbar{
        display: none;
    }

    .page-title-wrapper .order-date {
        margin: 0;
    }

    .page-title-wrapper .order-date span.label {
        padding: 0;
    }

    .page-title-wrapper .order-status,
    .page-title-wrapper .order-date span,
    .page-title-wrapper .order-date span.label {
        background: none;
        color: $black;
        font-size: rem(14);
        font-family: $fontBody;
        position: relative;
        transition: color 0.2s ease-in-out;
    }

    .page-title-wrapper {
        text-align: center;
        margin-bottom: rem(40);
    }

    .page-title-wrapper .page-title {
        position: relative;
        font-family: $fontAlt;
        text-transform: uppercase;
        color: $purple--dark;
        text-align: center;
        letter-spacing: rem(8);
        font-size: rem(30);
        padding-bottom: rem(20px) !important;
        margin-bottom: rem(46px);

        @include bp-lt($medium) { font-size: rem(28); }
        @include bp-lt($small) { font-size: rem(26); }
        @include bp-lt($mobileL) { font-size: rem(22); }

        &:after {
            content: '';
            position: absolute;
            left: 50%;
            bottom: 0 !important;
            transform: translateX(-50%);
            display: block;
            width: rem(50);
            height: rem(1);
            background: $corail;
        }
    }

    .order-details-items > .actions-toolbar:first-child,
    .order-details-items.creditmemo .actions-toolbar {
        display: none;
    }


}

.sales-order-shipment,
.sales-order-creditmemo {
    .order-details-items.creditmemo {
        margin-bottom: rem(20);
    }

    .order-tracking .tracking-content,
    .order-tracking .tracking-title,
    .box-account.box-info .order-title {
        color: $black;
        font-size: rem(14);
        font-family: $fontBody;
        margin-bottom:rem(10);
        text-align: center;
        font-weight: 400;

        strong, a {
            color: $black;
            font-weight: 400;
        }

        a {
            color: $subBranding;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }

    .box-account.box-info .order-title a.action {
        display: none;
    }
}

.sales-order-shipment {
    .box-account.box-info .order-title strong {
        display: block;
    }

    .box-account.box-info .order-title a {
        margin: 0 5px;
        display: inline-block;
    }

    .order-tracking {
        text-align: center;
    }

    .order-tracking .tracking-content,
    .order-tracking .tracking-title {
        display: inline-block;
        margin: 0;
    }
}
