// ========================================= //
// inputs
// ========================================= //

$inputH : 48;

[class*='c-input__holder'] {
    position: relative;
    margin-bottom: rem(24);
    border: solid rem(1) $grey--02;

    .c-label label {
        position: absolute;
        left: rem(7);
        top: rem(-7);
        display: inline-block;
        width: auto;
        height: rem(12);
        padding-left: rem(5);
        padding-right: rem(5);
        font-family: $maven;
        font-size: rem(10);
        color: $colorFont;
        text-transform: uppercase;
        letter-spacing: rem(1);
        background: $white;
        font-weight: 700;
    }

    .c-input input,
    .c-input textarea {
        padding-left: rem(10);
        padding-right: rem(10);
        border: none;
        box-shadow: none;
        background: transparent;
        font-family: $maven;
        font-size: rem(15);
        color: $colorFont;
        margin-bottom: 0;
    }

    .c-input input {
        height: rem($inputH);
    }

    .c-input textarea {
        height: rem(216);
        resize: none;
    }

    .is-required {
        margin-left: rem(5);
        color: $error;
    }

    &.has-error {
        border-color: $error;

        .c-label label { color: $error; }
    }
}

select {
    height: rem(48);
    padding-left: rem(16);
    padding-right: rem(30);
    font-family: $maven;
    font-size: rem(15);
    color: $colorFont;
    border: solid rem(1) $grey--09;
    background-color: $grey--05;
    background-image: url(#{$img}c-bkg__select.svg);
}

.c-input__datePicker {
    position: relative;

    input, input[readonly] {
        margin-bottom: 0;
        height: rem(48);
        border: $border--grey--02;
        background: url(#{$img}c-icon__calandar.svg) no-repeat $white;
        background-position: top rem(13) right rem(14);
        font-family: $maven;
        color: $colorFont;
        font-size: rem(15);
        @include placeholder-color($taupe--light);

        @include bp-lt($large) { background-position: top rem(11) right rem(14); }
        @include bp-lt($desktop) { background-position: top rem(10) right rem(14); }
    }

    .picker, .picker__holder {
        min-width: rem(280);
        left: 50%;
        transform: translateX(-50%);
    }
}

.c-input__inline {
    $inputW : 185;
    @include clearfix;

    .c-input__holder {
        width: calc(100% - #{$inputW}px);
        float: left;
        margin-bottom: 0;

        @include bp-lt($mobileL) {
            width: 100%;
            float: none;
        }
    }

    input[type='submit'] {
        height: rem($inputH + 2);
        min-width: 0;
        max-width: #{$inputW}px;
        float: left;
        margin-bottom: 0;

        @include bp-lt($mobileL) {
            max-width: 100%;
            width: 100%;
            float: none;
        }
    }
}

input[type="checkbox"],
input[type="radio"] {
    &:checked, &:not(:checked) {
        position: absolute;
        left: -9999px;

        & + label {
            position: relative;
            width: 100%;
            margin-left: 0;
            margin-right: 0;
            padding-left: rem(25);
            margin-bottom: $gutter;
            font-family: $maven;
            font-size: rem(15);
            color: $colorFont;

            &:before {
                content: '';
                position: absolute;
                top: 3px;
                left: 0;
                width: 15px;
                height: 15px;
                background: $white;
                transition: $ease;
                border: solid rem(1) $grey--02;

                @include bp-lt($desktop){
                    top: 1px;
                }
            }

            &:after {
                content: '';
                position: absolute;
                width: 7px;
                height: 7px;
                top: 7px;
                left: 4px;
                background: $subBranding;
                transition: $ease;

                @include bp-lt($desktop){
                    top: 5px;
                }
            }
        }

        &:not(:checked) + label:after {
            opacity: 0;
            transform: scale(0);
            transition: $ease;
        }

        &:checked + label:after {
            opacity: 1;
            transform: scale(1);
        }

        &:hover {
            & + label {
                &:before {
                    transition: $ease;
                }
            }
        }
    }
}

input[type="radio"] {
    display: inline-block;

    &:checked, &:not(:checked) {
        & + label:before,
        & + label:after {
            border-radius: 50%;
        }
    }
}

