// ========================================= //
// Magento/ block-addresses-list
// ========================================= //

.block-addresses-list {

    .table-wrapper {
        margin-bottom: rem(40);
        width: 100%;
        overflow-y: auto;
        scrollbar-width: thin;
        scrollbar-color: $corail $white;

        &::-webkit-scrollbar
        {
            -webkit-appearance: none;
            width: 12px;
            height: 12px;
        }

        &::-webkit-scrollbar-thumb
        {
            border-radius: 8px;
            border: 3px solid $white;
            background-color: rgba(darkblue, .5);
        }
    }

    .table-caption {
        display: none;
    }


    // M E D I A   Q U E R I E S
    @include bp-gt(768px)
    {

    };

    @include bp-gt(1024px)
    {

    };

    @include bp-gt(1440px)
    {

    };

}
