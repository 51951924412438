// ========================================= //
// share
// ========================================= //

.o-share {
    font-size: rem(23);
    color: $colorLink;
    transition: $ease;

    &:hover {
        color: $mainBranding;
    }
}
