// ========================================= //
// colors
// ========================================= //

$white : #ffffff !default;
$black : #000000 !default;
$error : #da4f4f !default;
$grey : #9d9da6 !default;
$red : #ba2327 !default;
$orange : #ffa279 !default;
$purple--dark : #463b41;

$green : #a6c243;

$taupe : #9e8c8d;
$taupe--light: #c9bcbc;

$corail : #ce5959;
$corail--dark: #a8050d;
$corail--dark--2: #B70000;
$orange--light: #BE5F5F;

$grey : #dcd4d4;
$grey--02 : #e5dfdf;
$grey--03 : #f0f0f0;
$grey--04 : #c9bcbc;
$grey--05 : #f9f9f9;
$grey--06 : #e8e6e7;
$grey--07 : #efeded;
$grey--08 : #ebe7e7;
$grey--09 : #e2e2e2;
$grey--10 : #f3f3f3;
$grey--11 : #bebbbd;

$mainBranding : $purple--dark !default;
$mainBranding--lighten--01: #5f555b !default;
$mainBranding--lighten--02: #463b41 !default;
$mainBranding--lighten--03: #d17675 !default;
$mainBranding--lighten--04: #af9e9f !default;
$mainBranding--lighten--05: #685c62 !default;

$mainBranding--darken--01: #393035 !default;
$mainBranding--darken--02: #2d262a !default;
$mainBranding--darken--03: #be4f4f !default;

$subBranding : $corail !default;
$subBranding--lighten--01: $taupe--light;
$subBranding--lighten--02: #b1a6a6;

$subBranding--darken--01: #c6484a;

$colorFont : $purple--dark !default;
$colorLink : $corail;

$selectionColor : $white !default;
$selectionBackground : $purple--dark !default;


$green--01:#82bf71;