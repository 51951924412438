// ========================================= //
// mapTeam
// ========================================= //

.c-map__teamHolder {
    position: relative;
    max-width: rem(650);
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    svg {
        width: 650px;
        height: 700px;

        @include bp-lt(800px) { width: 530px; height: 570px; }
        @include bp-lt(600px) { width: 450px; height: 485px; }
        @include bp-lt(480px) { width: 340px; height: 366px; }
        @include bp-lt(375px) { width: 280px; height: 302px; }
        @include bp-lt(320px) { width: 270px; height: 291px; }
    }
}

.js-map__dept {
    fill: #EDEDED;
    stroke: $white;
    stroke-width: 0.5;
    stroke-miterlimit: 10;
    transition: $ease;
    cursor: pointer;

    &:hover {
        fill: $white !important;
    }
}

.c-map__teamControler--small {
    display: none;

    @include bp-lt($small) {
        display: block;
    }

    select {
        margin-bottom: 40px;
    }
}

.blog-index-index .o-share,
.contact-index-index .o-share,
.teams-index-index .o-share {
    @include bp-lt($small) {
        right: rem(24);
    }
}