// ========================================= //
// gmap
// ========================================= //

.c-contact__gmap--holder {
    background: $white;
}

#o-map__contact {
    height: rem(432);
}
