// ========================================= //
// Magento/ field
// ========================================= //

// B A S E
// form .field {

//     // @extend .input;

//     .control {
//         width: 100%;

//         > div:not(.nested):not(.cartQuantity__btn):not([class*="picker"]) {
//             display: block;
//             font-size: 0.65em;
//             line-height: 1.1;
//             position: static;
//             width: 100%;

//             &:not(:empty) {
//                 margin-top: 0.5em;
//             }
//         }

//         .nested,
//         input + input,
//         input + select,
//         input + textarea,
//         select + input,
//         select + select,
//         select + textarea,
//         textarea + input,
//         textarea + select,
//         textarea + textarea {
//             margin-top: $spacer;
//         }

//     }

//     &:not(.choice):not(:last-of-type) {
//         margin-bottom: $spacer;
//     }

// }

#co-shipping-form {
    display: block;
    max-width: 100%;

    #shipping-new-address-form {
        display: flex;
        flex-flow: row wrap;
        padding-bottom: 0;
    }

    input:not([type]),
    input[type="text"],
    input[type="password"],
    input[type="date"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="month"],
    input[type="week"],
    input[type="email"],
    input[type="number"],
    input[type="search"],
    input[type="tel"],
    input[type="time"],
    input[type="url"],
    input[type="color"],
    select,
    textarea {
        -webkit-appearance: none;
        -moz-appearance: none;
        border-radius: 0;
        background-color: #fff;
        border-style: solid;
        border-width: 1px;
        border-color: #ccc;
        box-shadow: none;
        color: #000000bf;
        display: block;
        font-family: inherit;
        font-size: .875rem;
        height: 2.3125rem;
        margin: 0 0 1rem 0;
        padding: .5rem;
        width: 100%;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-transition: border-color .15s linear,background .15s linear;
        -moz-transition: border-color .15s linear,background .15s linear;
        -ms-transition: border-color .15s linear,background .15s linear;
        -o-transition: border-color .15s linear,background .15s linear;
        transition: border-color .15s linear,background .15s linear;
    }

    .field.street {
        width: 100%;
        padding: 0 10px;
        margin: 0;
        border: 0;

        @include bp-lt($small){
            padding: 0;
        }

        .control .field._required label,
        .control .field.additional label {
            width: 1px;
            display: none;
        }

        .field {
            width: 100%;
            padding: 0;
        }
    }

    .field.choice {
        label {
            padding-left: 25px;
            line-height: 1;
        }

        &::before {
            display: none
        }
    }

    div.field {
        width: 50%;
        padding: 0 10px;
        margin: 0;

        @include bp-lt($small){
            width: 100%;
            padding: 0;
        }

        select {
            display: block;
        }
    }

    .field-error {
        width: 100%;
        float: left;

        display: block;
        font-size: 12px;
        color: $error;
        line-height: 1;
        padding-top: 4px;
        margin-top: -1rem;
        margin-bottom: 1rem;

        @include bp-lt($small){
            font-size: 10px;
        }
    }

    .control._with-tooltip .field-tooltip.toggle  #tooltip-label {
        margin: 0;
    }

    .field-tooltip .field-tooltip-action {
        display: block;
    }

    .control {
        width: 100%;
        float: none;
    }

    .control._with-tooltip input.input-text {
        max-width: calc(100% - 42px);
        float: left;
    }

    .control._with-tooltip .field-tooltip.toggle {
        display: inline;
        float: right;
        position: relative;

        #tooltip-label,
        .tooltip-label,
        .field-tooltip-content {
            display: none;
        }

        .field-tooltip-content {
            position: absolute;
            right: 0;
            top: -10px;
            background: #fff;
            padding: 10px;
            min-width: 200px;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            transform: translateY(-100%);
            font-size: 14px;
            line-height: 1.25;
        }

        &._active .field-tooltip-content {
            display: block;
        }
    }

    .field-tooltip .field-tooltip-action:before {
        font-family: $maven;
        content:"?";
        width: 32px;
        height: 32px;
        border-radius: 16px;
        background-color: $mainBranding;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: $white;
    }

    .label {
        background: none;
        padding: 0;
        width: 100%;
        text-align: left;
        float: none;
        display: block;
        line-height: 1;
        color: $black;

        font-family: $maven;
        font-size: rem(16);
        font-weight: 400;
        text-transform: none;
        margin-bottom: 8px;
        white-space: normal;

        em {
            color: $error;
        }
    }

}