// ========================================= //
// blocks.title
// ========================================= //
$fontAlt                : 'Oswald', sans-serif;
$mainFont               : $purple--dark !default;
$subBrandingcheckout    : #463b41 !default;
$grey--table--thead     : #e5e4e4 !default;

$grey--account--01: #6f777e;

.c-title__account + .navigation.c-nav__link a {
    font-weight: 400;
    color: $subBrandingcheckout;
}

.c-title__account +.navigation.c-nav__link {
    position: absolute;
    right: rem(130);
    top: rem(72);
    width: auto;
    margin: 0;
    z-index: 10;

    @include bp-lt($xmedium){
        right: rem(20);
    }

    @include bp-lt($small){
        position: static;
        display: inline-flex;
        width: 100%;
        margin-bottom: rem(30);

        ul {
            margin: 0 auto;
        }
    }

    a {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white;
        height: rem(50);

        &:hover {
            text-decoration: none;
        }
    }
}

%box-head {
    height: rem(60);
}

%box-link {
    margin: 0;
    padding: rem(10) 0 0 0 !important;
    text-align: left;
    font-size: rem(24);
    font-style: initial;
    font-family: $fontBody;
    color: $subBrandingcheckout;
    letter-spacing: initial !important;
    font-weight: 400;

    @include bp-lt($medium) {
        font-size: rem(14);
    }
}

%box-white {
    background: $white;
    padding: rem(20);
    border-radius: 0 0 rem(3) rem(3);
}

%box-content {
    min-height: rem(120);
    padding: rem(20);
    color: $subBranding;
    display: table;
    width: 100%;

    p,
    address {
        display: table-cell;
        vertical-align: middle;
        font-family: $fontAlt;
        font-weight: 300;
        color: $grey--account--01;
        font-size: rem(14);
        font-style: normal;
        line-height: 1.5;

        strong {
            font-family: $fontBody;
            font-size: rem(20);
            font-weight: 400;
            color: $subBranding;
        }

        a {
            color: $grey--account--01;
            text-decoration: none;
        }

        .c-icon {
            margin-bottom: rem(15);
        }
    }

    p, td, li {
        @include bp-lt($small) {
            font-size: rem(13) !important;
        }
    }

    a {
        color: $mainBranding;
        text-decoration: underline;
    }
}

%link-before {
    position: absolute;
    top: rem(1);
    bottom: 0;
    right: rem(10);
    margin-top: auto;
    margin-bottom: auto;
    display: block;
    width: rem(30);
    height: rem(30);
    font-size: 0;

    &:before {
        position: absolute;
        display: block;
        width: rem(30);
        height: rem(30);
        line-height: rem(30);
        font-family: $iconFont;
        font-size: rem(25);
        color: $white;
        text-align: center;
    }
}

.c-title__account,
.page-title {
    h1, .h1 {
        position: relative;
        font-family: $fontAlt;
        text-transform: uppercase;
        color: $purple--dark;
        text-align: center;
        letter-spacing: rem(8);
        font-size: rem(30);
        padding-bottom: rem(20px) !important;
        margin-bottom: rem(46px);

        @include bp-lt($medium) { font-size: rem(28); }
        @include bp-lt($small) { font-size: rem(26); }
        @include bp-lt($mobileL) { font-size: rem(22); }

        &:after {
            content: '';
            position: absolute;
            left: 50%;
            bottom: 0 !important;
            transform: translateX(-50%);
            display: block;
            width: rem(50);
            height: rem(1);
            background: $corail;
        }
    }
}

.c-title__account {
    h1, .h1 {
        padding-bottom: rem(30);

        &:after {
            bottom: rem(13);
        }
    }
}

.account .c-account .toolbar .toolbar-amount {
    display: none;
}

.account .c-account .toolbar .pages {
    position: static;
    border: 0;
    padding: 0;
    background: none;

    .row {
        margin: 0;
    }

}

.account.sales-order-history .c-account .order-products-toolbar .limiter {
    display: none;
}

.c-account {
    .page-title {
        border: none;

        h1, .h1 {
            @include bp-lt($small) {
                background: $black;
            }
        }
    }

    .box-account.box-info .block-dashboard-orders {
        margin-top: rem(40);
    }

    .actions-toolbar {
        position: static;
        margin: 50px 0 50px!important;
        padding: 0;

        .secondary {
            margin: 0 !important;
        }

        a.action.back {
            display: flex;
            align-items: center;
            justify-content: center;
            background: $white;
            color: $subBrandingcheckout;
            border-color: $grey--04;
            margin: 0 !important;

            &:hover {
                background: #463b41;
                color: #fff;
                border-bottom-color: #2d262a;
            }
        }
    }

    .box-account,
    .order-info-box,
    .addresses-list {
        .block-dashboard-orders {
            .block-title {
                position: relative;
                margin-bottom: rem(10);
                margin-top: rem(40);

                strong {
                    @extend %box-link;
                }

                .action.view {
                    position: absolute;
                    top: rem(1);
                    bottom: 0;
                    right: rem(10);
                    margin-top: auto;
                    margin-bottom: auto;
                    display: block;
                    width: rem(30);
                    height: rem(30);
                    font-size: 0;

                    &:before {
                        position: absolute;
                        display: block;
                        width: rem(30);
                        height: rem(30);
                        line-height: rem(30);
                        font-family: $iconFont;
                        content: "\EA30";
                        font-size: rem(25);
                        color: $subBrandingcheckout;
                        text-align: center;
                    }
                }
            }
        }

        .box-head,
        .box-title,
        .box-subtitle {
            @extend %box-head;

            span,
            h2,
            h3,
            a>h2,
            a>h3 {
                @extend %box-link;
            }
        }

        .box-head {
            text-align: center;
        }

        .box-title {
            text-align: left;
        }
    }

    .box-actions {

        a {
            position: absolute;
            top: rem(10);
            right: rem(10);
            display: block;
            width: rem(45);
            height: rem(45);
            font-size: 0;
            border: solid rem(1) #f0f1f2;
            border-radius: 50%;
            transition: $ease;

            &:after {
                content: '\EA28';
                display: flex;
                align-items: center;
                justify-content: center;
                width: rem(45);
                height: rem(45);
                font-size: rem(18);
                color: #82898f;
                text-align: center;
                font-family: $iconFont;
                transition: $ease;
            }

            &.c-bkg__arrow:after {
                content: '\EA06';
            }

            &:hover {
                background: $mainBranding;
                &:after { color: $white; }
            }
        }
    }

    form {
        .fieldset {
            padding: 0;

            .form-list {
                @extend %box-white;
            }
        }
    }

    .addresses-list {
        margin-bottom: rem(20);
        padding-left: rem(10);
        padding-right: rem(10);

        .box-title {
            @extend %box-head;

            strong,
            span,
            h2 {
                @extend %box-link;
            }
        }

        .c-box {
            @extend %box-white;
        }

        .c-box--noPadding {
            @include clearfix();
            padding: 0;
            margin-left: rem(-14);
            margin-right: rem(-14);
            background: $white;
            border-radius: 0 0 rem(3) rem(3);
        }
    }
}

.c-account form .fieldset .legend {
    @extend %box-link;
}

.o-layout__account {
    .c-bkg__white {
        .page-title {
            display: none;
        }
    }
}

.my-account {
    .page-title {
        display: none;
    }
}

.fieldset .legend {
    margin-bottom: rem(36) !important;
    display: inline-block;
    font-family: $fontAlt;
    font-size: rem(24);
    color: $mainFont;
    text-transform: uppercase;
    letter-spacing: rem(2);
    border-bottom: solid rem(2) $mainBranding;

    @include bp-lt($small) {
        font-size: rem(20);
    }
}

.c-account {
    .box-account,
    .order-info-box {
        border-top: none;
        padding: 0 rem(18);
        margin-top: 0;

        @include bp-lt($small) {
            margin-bottom: 0;
        }

        .box {
            background: $white;
            border: solid rem(1) $grey--02;
            padding: rem(10);
            margin: 0 0 rem(20) !important;
            border-radius: rem(3);
            position: relative;

            .box-content {
                @extend %box-content;
            }
        }

        &.box-recent {}


        &.box-info {
            .block-title,
            .box-head {
                display: none;
            }

            @include bp-lt($small) {
                width: 100%;
            }
        }

        .data-table,
        #my-orders-table {
            margin-top: 0;

            th, td {
                @include bp-lt($small) {
                    font-size: rem(13) !important;
                }
            }
        }

        &.box-fidelity-sponsorship {
            width: 50%;
            padding-right: rem(10);

            @include bp-lt($small) {
                width: 100%;
                padding-right: 0;
            }

            .box-head {
                text-align: left !important;
            }

            .col1-set {
                background: $white;
                margin-bottom: rem(20);
                border-radius: 0 0 rem(3) rem(3);
                @extend %box-content;
            }
        }
    }

    .addresses-list {
        .c-box,
        .box-content {
            @extend %box-content;
        }

        .box-content {
            padding: rem(20);
            font-size: rem(20);
            font-family: $fontBody;
            color: $subBrandingcheckout;
            font-weight: 300;
        }

        .addresses-additional {
            .c-edit, .c-delete {
                @include bp-lt($medium) {
                    width: rem(33);
                    height: rem(33);
                }

                &:after {
                    @include bp-lt($medium) {
                        width: rem(33);
                        height: rem(33);
                        position: relative;
                        top: rem(-5);
                    }
                }
            }

            .c-address__action {
                @include bp-lt($medium) {
                    width: rem(90);
                }
            }

            address {
                > strong {
                    max-width: calc(100% - 155px);
                    display: inline-block;
                }
            }
        }
    }

    .box-subtitle {
        h3 {
            @include bp-lt($medium) {
                padding-left: 0 !important;
            }
        }
    }
}

.c-account {
    padding-top: rem(48);

    .c-row {
        margin-bottom: rem(20);
    }

    .text-center { text-align: center; }
    .text-left { text-align: left; }
    .text-right { text-align: right; }
}

.block-dashboard-addresses,
.block-dashboard-info {
    .block-content {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: rem(20);

        @include bp-lt($small) {
            gap: 0;
            grid-template-columns: repeat(1, 1fr);
        }
    }

    .block-content .box {
        width: 100%;
    }
}

.c-account .box-account.box-info .block-dashboard-addresses {
    background: $white;
    border: solid rem(1) $grey--02;
    padding: rem(10);
    margin: 0 0 rem(20) !important;
    border-radius: rem(3);

    .action.edit {
        display: none;
    }

    .block-content {
        gap: 0;
    }

    .block-title {
        display: block;

        strong {
            @extend %box-link;
        }
    }

    .box-title {
        padding: 0 rem(20);
        margin-top: 20px;
        display: block;
        height: auto;
    }

    .box-title span {
        font-size: rem(20);
    }

    .box-content {
        padding-top: 0;
    }

    .box {
        border: 0;
        border-radius: 0;
        padding: 0;
        margin: 0 !important;
    }
}

.box-account.box-info .table-wrapper.orders-recent,
.box-account.box-info .table-wrapper.orders-history,
.box-account.box-info .block-dashboard-orders {
    margin-bottom: rem(50);

    .data.table {
        width: 100%;
        font-size: rem(14);
        font-family: $fontBody;
        font-weight: 300;

        @include bp-lt($tablet){
            min-width: 600px;
        }

        .table-caption {
            display: table-caption;
            width: 100%;
            text-align: center;
            font-family: $fontBody;
            color: $white;
            background: $purple--dark;

            font-size: rem(16px);
            letter-spacing: rem(1.14px);
            line-height: rem(28px);
            text-transform: uppercase;
            font-weight: 400;
            padding: rem(10) 0;

            a, a:hover {
                color: $mainBranding;
                text-decoration: underline;
            }
        }

        thead tr th {
            font-size: rem(14);
            border-color: $grey--table--thead;
            letter-spacing: rem(1);
            font-weight: 400;
            text-transform: uppercase;
        }

        tbody tr td {
            font-size: rem(15);
            border-color: $grey--table--thead;
            letter-spacing: rem(0.45px);
            line-height: rem(28);
        }

        thead tr th,
        tbody tr td {
            text-align: left;
            padding: rem(12);
            vertical-align: middle;
            color: $black;
            background: $white;
            border-bottom: 1px solid $grey--02 !important;

            &:not(:last-child){
                border-right: 1px solid $grey--02 !important;
            }
        }
    }

    .messages {
        * {
            color: $white;
        }
    }

    .data-table__wishlist {
        thead {
            display: none;
        }

        tbody {
            @include clearfix();

            tr {
                display: block;
                width: 49%;
                float: left;
                margin-left: 0.5%;
                margin-right: 0.5%;
                margin-bottom: rem(20);
            }
        }
    }
}

.account {
    .block-collapsible-nav .title {
        display: none;
    }
}

.box-account.box-info .table-wrapper.orders-history {
    overflow-x: scroll;

    width: 100%;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: $corail $white;

    &::-webkit-scrollbar
    {
        -webkit-appearance: none;
        width: 12px;
        height: 12px;
    }

    &::-webkit-scrollbar-thumb
    {
        border-radius: 8px;
        border: 3px solid $white;
        background-color: $corail;
    }
}

.table-caption {
    display: none;
    width: 100%;
    text-align: right;
    font-family: $fontBody;
    color: $mainFont;
    font-size: rem(15);
    font-weight: 400;

    a, a:hover {
        color: $mainBranding;
        text-decoration: underline;
    }
}

.account .o-page--bkgGrey.c-account {
    background-color: $white;
}