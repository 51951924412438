// ========================================= //
// modalComparator
// ========================================= //

.mfp-bg {
    background: $mainBranding;
    opacity: .9;
}

.c-mfp__comparator {
    background: $white;
    max-width: rem(1580);
    width: 80%;
    margin: rem(50) auto;
    position: relative;

    &.empty p {
        margin-top: 1.25rem;
    }

    &.empty {
        @include bp-lt($small) {
            padding-right: 40px;
            padding-left: 20px;
        }
    }

    .c-button--corail.action.tocart.primary.c-grid__product__item--addToCart {
        transform: none;
    }

    @include bp-lt($medium) {
        width: 90%;
    }

    .c-modal__title {
        @include bp-lt($tablet) {
            font-size: rem(20);
            height: rem(70);
        }
    }

    .c-grid__product__item {
        border: none;
        box-shadow: none;

        [class*="c-button"] {
            min-width: 100%;
        }
    }

    .c-grid__product__item--title {
        word-break: break-word;
    }

    .c-grid__product__item--baseline {
        border-top: none;
    }

    .c-page__header [class*='c-section__title'] {
        padding-bottom: rem(30);
        margin-bottom: rem(30);
    }

    .c-modal__content--header .c-modal__content--cell {
        position: relative;
        padding-bottom: rem(80);

        .c-grid__product__item {
            position: static;
            margin-bottom: 0;
        }

        .c-addtoCart.c-button--corail,
        .product-item-actions {
            position: absolute;
            left: 50%;
            bottom: 0.812rem;
            transform: translateX(-50%);
            max-width: calc(100% - 0.812rem * 2);
            min-width: 0;
            width: 100%;
            height: 3.812rem;

            &, button {
                margin: 0;
            }
        }
    }
}

.mfp-wrap {
    webkit-overflow-scrolling: touch
}

#c-magnificpopup__addtocompare {
    .mfp-close {
        color: white;
    }
}

.c-modal__content--holder {
    width: 100%;

    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    overflow-scrolling: touch;
    -ms-overflow-style: none;
    &::-webkit-scrollbar { width: 0 !important }

    &::-webkit-scrollbar
    {
        -webkit-appearance: none;
        width: rem(14);
        height: rem(8);
    }

    &::-webkit-scrollbar-thumb
    {
        border-radius: rem(8);
        border: none;
        background-color: rgba($mainBranding, .2);
    }
}

.c-modal__content--table {
    display: table;
    table-layout: fixed;
    width: 100%;
    min-width: rem(500);

    .c-modal__content--header {
        display: table-header-group;

        .c-modal__content--row,
        .c-modal__content--row .c-modal__content--cell,
         {
            border-top: $border--grey--02;
            background: $white !important;
            vertical-align: top;
        }
    }

    .c-modal__content--row {
        display: table-row;

        .c-modal__content--cell {
            &:first-of-type {
                font-size: rem(14);
                font-weight: 700;
                text-transform: uppercase;
                font-family: $maven;
                letter-spacing: rem(1);
                padding-left: rem(20);
                padding-right: rem(20);
            }
        }

        &:nth-child(even) {
            background: $white;

            .c-modal__content--cell {
                &:nth-child(2n+2) {
                    background: $grey--05;
                }
            }
        }

        &:nth-child(odd) {
            background: $grey--05;

            .c-modal__content--cell {
                &:nth-child(2n+2) {
                    background: $grey--10;
                }
            }
        }
    }

    .c-modal__content--cell {
        display: table-cell;
        width: rem(180);
        padding: rem(13);
        min-height: rem(50);
        border-bottom: $border--grey--02;
        border-right: $border--grey--02;
        vertical-align: middle;
        font-family: $maven;
        font-size: rem(15);
        color: $colorFont;
        letter-spacing: rem(1);
    }
}

.c-modal__footer {
    padding-top: rem(45);
    padding-bottom: rem(45);
}
