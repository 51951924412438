// ========================================= //
// Magento/ block cart-summary
// ========================================= //
$cart-summary-bg: $white;
$cart-summary-color: $white;
$cart-summary-width-lg: percentage(331/380);
$cart-summary-button-size: rem(40);

.cart-summary {

    background-color: $cart-summary-bg;
    color: $cart-summary-color;

    padding: 0 $spacer $spacer $spacer;

    ul {
          padding-left: 0;
  list-style: none;
        margin: 0;
        li {
            padding-left: 0;
            &::before {
                content: none;
            }
        }
    }

    .action.primary {
        // // @extend .c-button--main;
        width: 100%;
    }

    // M E D I A   Q U E R I E S
    @include bp-gt(768px)
    {

    };

    @include bp-gt(1024px)
    {
        width: $cart-summary-width-lg;
        margin-left: auto;
    };

    @include bp-gt(1440px)
    {

    };

}
