// ========================================= //
// Magento/ checkout-container
// ========================================= //
$checkout-container-MQL: 'lg';
$fontAlt                : 'Oswald', sans-serif;

.checkout-container {

    @include make-row();
    margin-left: 0;
    margin-right: 0;
    justify-content: center;

    margin-bottom: $spacer * 5;

    // E L E M E N T S
    & > * {
        position: relative;
        width: 100%;
        padding-right: $gutter / 2;
        padding-left: $gutter / 2;
    }

    & > .opc-progress-bar {
        @include make-col(12);
    }

    & > .opc-estimated-wrapper {
        @include make-col(11);
    }

    & > .messages {
        @include make-col(11);
    }

    & > .opc-wrapper {
        @include make-col(11);
    }

    & > .opc-summary-wrapper {
        @include make-col(11);
    }

    [role="dialog"], [role="tab"] {
        outline: none;
    }

    // M E D I A   Q U E R I E S
    @include bp-gt(1024px)
    {

        & > .opc-estimated-wrapper {
            @include make-col(8);
        }

        & > .messages {
            @include make-col(12);
        }

        & > .opc-wrapper {
            @include make-col(7);
        }

        & > .opc-summary-wrapper {
            @include make-col(4);
        }
    }

    @include bp-gt(1400px)
    {
        margin-bottom: $spacer * 10;

        // E L E M E N T S

        & > .opc-estimated-wrapper {
            @include make-col(8);
        }

        & > .messages {
            @include make-col(12);
        }

        & > .opc-wrapper {
            max-width: rem(567);
            width: 100%;
        }

        & > .opc-summary-wrapper {
            @include make-col(3);
            @include make-col-offset(0.35);
            max-width: rem(374);
            width: 100%;
        }
    };

    // M E D I A   Q U E R I E S
    @include bp-lt(768px)
    {
        & > .opc-summary-wrapper {
            @include make-col(12);
            @include make-col-offset(0);
            left: auto;
            flex: auto;
            max-width: calc(100% - 40px);
        }
    }

    // M E D I A   Q U E R I E S
    @include bp-bt(768px, 1200px)
    {
        & > .opc-wrapper {
            padding-right: .3125rem;
        }
    }

}

body.checkout-index-index {
    font-family: $maven;

    .payment-option._collapsible.opc-payment-additional.discount-code {
        padding: rem(20) rem(44);
        border-top: 1px solid #e5dfdf;

        @include bp-lt(640px){
            padding: rem(20) rem(20);
        }

        .payment-option-content {
            display: block !important;
        }

        .payment-option-title {
            font-family: $maven;
            font-size: 15px;
            line-height: (22/15);
            color: #000000;
            font-weight: 400;
            letter-spacing: 0.45px;
            margin-bottom: 10px;
        }

        .payment-option-content form .label {
            display: none;
        }

        .payment-option-content form textarea {
            padding-left: rem(10);
            padding-right: rem(10);
            box-shadow: none;
            background: transparent;
            font-family: $maven;
            font-size: rem(15);
            color: $black;
            margin-bottom: 0;
            resize: vertical;
            border: 1px solid #e5dfdf;

            &::placeholder {
                color: rgba($black, 0.5);
            }
        }
    }
}

.checkout-index-index .o-page--bkgGrey {
    background-color: $white;
}

body._has-modal-custom .modal-custom-overlay {
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 899
}

.minicart-items .product-item:not(:last-child) {
    border-bottom: 1px solid #ccc
}

.opc-progress-bar {
    margin: 0;
    padding: 0;
    list-style: none none;
}

.opc-block-summary .items-in-cart>.title {
    border-bottom: 1px solid #ccc;
    padding: 10px 40px 10px 0;
    cursor: pointer;
    display: block;
    text-decoration: none;
    margin-bottom: 0;
    position: relative
}

.minicart-items .product>.product-item-photo,
.minicart-items .product>.product-image-container {
    float: left;
    display: inline-block;
    max-width: 100%;
}

body.checkout-index-index .product-image-wrapper {
    display: block;
    overflow: hidden;
    position: relative;
    z-index: 1
}

.c-link--rib {
    font-family: $maven;
    font-size: rem(14);
    letter-spacing: rem(.6);
    text-transform: uppercase;
    color: $black;
    margin-bottom: 20px;
    display: inline-block;
    position: relative;

    @include links($subBranding , $subBranding , 1);
}

.products-list .product-image-wrapper,
.cart.table-wrapper .product-image-wrapper,
.minicart-items .product-image-wrapper,
.block-giftregistry-shared-items .product-image-wrapper {
    height: auto;
    padding: 0 !important
}

.product-item-name {
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    -webkit-hyphens: auto;
    display: block;
    hyphens: auto;
    margin: 5px 0;
    word-wrap: break-word
}

@media only screen and (max-width:768px) {
    .modal-custom .action-close:before,
    .modal-popup .action-close:before,
    .modal-slide .action-close:before {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 14px;
        line-height: 14px;
        color: #757575;
        content: '\e616';
        font-family: 'luma-icons';
        margin: 0;
        vertical-align: top;
        display: inline-block;
        font-weight: normal;
        overflow: hidden;
        speak: none;
        text-align: center
    }

    .modal-custom .action-close,
    .modal-popup .action-close,
    .modal-slide .action-close {
        background: none;
        padding: 0;
    }

    .modal-popup.modal-slide {
        left: 44px;
        z-index: 900
    }

    .modal-popup.modal-slide._show .modal-inner-wrap {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }

    .modal-popup.modal-slide .modal-inner-wrap {
        height: 100%;
        overflow-y: auto;
        position: static;
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
        -webkit-transition: -webkit-transform .3s ease-in-out;
        transition: transform .3s ease-in-out;
        width: auto
    }

    .modal-popup.modal-slide .modal-inner-wrap {
        margin: 0;
        max-height: none
    }

    .custom-slide {
        bottom: 0;
        left: 0;
        min-width: 0;
        position: fixed;
        right: 0;
        top: 0;
        visibility: hidden;
        opacity: 0;
        -webkit-transition: visibility 0s .3s, opacity .3s ease;
        transition: visibility 0s .3s, opacity .3s ease;
        left: 44px;
        z-index: 900;
        background: none;
    }

    .custom-slide._show {
        visibility: visible;
        opacity: 1;
        -webkit-transition: opacity .3s ease;
        transition: opacity .3s ease
    }

    .custom-slide._show .modal-inner-wrap {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0)
    }

    .custom-slide .modal-inner-wrap {
        background-color: #fff;
        box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.35);
        opacity: 1;
        pointer-events: auto
    }

    .custom-slide._show .modal-inner-wrap {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }

    .custom-slide .modal-inner-wrap {
        height: 100%;
        overflow-y: auto;
        position: static;
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
        -webkit-transition: -webkit-transform .3s ease-in-out;
        transition: transform .3s ease-in-out;
        width: auto
    }

    .custom-slide._show {
        -webkit-overflow-scrolling: touch;
        overflow-x: hidden;
        overflow-y: auto
    }

    .custom-slide .modal-inner-wrap {
        background-color: #f4f4f4;
        box-sizing: border-box;
        height: auto;
        min-height: 100%
    }

    body._has-modal-custom {
        overflow: hidden;
    }

    body._has-modal-custom .modal-custom-overlay {
        background-color: rgba(51, 51, 51, 0.55)
    }

    .modal-popup {
        pointer-events: auto
    }

    .modal-popup.modal-slide .modal-inner-wrap[class] {
        background-color: #f4f4f4
    }

    .modal-popup.modal-slide._inner-scroll._show {
        -webkit-overflow-scrolling: touch;
        overflow-y: auto
    }

    .modal-popup.modal-slide._inner-scroll .modal-inner-wrap {
        height: auto;
        min-height: 100%
    }

    .modal-popup .modal-title {
        font-size: 14px;
        font-weight: 700
    }

    .minicart-items .product>.product-item-photo,
    .minicart-items .product>.product-image-container {
        float: left
    }

    .product-image-container {
        display: inline-block;
        max-width: 100%
    }
}

@media only screen and (min-width:769px) {
    .abs-no-display-desktop,
    .opc-estimated-wrapper,
    .sidebar .block.widget .pager .item:not(.pages-item-next):not(.pages-item-previous) {
        display: none
    }
}

body.checkout-index-index {
    .opc-wrapper .new-address-popup {
        margin-bottom: 24px;
        margin-top: 20px;
    }

    .checkout-container {
        @include bp-lt($tablet) {
            margin-bottom: 50px;
        }
    }

    .opc-summary-wrapper .minicart-items .minicart-items-wrapper .product {
        @include bp-lt($tablet) {
            min-height: calc(#{rem(48)} + #{rem(22)}) !important;
        }
    }

    .opc-summary-wrapper .opc-block-summary > span,
    .opc-wrapper #checkoutSteps .step-title,
    .opc-wrapper #checkoutSteps li.checkout-payment-method .step-title,
    .opc-wrapper #checkoutSteps li.checkout-shipping-address .step-title {
        margin-top: -1px;
        margin-left: -1px;
        margin-right: -1px;
    }

    .table-checkout-shipping-method {
        margin-top: 18px;
    }

    .field.addresses .shipping-address-items .shipping-address-item .action.edit-address-link,
    .field.addresses .shipping-address-items .shipping-address-item .action.action-select-shipping-item,
    .new-address-popup button.action.action-show-popup,
    .opc-wrapper #co-shipping-method-form .actions-toolbar .primary button {
        font-weight: 400;
        letter-spacing: rem(1);
    }

    .opc-block-summary .block.items-in-cart .actions-toolbar {
        display: none;
    }

    .c-header__logo {
        height: auto;
        margin: 0;
        @include bp-lt($mobileXl) {
            text-align: center;
        }

        img {
            height: auto;
            max-width: rem(283);
            position: static;

            @include bp-lt($small) {
                max-width: rem(180);
            }
        }
    }

    header.page-header {
        background-color: #F9F9F9;
        border-bottom: 1px solid #E5DFDF;
    }

    header.page-header .header.content {
        padding: 40px 0 45px;

        @include bp-lt($small) {
            padding: 25px 0 30px;
        }
    }

    .checkout_index_index.page-title-wrapper .page-title {
        position: relative;

        font-family: $fontAlt;
        font-weight: 300;
        font-size: rem(30);

        text-transform: uppercase;
        color: $purple--dark;
        text-align: center;
        letter-spacing: rem(8);

        margin: 52px 0 46.5px;
        padding-bottom: rem(30);

        @include bp-lt($medium) { font-size: rem(28); }
        @include bp-lt($small) {
            font-size: rem(26);
            margin: 25px 0 20px;
            padding-bottom: rem(20);
        }
        @include bp-lt($mobileL) { font-size: rem(22); }

        &:after {
            content: '';
            position: absolute;
            left: 50%;
            bottom: 0 !important;
            transform: translateX(-50%);
            display: block;
            width: rem(50);
            height: rem(1);
            background: $corail;
        }
    }

    #opc-new-shipping-address {
        .field.required .label span:after,
        .field._required .label span:after {
            content:'*';
            color: $error;
            font-family: $maven;
            font-size: rem(16);
            margin-left: rem(2);
        }
    }
}