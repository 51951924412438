// ========================================= //
// Magento/ opc-progress-bar
// ========================================= //
$opc-progress-bar-MQL: 1440px;
$opc-progress-color: rgba($white, .3);
$opc-progress-color-active: $white;
$opc-progress-dot-size: 12px;

.opc-sidebar .opc-block-shipping-information {
    padding: 0;
    margin: 0;
    border: 0 !important;

    @include bp-lt(768px){
        padding-bottom: 40px;
    }

    .ship-to, .ship-via {
        border: 1px solid $grey--02 !important;
        margin: 0;
        padding-bottom: 16px;
    }

    .ship-to {
        border-bottom: 0 !important;
        margin: 0;
        padding-bottom: 16px;

        a {
            color: inherit;
        }
    }

    .shipping-information .shipping-information-title {
        font-family: $maven;
        font-size: 14px!important;
        color: #000000;
        letter-spacing: 1px!important;
        line-height: 28px!important;
        padding: 17px 22px;
        padding-bottom: 0;
        margin: 0;
        text-transform: uppercase;
        margin-bottom: 15px;
        border-bottom: 0;
    }

    .action.action-edit {
        width: 20px;
        height: 20px;
        position: absolute;
        top: calc(50% + 6px);
        transform: translateY(-50%);
        right: 24px;

        &::before {
            content: '\EA25';
            font-family: $iconFont;
            color: #CF5959;
            font-size: 18px;
        }
    }

    .shipping-information .shipping-information-content {
        font-family: $maven;
        font-size: 15px;
        color: #000000;
        letter-spacing: 0.45px;
        line-height: 28px;
        padding: 0 22px;
    }
}

.opc-sidebar {
    .data.table.table-totals {
        border-top: 0;
        border-right: 0;
        border-left: 0;
        margin-bottom: 0;
        padding: 27px 0 45px 0;

        tr:last-child .price::after {
            display: none;
        }
    }

    .data.table.table-totals .totals.shipping.excl .mark .value {
        display: none;
    }

    .data.table.table-totals .amount,
    .data.table.table-totals .mark,
    .data.table.table-totals .mark .label {
        font-family: $maven;
        font-size: 15px;
        color: #000000;
        letter-spacing: 0.45px;
        line-height: 28px;
        background: none;
        font-weight: 400;
    }

    .data.table.table-totals tr.even, .data.table.table-totals tr.alt, .data.table.table-totals tr:nth-of-type(even) {
        background: white;
    }

    .opc-block-summary .totals-tax {
        padding: 16px 0;

        .price::before,
        .price::after {
            content: '';
            width: rem(80);
            height: 1px;
            background-color: $grey--02;
            display: block;
            position: absolute;
            left: 35px;
            // transform: translateX(-50%);
        }

        .price::after {
            bottom: 0;
        }

        .price::before {
            top: 0;
        }
    }
    .opc-block-summary .grand.totals strong {
        font-weight: 400;
    }

    .opc-block-summary  .totals.sub,
    .opc-block-summary  .totals.discount,
    .opc-block-summary  .grand.totals,
    .opc-block-summary .totals.shipping.excl,
    .opc-block-summary .totals-tax {
        th {
            padding: 16px 0 !important;
            padding-left: 20px !important;
            padding-right: 40px !important;
            text-align: right;
        }

        td.amount {
            padding: 16px 0 !important;
            text-align: left;
            padding-right: 20px !important;
            padding-left: 40px !important;
        }

        th, td {
            position: relative;

            span, strong {
                padding: 0;
            }
        }

        span.label {
            padding: 0;
        }
    }

    .totals-tax {
        background: none !important;
    }

    .cart-totals .grand .amount, .opc-block-summary .table-totals .grand .amount,
    .cart-totals .grand .mark, .opc-block-summary .table-totals .grand .mark {
        border: 0;
    }
}

.opc-progress-bar {

    background-color: $corail;

    padding-left: 0;
    list-style: none;
    margin-top: 0!important;
    margin-bottom: rem(100)!important;

    display: flex!important;
    justify-content: center;

    counter-reset: progress!important;

    // M E D I A   Q U E R I E S
    @include bp-lt(768px) {
        margin-bottom: rem(40)!important;
    }

    // E L E M E N T S
    .opc-progress-bar-item {

        flex-basis: 0;
        flex-grow: 1;

        color: $white;
        font-size: rem(16);
        font-weight: 400;
        letter-spacing: em(1.14, 16);
        line-height: (28/16);
        text-align: center;
        text-transform: uppercase;

        position: relative;
        padding-top: rem(32);
        padding-bottom: rem(43);
        height: 100%;

        &::before {
            display: none!important;
        }

        span {
            padding: 0;
            color: $white;
            font-size: rem(16);
            line-height: (28/16);
            font-weight: 400;

            &, &::before, &::after {
                display: block;
            }

            &::before {
                counter-increment: progress;
                content: counter(progress);

                font-family: $maven;
                font-size: rem(16);
                font-weight: 400;
                line-height: (28/16);
                text-transform: none;
                color: $corail--dark--2;

                margin-bottom: rem(8);

                background: none;
                border: none;
                height: auto;
                width: auto;
                position: static;
                margin-left: 0;
                border-radius: 0;
            }

            &::after {
                content:'' !important;
                width: rem(40);
                height: rem(4);
                background: $corail--dark--2;
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                margin-left: 0;
                top: auto;
                border: 0;
                border-radius: 0;
            }

        }

        &:not(._active) {
            opacity: 0.35;
        }

        &._complete>span {
            color: $opc-progress-color-active;
        }

        &._active,
        &:first-of-type:hover {

            color: $opc-progress-color-active;
            background: none;

            span {
                background: none;
                border: none;
                color: $white;
                font-weight: 400;
                font-size: rem(16);

                &::after {
                    border: 0;
                    border-radius: 0;
                }

                &::before {
                    color: $red;
                    background: none;
                }

            }
        }

        &:first-of-type:not(._active):hover {
            cursor: pointer;
        }

    }


    // M E D I A   Q U E R I E S
    @include bp-gt(1200px)
    {
        margin-bottom: $spacer * 3;

        // E L E M E N T S
        .opc-progress-bar-item {
            flex-basis: rem(250px);
            max-width: rem(250px);
            margin: 0 (20px / 2);
        }

    };

}
