// ========================================= //
// Magento/ block discount
// ========================================= //
$block-discount-bg: darkblue;
$block-discount-color: $white;
$block-discount-width-lg: percentage(331/380);
$block-discount-input-height: 3rem;

.block.discount {

    background-color: $block-discount-bg;
    color: $block-discount-color;

    .fieldset {
        background-color: transparent;
        border-radius: 0px;
        margin: 0;
        max-width: none;

        .control {
            position: relative;

            input {
                padding-right: $block-discount-input-height;
                height: 3rem;
            }

            .action {
                position: absolute;
                top: 0;
                right: 0;
                width: $block-discount-input-height;
                height: $block-discount-input-height;

                background: none;
                border: none;
                color: darkblue;
                font-size: rem(24px);
                line-height: 0;

                cursor: pointer;

                &:hover {
                    color: lighten(darkblue, 15%);
                }
            }

            .action.apply {
                color: $red;
                &:hover {
                    color: lighten($red, 15%);
                }
            }
        }
    }

    .input input,
    .field input,
    .input select,
    .field select,
    .input textarea,
    .field textarea {
        border-radius: 5px;
        border-width: 1px;
    }

    // M E D I A   Q U E R I E S
    @include bp-gt(768px)
    {

    };

    @include bp-gt(1024px)
    {
        width: $block-discount-width-lg;
        margin-left: auto;
    };

    @include bp-gt(1440px)
    {

    };

}
