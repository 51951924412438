.o-slider__launcher {
    max-width: 62.5%;
    position: relative;
    padding-right: 7px;
    height: calc(100vh - 210px);

    @include bp-lt($medium){
        max-width: 100%;
        padding-right: 0;
        height: auto;
    }

    .o-slide__launcher a {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        overflow: hidden;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;

            @include bp-lt($medium){ height: auto; }
        }
    }

    .slide-dots {
        margin: 0;
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        line-height: 0;

        li button {
            position: relative;
            text-indent: -9999px;
            padding: 0;
            margin: 0;
            font-size: 0;
            line-height: 0;

            &:before {
                content: '';
                width: 30px;
                height: 5px;
                display: block;
                background-color: $grey--11;
            }
        }

        li {
            display: inline-block;
            margin: 0 5px;

            &.slick-active {
                button:before { background-color: $corail; }
            }
        }
    }

    .slick-list, .slick-track {
        height: 100%;
    }
}

.c-wrapper__images {
    max-width: 37.5%;
    width: 100%;
    padding-left: 7px;
    height: calc(100vh - 210px);

    @include bp-lt($medium){
        max-width: 100%;
        width: 100%;
        height: auto;
        padding-left: 0;
        padding-top: 14px;

        display: flex;
        flex-flow: row wrap;
        align-items: stretch;
    }

    .o-block__product img {
        width: 100%;
        object-fit: cover;
    }
}

.c-grid__image {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    margin-bottom: 52px;

    @include bp-lt($small){
        margin-bottom: 12px;
    }

    img {
        max-width: none;
        margin: 0 auto;

        @include bp-lt($medium){
            height: auto;
            width: 100%;
            max-width: 100%;
         }
    }

    .o-block__product {
        padding-bottom: 7px;
        height: 50%;

        @include bp-lt($medium){
            max-width: 50%;
            width: 100%;
            height: auto;
            padding-bottom: 0;
            padding-right: 7px;
        }

        img {
            transition: $ease;
            object-fit: cover;
            height: 100%;
        }

        &:hover img {
            transform: scale(1.15);
        }

        a {
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            height: 100%;
        }

        + .o-block__product {
            padding-bottom: 0;
            padding-top: 7px;

            @include bp-lt($medium){
                padding-top: 0;
                padding-right: 0;
                padding-left: 7px;
            }
        }
    }
}