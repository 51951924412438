// ========================================= //
// tabs
// ========================================= //

.c-tabs__controler {
    height: rem(59);
    padding: 0;
    margin: 0 0 rem(48);
    list-style: none;
    text-align: center;
    border-top: $border--grey--02;
    border-bottom: $border--grey--02;

    @include bp-lt($mobileXl) {
        height: auto;
    }

    li {
        display: inline-block;
        margin-left: rem(40);
        margin-right: rem(40);

        @include bp-lt($medium) {
            margin-left: rem(20);
            margin-right: rem(20);
        }

        @include bp-lt($tablet) {
            margin-left: rem(7);
            margin-right: rem(7);
        }

        @include bp-lt($bp600) {
            margin-left: rem(5);
            margin-right: rem(5);
        }

        @include bp-lt($mobileXl) {
            display: block;
            width: 100%;
            margin-left: 0;
            margin-right: 0;
        }
    }

    a {
        position: relative;
        display: block;
        height: rem(59);
        padding-left: rem(15);
        padding-right: rem(15);
        font-family: $maven;
        font-size: rem(14);
        font-weight: 700;
        color: $mainBranding;
        text-transform: uppercase;
        letter-spacing: rem(1);
        opacity: .35;
        transition: $ease;

        @include bp-lt($tablet) {
            padding-left: rem(10);
            padding-right: rem(10);
        }

        @include bp-lt($bp600) {
            padding-left: rem(5);
            padding-right: rem(5);
            font-size: rem(13);
        }

        @include bp-lt($mobileXl) {
            height: rem(35);
        }

        &:before,
        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            display: block;
            width: 0;
            height: rem(2);
            background: $subBranding;
            transition: $ease;
        }

        &:before { left: 50%; }
        &:after { right: 50%; }

        &:hover, &.is-active {
            opacity: 1;

            &:before,
            &:after {
                width: 50%;
            }
        }
    }
}

.c-tabs {
    .content-home-block {
        display: none;

        &.is-visible {
            display: block;
        }
    }
}