// ========================================= //
// labels
// ========================================= //

[class*='c-product__label'] {
    $side : 45;

    position: absolute;
    z-index: #{$zindex--content - 1};
    display: block;
    width: rem($side);
    height: rem($side);
    text-align: center;

    @include bp-lt($small) {
        $side : 35;

        width: rem($side);
        height: rem($side);
        left: rem(7);
    }

    @include bp-lt($mobileS) {
        left: rem(1);
    }

    &[class*='--discount'] {
        background: $subBranding;
        color: $white;
        font-family: $maven;
        font-weight: 900;
        letter-spacing: rem(1);
    }

    &[class*='--bestSeller'] {
        background: $mainBranding;
    }

    &[class*='--unstock'] {
        background: $mainBranding--lighten--04;
    }

    img {
        position: relative;
        top: rem(-2);
    }
}
