// ========================================= //
// cta
// ========================================= //

.c-cta__pro {
    background-image: url(#{$img}c-bkg__itemAlphaWhite.png);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;

    .c-wysiwyg {
        margin-bottom: rem(36);

        p {
            color: $white;
        }
    }
}

.c-cta__privatePerson {
    background-image: url(#{$img}c-bkg__mapFrance.png);
    background-repeat: no-repeat;
    background-position: 0 50%;
    background-size: contain;

    @include bp-lt($mobileXl) {
        background-size: cover;
    }

    span {
        margin-right: rem(30);
        display: inline-block;
        height: rem(57);
        font-family: $oswald;
        font-size: rem(21);
        color: $colorFont;
        letter-spacing: rem(1);

        @include bp-lt($tablet) {
            display: block;
            margin-right: 0;
            margin-bottom: rem(24);
        }
    }
}
