// ========================================= //
// languageSwitcher
// ========================================= //

.c-languageSwitcher {
    position: relative;
    padding-right: rem(13);
    padding-bottom: rem(3);
    float: right;
    height: rem($topLineH);
    text-align: right;

    @include bp-lt($small) {
        width: rem(70);
    }

    span,
    a {
        position: relative;
        display: inline-block;
        font-family: $maven;
        font-size: rem(12);
        text-transform: uppercase;
        font-weight: 700;
    }

    span {
        color: $white;
    }

    a {
        color: $grey;
        transition: $ease;

        @include links($white, $white, 1);
    }

    .c-separator {
        margin-left: rem(10);
        margin-right: rem(10);

        @include bp-lt($small) {
            margin-left: rem(5);
            margin-right: rem(5);
        }
    }
}
