// ========================================= //
// Magento/ table-cart-totals
// ========================================= //

.cart-totals {
    font-size: rem(16);
    line-height: 1;

    .table-caption {
        display: none;
    }

    .totals {
        border: none;
        width: 100%;
    }

    strong {
        font-weight: inherit;
    }

    tr {
        display: flex;
        position: relative;

        &::before {
            content:'';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
        }

        th, td {
            padding-top: $spacer * 0.25;
            padding-bottom: $spacer * 0.25;
            width: 50%;
        }

        th {
            text-align: right;
            padding-right: $spacer * 0.5;

            font-weight: 400;

            font-family: $maven;
            font-size: 13px;
            color: #F9F9F9;
            letter-spacing: 0.39px;
            text-align: right;
            line-height: 20px;
        }

        td {
            padding-left: $spacer * 0.5;

            font-family: $maven;
            font-size: 15px;
            color: #F9F9F9;
            letter-spacing: 0.45px;
            line-height: 20px;
        }
    }

    tr.grand.totals.excl {

        &::before, &::after {
            content:'';
            display: block;
            height: 1px;
            background: rgba(blue,0.2);
            position: absolute;
            left: 0;
            right: 0;
        }

        &::before {
            top: $spacer * 0.75;
        }

        &::after {
            bottom: $spacer * 0.75;
        }

        th, td {
            padding-top: $spacer +  $spacer * 0.75;
            padding-bottom: $spacer +  $spacer * 0.75;
        }

    }

    tr.grand.totals.incl {

        th, td {
            padding-bottom: $spacer;
        }

    }

    tr.grand.totals {
        th, td {
            padding-top: 30px;
        }
    }

    tr.totals-tax {

    }

    tr.totals.sub {
        border-top: 1px solid rgba(229,223,223,0.28);
        padding: 25px 0;
        margin-top: 25px;
        align-items: center;

        th strong, th, td {
            font-weight: 900;
            font-family: $maven;
            color: #F9F9F9;
            line-height: 20px;
        }

        th,
        th strong {
            font-size: 18px;
            letter-spacing: 0.54px;
            text-align: right;
        }

        td {
            font-size: 24px;
            letter-spacing: 0.72px;
        }
    }

    // M E D I A   Q U E R I E S
    @include bp-gt(768px)
    {

    };

    @include bp-gt(1024px)
    {

    };

    @include bp-gt(1440px)
    {

    };

}
