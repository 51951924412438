// ========================================= //
// Magento/ fieldset
// ========================================= //

.fieldset {
    border: none;
    padding: $spacer;
    margin-left: auto;
    margin-right: auto;
}

body.account .fieldset {

    // // @extend .bkgd--darkslateblue-5;
    @include make-col(12);

    &:not(:last-of-type) {
        margin-bottom: $spacer;
    }

    // E L E M E N T S
    .legend {

        // // @extend .h4;

        text-align: center;
        margin-top: 0;
        margin-bottom: $spacer;

        float: left;
        width: 100%;

        + * {
            clear: both;
        }
    }

    > .col-12 {
        padding-left: 0;
        padding-right: 0;
    }

    // M E D I A   Q U E R I E S
    @include bp-gt(768px)
    {
    };

    @include bp-gt(1024px)
    {
        @include make-col(6);
    };

    @include bp-gt(1440px)
    {

    };

}
