.account.customer-account-edit .box-account.box-info {
    .form.form-edit-account {
        display: grid;
        grid-template: "info password"
                       "facturation ."
                       "additional_info additional_info"
                       "actions-toolbar actions-toolbar";
        grid-template-columns: repeat(2, 1fr);
        column-gap: rem(20);

        @include bp-lt($small){
            grid-template: "info"
                            "password"
                            "facturation"
                       "additional_info"
                       "actions-toolbar";
        }
    }

    .form.form-edit-account .additional_info {
        display: none;
    }

    .form.form-edit-account .fieldset {
        width: 100%;
        max-width: 100%;
        flex: 0 0 100%;

        margin: 0;
        padding: rem(20);
        float: none;
        background-color: $white;
        border: solid rem(1) $grey--02;

        .mage-error {
            border-color: $corail;
        }


        select.mage-error + .mage-error,
        input.mage-error + .mage-error {
            font-family: $maven;
            font-size: rem(10);
            color: $corail;
            font-weight: 400;
            line-height: 1;
            margin-top: rem(5);
        }
    }

    .form.form-edit-account .fieldset.c-condition__check {
        border-top: 0;
        grid-area: facturation;

        .field .columns > p {
            font-family: $maven;
            font-size: rem(12);
            color: $colorFont;
            text-transform: uppercase;
            background: $white;
            line-height: 1.25;

            color: #000000;
            font-weight: 400;
            letter-spacing: rem(0.71px);
            white-space: normal;
            margin-bottom: rem(8);

            em {
                color: $subBranding;
            }
        }

        .field .columns > .c-input__holder {
            margin: 0;
        }
    }

    .legend {
        text-transform: none;
        border-bottom: none;

        @extend %box-link;

        span {
            font-weight: 400;
        }
    }

    .form.form-edit-account li {
        list-style: none;
    }

    .form.form-edit-account .c-input__holder {
        border: none;
    }

    .form.form-edit-account .fieldset.password {
        grid-area: password;

        .field.new.password.required,
        label.label span,
        label.label {
            margin: 0;
        }

        .control input {
            margin: 0;
        }

        .control {
            margin-bottom: 1.5rem;
        }
    }

    .form.form-edit-account .fieldset.additional_info {
        grid-area: additional_info;
    }

    .form.form-edit-account .actions-toolbar {
        grid-area: actions-toolbar;
    }

    .form.form-edit-account .fieldset.info {
        grid-area: info;
    }

    .form.form-edit-account {
        max-width: 100%;

        .c-input__holder input,
        .control input {
            margin: 0;
        }

        .fields select,
        .control._with-tooltip .field-tooltip.toggle #tooltip-label,
        .control._with-tooltip .field-tooltip.toggle .tooltip-label,
        div.field,
        .row {
            margin: 0;
        }

        .row .medium-6 {
            width: calc(50% - 10px);
        }
        .columns {
            padding: 0;
        }

        .field-tooltip .field-tooltip-action,
        div.field select {
            display: block;
        }

        .control {
            width: 100%;
            float: none;
        }

        input:not([type="checkbox"]), select {
            height: 50px;
            border: solid .0625rem #e5dfdf;
            box-shadow: none;
        }

        select {
            background-color: white;
        }

        .field-tooltip .field-tooltip-action:before {
            font-family: $maven;
            content:"?";
            width: 20px;
            height: 20px;
            border-radius: 16px;
            background-color: $mainBranding;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            line-height: 20px;
            color: $white;
        }

        .field-tooltip.toggle {
            display: inline-block;
            position: relative;

            #tooltip-label,
            .tooltip-label,
            .field-tooltip-content {
                display: none;
            }

            .field-tooltip-content {
                position: absolute;
                right: 0;
                top: -10px;
                background: #fff;
                padding: 10px;
                min-width: 200px;
                box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                transform: translateY(-100%);
                font-size: 14px;
                line-height: 1.25;
            }

            &._active .field-tooltip-content {
                display: block;
            }
        }

        .field.new.password.required {
            margin-bottom: 10px;

            input {
                margin-bottom: 0;
            }
        }

        .label span,
        .label {
            background: none;
            padding: 0;
            width: auto;
            text-align: left;
            float: none;
            display: inline-block;
            line-height: 1;
            color: $black;

            font-family: $maven;
            font-size: rem(16);
            font-weight: 400;
            text-transform: none;
            margin-bottom: 8px;
            white-space: normal;
        }

        .label span,
        .c-input__holder label {
            display: inline-block;
            width: auto;
            height: rem(12);
            padding: 0;
            padding-left: rem(5);
            padding-right: rem(5);
            font-family: $maven;
            font-size: rem(10);
            color: $colorFont;
            text-transform: uppercase;
            background: $white;
            line-height: 1;

            color: #000000;
            font-weight: 400;
            letter-spacing: rem(0.71px);
            white-space: normal;

            &.required {
                em {
                    color: $subBranding;
                    float: right;
                }
            }
        }

        .password-strength-meter {
            font-family: $maven;
            font-size: rem(10);
            color: $colorFont;
        }

        .fieldset.password {
            @include bp-lt($small){
                margin: rem(10) 0 0;
            }
        }

        .fieldset.password .field {
            position: relative;
        }

        .fieldset.password .label,
        .fields .label span,
        .fields .c-input__holder label {
            position: absolute;
            left: rem(7);
            top: 0;
            transform: translateY(-50%);
        }

        .field.choice {
            line-height:1;
            margin-bottom: rem(10);

            label {
                padding-left: 15px;
                margin: 0;
                line-height: 16px;
            }

            label span {
                height: auto;
                margin: 0;
                line-height: 16px;
            }

            &::after,
            &::before {
                display: none;
            }
        }

        .buttons-set {
            margin: rem(50) 0 rem(50)!important;
            flex-direction: row;

            @include bp-lt($small) {
                margin: rem(20) 0 rem(50)!important;
            }

            button,
            p,
            p a {
                @include bp-lt($mobileXl) {
                    width: 100%;
                }
            }

            button,
            p:not(.required) {
                margin: 0;
            }

            p.required {
                @include bp-lt($mobileXl) {
                    order: 0;
                    margin-bottom: rem(20);
                }
            }

            p:not(.required) {
                @include bp-lt($mobileXl) {
                    order: 2;
                }
            }

            button {
                width: auto;
                margin-left: 10px;

                @include bp-lt($mobileXl) {
                    margin-left: 0;
                    margin-bottom: rem(20);
                    order: 1;
                    width: 100%;
                }
            }
        }

        .actions-toolbar.buttons-set .required {
            margin: 0;
            margin-right: auto;
            margin-left: 0;
            padding: 0;
            position: static;
            font-style: italic;

            @include bp-lt($small) {
                transform: none;
            }
        }

        .actions-toolbar > .primary, .actions-toolbar > .secondary {
            margin: 0;
        }

        .action.back,
        .actions-toolbar .action.save.primary{
            margin: 0 rem(10);
            display: flex;
            align-items: center;
            justify-content: center;

            height: rem(50);
            width: auto;
            min-width: 207px;

            padding-left: rem(30);
            padding-right: rem(30);

            text-transform: uppercase;
            text-align: center;

            font-family: $maven;
            font-size: rem(14);
            font-weight: 400;

            letter-spacing: rem(1);
            transition: $ease;

            @include bp-lt($small){
                margin: rem(10) 0;
            }
        }

        .actions-toolbar .action.save.primary{
            border: none;
            border-bottom: solid rem(2);

            background: $corail;
            color: $white;
            border-bottom-color: $corail--dark;

            &:hover {
                background: $mainBranding;
                color: $white;
                border-bottom-color: $mainBranding--darken--02;
            }
        }
    }
}

.newsletter-manage-index .box-account.box-info {
    .label span,
    .label {
        background: none;
        padding: 0;
        width: auto;
        text-align: left;
        float: none;
        display: inline-block;
        line-height: 1;
        color: $black;

        font-family: $maven;
        font-size: rem(16);
        font-weight: 400;
        text-transform: none;
        margin-bottom: 8px;
        white-space: normal;
    }

    .legend {
        text-transform: none;
        border-bottom: none;

        @extend %box-link;

        span {
            font-weight: 400;
        }
    }

    .form .fieldset {
        width: 100%;
        max-width: 100%;
        flex: 0 0 100%;

        margin: 0;
        padding: rem(20);
        float: none;
        background-color: $white;
        border: solid rem(1) $grey--02;

        .mage-error {
            border-color: $corail;
        }


        select.mage-error + .mage-error,
        input.mage-error + .mage-error {
            font-family: $maven;
            font-size: rem(10);
            color: $corail;
            font-weight: 400;
            line-height: 1;
            margin-top: rem(5);
        }
    }

    .form .label span,
    .form .c-input__holder label {
        display: inline-block;
        width: auto;
        height: rem(12);
        padding: 0;
        padding-left: rem(5);
        padding-right: rem(5);
        font-family: $maven;
        font-size: rem(10);
        color: $colorFont;
        text-transform: uppercase;
        background: $white;
        line-height: 1;

        color: #000000;
        font-weight: 400;
        letter-spacing: rem(0.71px);
        white-space: normal;

        &.required {
            em {
                color: $subBranding;
                float: right;
            }
        }
    }

    .form .field.choice {
        line-height:1;
        margin-bottom: rem(10);

        label {
            padding-left: 15px;
            margin: 0;
            line-height: 16px;
        }

        label span {
            height: auto;
            margin: 0;
            line-height: 16px;
        }

        &::after,
        &::before {
            display: none;
        }
    }

    .actions-toolbar {
        @include bp-lt($small) {
            flex-flow: column wrap;
        }
    }

    .actions-toolbar  .required {
        margin: 0;
        margin-right: auto;
        margin-left: 0;
        padding: 0;
        position: static;
        font-style: italic;

        @include bp-lt($small) {
            transform: none;
        }
    }

    .actions-toolbar > .primary,
    .actions-toolbar > .secondary {
        margin: 0;
    }

    .action.back,
    .actions-toolbar .action.save.primary{
        margin: 0 rem(10);
        display: flex;
        align-items: center;
        justify-content: center;

        height: rem(50);
        width: auto;
        min-width: 207px;

        padding-left: rem(30);
        padding-right: rem(30);

        text-transform: uppercase;
        text-align: center;

        font-family: $maven;
        font-size: rem(14);
        font-weight: 400;

        letter-spacing: rem(1);
        transition: $ease;

        @include bp-lt($small){
            margin: rem(10) 0;
        }
    }

    .actions-toolbar .action.save.primary{
        border: none;
        border-bottom: solid rem(2);

        background: $corail;
        color: $white;
        border-bottom-color: $corail--dark;

        &:hover {
            background: $mainBranding;
            color: $white;
            border-bottom-color: $mainBranding--darken--02;
        }
    }
}