// ========================================= //
// links
// ========================================= //

.c-link__readMore {
    position: relative;
    display: inline-block;
    font-family: $maven;
    color: $colorFont;
    font-size: rem(14);
    font-weight: 700;
    letter-spacing: rem(.5);
    text-transform: uppercase;
    transition: $ease;

    @include links($subBranding, $subBranding, 1);

    span {
        color: $subBranding;
        margin-right: rem(12);
        position: relative;
        top: -1px;
    }
}
