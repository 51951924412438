// ========================================= //
// links
// ========================================= //

@mixin links($color, $background, $height) {
    &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: rem(-10);
        width: 100%;
        height: rem($height);
        background: $background;
        opacity: 0;
        transition: $ease;
        pointer-events: none;
    }

    &:hover, &:focus {
        color: $color;

        &:after {
            opacity: 1;
            bottom: 0;
        }
    }
}
