@media print {
    .page-print {

        .c-header__logo a {
            font-size: 0;
        }

        .page-title-wrapper {
            text-align: center;
            margin-bottom: 40px;
        }

        .page-title-wrapper .page-title {
            font-size: 24px;
        }

        .container-fluid {
            max-width: 95%;
            margin: 0 auto;
        }

        .product.name.product-item-name {
            text-align: left;
        }


        .col.name {
            min-width: 300px;
        }

        .table-wrapper  table tr.totals-tax {
            th, td {
                border-top: 1px solid #e6e8ea;
                background: #fff;
                padding: 10px;
                padding-right: 30px;
                word-break: break-word;
                font-family: Ubuntu, Helvetica, Arial;
                text-align: right;
            }

            th {
                text-align: right;
                font-weight: 300;

                margin-left: 20px;
                color: #463b41;

                font-size: 16px;
                line-height: 22px;
                font-weight: 300;
            }

            td {
                text-align: left;
            }
        }

        .table-wrapper {
            margin-bottom: 40px;
        }

        .table-wrapper table.table {
            table-layout: auto;
        }

        table.table th {
            white-space: normal;
        }

        .table-wrapper table th, .table-wrapper table td {
            border-top: 1px solid #e6e8ea;
        }

        .table-wrapper  table td,
        .table-wrapper  table td strong,
        .table-wrapper  table td span {
            font-size: 10px;
        }

        .table-wrapper  table th,
        .table-wrapper  table th strong,
        .table-wrapper  table th span {
            font-size: 11px;
        }

        .table-wrapper table .amount {
            padding-right: 10px!important;
        }

        .table-wrapper  table th, .table-wrapper  table td {
            text-align: center;
        }

        .block.block-order-details-view .block-title {
            text-align: center;
        }

        .block.block-order-details-view .block-content .box{
            width: 50%;
            float: left;
            padding: 20px;
        }

        .block.block-order-details-view .block-content .box .box-title {
            font-size: 13px;
            text-decoration: underline;
        }

        .payment-method .title {
            font-weight: 400;
        }
    }
}