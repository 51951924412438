// ========================================= //
// Magento/ account-nav
// ========================================= //

$account-nav-bg: black;
$account-nav-color: $white;
$account-nav-height: rem(80);
$account-nav-height-md: rem(120);
$account-nav-gutter: $spacer;

nav.account-nav {

    background-color: $account-nav-bg;
    box-shadow: 50vw 0 0 0 $account-nav-bg, -50vw 0 0 0 $account-nav-bg;
    font-family: $maven;
    font-size: rem(18);
    font-weight: 700;
    letter-spacing: em(2,18);
    line-height: (18/22);
    text-transform: uppercase;
    white-space: nowrap;

    margin-bottom: $spacer;

    // E L E M E N T S
    a {
        color: $account-nav-color;
        text-decoration: none;
        opacity: .3;

        transition: opacity .3s ease;

        &:hover, &:focus {
            color: $account-nav-color;
            opacity: 1;
            transition-duration: .1s;
        }
    }

    strong {
        font-weight: inherit;
    }

    .items {
          padding-left: 0;
  list-style: none;
        margin: 0;
        display: flex;
        justify-content: space-between;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    .item {
        flex-shrink: 0;

        height: $account-nav-height;
        padding-left: 0;
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        &::before {
            content: '';
            position: absolute;
            top: auto;
            bottom: 0;
            left: 50%;
            margin-left: -36px * 0.5;
            display: block;
            width: 36px;
            height: 3px;
            background: $red;
            margin-top: $spacer * 2;
        }

        &:not(.current)::before {
            opacity: 0;
        }

        &, &:not(:last-child) {
            margin-bottom: 0;
        }

    }

    .item + .item {
        margin-left: $account-nav-gutter;
    }

    // M E D I A   Q U E R I E S
    @include bp-gt(768px)
    {
        margin-bottom: $spacer * 3;
    };

    @include bp-gt(1024px)
    {
        .item {
            height: $account-nav-height-md;
        }
    };

    @include bp-gt(1440px)
    {
        margin-left: percentage(-1/12);
        margin-right: percentage(-1/12);
    };

}
