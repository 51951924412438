// ========================================= //
// placeholder
// ========================================= //

// Change placeholder color
// Internet Explorer 10+ (!)
@mixin placeholder-color($color) {
    &::-webkit-input-placeholder {
        color:$color;
        line-height: initial;
    }

    &::-moz-placeholder {
        color:$color;
        opacity:1;
    }

    &:-ms-input-placeholder {
        color:$color;
    }

    &:-moz-placeholder {
        color:$color;
        opacity:1;
    }
}
