// ========================================= //
// Magento/ checkoutCartItem
// ========================================= //

$checkoutCartItem-padding: rem(12);

.checkoutCartItem {

    $root: &;

    border-bottom: 1px solid blue;

    // dirty overides
    .c-wysiwyg table tbody & {

        a {
            color: $white;
            text-decoration: none;
        }

        td {
            height: auto;
            white-space: normal;
            text-align: left;
            padding-left: 0;
            padding-right: 0;
        }

        #{$root} {

            // E L E M E N T S
            &__img
            {
                padding-left: $checkoutCartItem-padding * 0.5;
                padding-right: $checkoutCartItem-padding * 0.5;
                text-align: center;

                figure {
                    margin: 0;
                }

                img {
                    width: rem(72px);
                    height: rem(72px);
                    max-width: none;
                }

            };

            &__title
            {
                padding-top: $checkoutCartItem-padding * 0.5;
                padding-bottom: $checkoutCartItem-padding * 0.5;
                padding-left: $checkoutCartItem-padding;
                padding-right: $checkoutCartItem-padding;

                min-width: rem(250px);
                border-left: none !important;

                dl {
                    display: block;
                    margin: 0;

                    dt, dd {
                        display: inline;
                        margin: 0;
                    }
                }

                .message:last-child {
                    margin-bottom: 0;
                }
            };

            &__msrp
            {
                padding-left: $checkoutCartItem-padding;
                padding-right: $checkoutCartItem-padding;
            };

            &__price
            {
                padding-left: $checkoutCartItem-padding;
                padding-right: $checkoutCartItem-padding;
            };

            &__packaging
            {
                padding-left: $checkoutCartItem-padding;
                padding-right: $checkoutCartItem-padding;
            };

            &__quantity
            {
                padding-left: $checkoutCartItem-padding;
                padding-right: $checkoutCartItem-padding;

                input {
                    border-radius: 5px;
                    border-width: 1px;
                }

                .cartQuantity__btn {
                    padding-bottom: 0;
                }
            };

            &__subtotal
            {
                padding-left: $checkoutCartItem-padding;
                padding-right: $checkoutCartItem-padding;
            };

            &__actions
            {
                padding-left: $checkoutCartItem-padding;
                padding-right: $checkoutCartItem-padding;
                position: relative;
                min-width: rem(34);
                overflow: hidden;

                .actions-toolbar {
                    margin-top: 0;
                }

                .action-delete {
                    font-size: rem(34);
                    line-height: 1;
                    margin: 0;
                    padding: 1em;
                    position: absolute;
                }
            };

        }
    }

    // M E D I A   Q U E R I E S
    @include bp-gt(768px)
    {

    };

    @include bp-gt(1024px)
    {

    };

    @include bp-gt(1440px)
    {

    };
}
