// variables} = primordials;

$rgpd-headings-font-family: $oswald;
$rgpd-font-family: $maven;
$rgpd-link-color: $black;

$rgpd-banner-bg: $corail;
$rgpd-bg: $white;
$rgpd-color: $black;
$rgpd-backdrop-bg: rgba($mainBranding--lighten--02, 0.50);
$rgpd-category-main-bg: $grey--05;
$rgpd-close-color: $white;
$rgpd-service-bg: $white;
$spacer: 20px;

$font-weight-bold: 700;
$font-weight-base: 400;
$headings-font-weight: $font-weight-bold;
$button-height: 50px;
$line-height-base: 1.87;

html.axeptio-widget--open {
    body > *:not(.axeptio_mount) {
        opacity: .5;
        filter: blur(5px);
        overflow: hidden;
        pointer-events: none;
    }

    #axeptio_overlay {
        @media (min-width: 450px) {
            [class*="WebsiteOverlay__WebsiteOverlayStyle"] {
                bottom: auto !important;
                top: 0 !important;
            }

            [class*="AxeptioButton__AxeptioButtonStyle"] {
                bottom: auto !important;
                top: calc(100vh - 10px) !important;
                transform: translateY(-100%) !important;
            }

            .ax-widget-container {
                left: 50% !important;
                top: 50vh !important;
                bottom: auto !important;
                transform: translate(-50%,-50%);
            }
        }
    }
}
