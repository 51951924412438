// ========================================= //
// mfp
// ========================================= //

.c-mfp__addtocart,
.c-mfp__classic {
    position: relative;
    max-width: rem(691);
    min-height: rem(150);
    margin-left: auto;
    margin-right: auto;
    background: $white;

    @include bp-lt($medium) { width: 80%; }
    @include bp-lt($small) { width: 90%; }

    .c-mfp__content {
        padding: rem(48) rem(24);
        border-left: $border--grey--02;
        color: $colorFont;

        @include bp-lt($small) {
            padding: rem(24);
        }
    }

    .c-mfp__content.columns {
        padding-top: rem(48);

        @include bp-lt($small) {
            padding-top: rem(24);
        }
    }

    .c-mfp__product__title {
        font-family: $oswald;
        font-style: rem(18);
        text-transform: uppercase;
        margin-bottom: rem(10);
        letter-spacing: rem(2);
    }

    .c-mfp__product__price {
        font-family: $oswald;
        font-size: rem(28);
        margin-bottom: rem(30);
    }

    .c-mfp__product__quantity,
    .c-mfp__product__info {
        font-family: $maven;
        font-size: rem(15);
        letter-spacing: rem(.5);
    }

    .c-mfp__product__quantity {
        margin-bottom: rem(30);
    }

    .c-mfp__footer {
        padding-top: rem(20);
        padding-bottom: rem(20);
        border-top: $border--grey--02;

        [class*='c-button'] {
            vertical-align: top;
            margin-left: rem(5);
            margin-right: rem(5);

            @include bp-lt($mobileXl) {
                display: block;
                width: 100%;
                margin-left: 0;
                margin-right: 0;

                &:nth-child(1) { margin-bottom: rem(24); }
            }
        }
    }
}

.mfp-close-btn-in .mfp-close {
    color: $subBranding;
}

.c-mfp__team {
    $thumbW : 290;
    $thumbW--tablet : 200;
    @include clearfix;

    .c-mfp__team__thumb {
        float: left;
        width: #{$thumbW}px;

        @include bp-lt($tablet) {
            width: #{$thumbW--tablet}px;
        }

        @include bp-lt($mobileXl) {
            width: 100%;
            text-align: center;
            padding-top: rem(48);

            img {
                width: 50%;
            }
        }
    }

    .c-mfp__team__content {
        float: right;
        width: calc(100% - #{$thumbW}px);
        padding: rem(60) rem(24);

        @include bp-lt($tablet) {
            padding: rem(40) rem(24);
            width: calc(100% - #{$thumbW--tablet}px);
        }

        @include bp-lt($mobileXl) {
            width: 100%;
        }
    }

    .c-mfp__team__name {
        font-family: $oswald;
        font-size: rem(28);
        text-transform: uppercase;
        color: $colorFont;
        letter-spacing: rem(1);
        margin-bottom: rem(10);
    }

    .c-mfp__team__location {
        font-family: $maven;
        font-size: rem(15);
        color: $colorFont;
        letter-spacing: rem(.5);
    }

    .c-separator {
        display: block;
        width: rem(43);
        height: rem(2);
        margin-left: auto;
        margin-right: auto;
        margin-top: rem(50);
        margin-bottom: rem(50);
        background: $subBranding;

        @include bp-lt($tablet) {
            margin-top: rem(30);
            margin-bottom: rem(30);
        }
    }

    .c-mfp__team__phone {
        font-family: $oswald;
        font-size: rem(28);
        text-transform: uppercase;
        letter-spacing: rem(1);

        a {
            color: $colorFont;
            position: relative;
            display: inline-block;
            @include links($subBranding, $subBranding, 1);
        }
    }
}

.c-mfp__addtocart.c-mfp__addtocart--notavailable {
    display: flex;
    align-items: center;
    justify-content: center;
    p {
        margin: 0;
        font-family: $maven;
    }
}

/* start state */
.my-mfp-zoom-in .mfp-image-holder,
.my-mfp-zoom-in .mfp-iframe-holder,
.my-mfp-zoom-in .mfp-inline-holder {
    opacity: 0;
    transition: all 0.2s ease-in-out;
    transform: scale(0.8);
}

/* animate in */
.my-mfp-zoom-in.mfp-ready .mfp-image-holder,
.my-mfp-zoom-in.mfp-ready .mfp-iframe-holder,
.my-mfp-zoom-in.mfp-ready .mfp-inline-holder {
    opacity: 1;
    transform: scale(1);
}

/* animate out */
.my-mfp-zoom-in.mfp-removing .mfp-image-holder,
.my-mfp-zoom-in.mfp-removing .mfp-iframe-holder,
.my-mfp-zoom-in.mfp-removing .mfp-inline-holder {
    transform: scale(0.8);
    opacity: 0;
}

/* Dark overlay, start state */
.my-mfp-zoom-in.mfp-bg {
    opacity: 0;
    transition: opacity 0.3s ease-out;
}
/* animate in */
.my-mfp-zoom-in.mfp-ready.mfp-bg {
    opacity: 0.8;
}
/* animate out */
.my-mfp-zoom-in.mfp-removing.mfp-bg {
    opacity: 0;
}
