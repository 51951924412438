// ========================================= //
// wysiwygContent
// ========================================= //

$marginWysiwyg: 24;
$fontTitle : $oswald;
$fontContent : $maven;
$hrBkg : $grey--02;

h2, .h2 {
    font-family: $fontTitle;
    font-size: rem(24);
    font-weight: 300;
    color: $subBranding;
    text-transform: uppercase;
    margin-bottom: rem($marginWysiwyg);
    letter-spacing: rem(1);

    @include bp-lt($small) { font-size: rem(20); }
}

h3, .h3 {
    position: relative;
    padding-left: rem(30);
    font-family: $fontContent;
    font-size: rem(20);
    font-weight: 700;
    color: $mainBranding;
    margin-bottom: rem($marginWysiwyg);

    &:before {
        content : '';
        position: absolute;
        top: rem(14);
        left: rem(0);
        display: block;
        width: rem(16);
        height: rem(2);
        background: $subBranding;
    }
}

h4, .h4 {
    font-family: $fontContent;
    font-size: rem(18);
    color: $subBranding;
    font-weight: 400;
    margin-bottom: rem($marginWysiwyg);
    margin-left: rem(30);

    @include bp-lt($mobileL) {
        margin-left: rem(10);
    }
}

h5, .h5 {
    font-family: $fontContent;
    font-size: rem(15);
    color: $taupe;
    font-weight: 700;
    margin-bottom: rem($marginWysiwyg);
    margin-left: rem(30);
    text-transform: uppercase;

    @include bp-lt($mobileL) {
        margin-left: rem(10);
    }
}

h6, .h6 {
    font-family: $fontContent;
    font-size: rem(17);
    color: $taupe--light;
    font-weight: 700;
    margin-bottom: rem($marginWysiwyg);
    margin-left: rem(45);

    @include bp-lt($mobileL) {
        margin-left: rem(20);
    }
}

img {
    &[style="float: right;"],
    &.alignright,
    &.align-right {
        float: right;
        margin-left: rem(2*$marginWysiwyg);
        margin-bottom: rem($marginWysiwyg);
    }

    &[style="float: left;"],
    &.alignleft,
    &.align-left {
        float: left;
        margin-right: rem(2*$marginWysiwyg);
        margin-bottom: rem($marginWysiwyg);
    }

    &[style="display: block; margin-left: auto; margin-right: auto;"],
    &.aligncenter,
    &.align-center {
        display: block;
        clear: both;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: rem($marginWysiwyg);
    }
}

p {
    font-family: $fontContent;
    font-size: rem(15);
    line-height: rem(28);
    color: $colorFont;
    margin-bottom: rem($marginWysiwyg);
    letter-spacing: rem(0.25);

    @include bp-lt($small) {
        font-size: rem(14);
        line-height: rem(26);
    }
}

a {
    color: $colorLink;
    text-decoration: underline;
}

blockquote {
    position: relative;
    display: block;
    width: 100%;
    padding: rem(48) rem(120);
    margin-bottom: rem($marginWysiwyg);
    border: none;
    color: $colorFont;
    font-size: rem(15);
    font-family: $fontContent;
    text-align: center;
    letter-spacing: rem(1);
    background: $grey--05;
    margin-top: 7px;

    @include bp-lt($medium) {
        padding: rem(24) rem(60);
        font-size: rem(25);
    }

    @include bp-lt($small) {
        padding: rem(24) rem(30);
        font-size: rem(20);
    }

    &:before {
        content: '';
        position: absolute;
        top: rem(-7);
        left: rem(29);
        display: block;
        width: rem(52);
        height: rem(41);
        background: url(#{$img}c-bkg__quote.svg) no-repeat;
        background-size: contain;

        @include bp-lt($medium) {
            width: rem(32);
            height: rem(21);
        }
    }
}

ul, ol {
    list-style: none;
    margin-left: 0;
    margin-bottom: rem($marginWysiwyg);
    margin-left: rem(30);

    @include bp-lt($small) {
        margin-left: rem(15);
    }

    ul, ol {
    }

    li {
        position: relative;

        &:before {
            position: absolute;
        }

        &, * {
            font-size: rem(15);
            margin-bottom: rem(10);
            font-family: $fontContent;
        }
    }
}

ul {
    li:before {
        content: '';
        top: rem(9);
        left: rem(-20);
        display: block;
        width: 7px;
        height: 7px;
        background: $subBranding;
        border-radius: 50%;
    }

    ul li:before {
        //background: $subBranding--lighten--01;
        width: 5px;
        height: 5px;
    }

    li ul li::before {
        background: $taupe;
    }
}

ol {
    counter-reset: section;

    li:before {
        counter-increment: section;
        content: counter(section) ' .';
        color: $subBranding;
        font-weight: 700;
        font-family: $oswald;
        font-style: normal;
        left: rem(-20);
    }
}

table {
    width: 100%;

    td, th {
        border: solid rem(1) $grey--06;
    }


    tr th {
        background: $subBranding--lighten--01;
        height: rem(48)!important;
        font-size: rem(15);
        font-weight: 700;
        font-family: $fontContent;
        color: $white;
        border-left-color: $subBranding--lighten--02;
        border-right-color: $subBranding--lighten--02;

        &:first-of-type { border-left-color: $grey--06; }
        &:last-of-type { border-right-color: $grey--06; }
    }

    tr:not(:first-child):nth-child(even) {
        background: $grey--05;
    }

    td {
        height: rem(48)!important;
        font-size: rem(15);
    }


    tr { background: $white; }
}

hr {
    clear: both;
    margin-bottom: rem($marginWysiwyg);
    border-color: $hrBkg;
}

video,
iframe {
    height: auto;
    width: 100%;
    aspect-ratio: 16/9;
    margin-bottom: rem($marginWysiwyg);
}