.field.addresses .shipping-address-items {

    .shipping-address-item {
        width: 100%;
        padding: rem(27) rem(36);

        font-family: $maven;
        font-size: rem(15);
        color: $black;
        letter-spacing: 0.45px;
        line-height: (28/15);

        a {
            color: $black;

            &:hover {
                text-decoration: none;
            }
        }

        &::before,
        &::after {
            display: none;
        }

        &.selected-item {
            border-color: $grey--02;
        }

        .action.edit-address-link,
        .action.action-select-shipping-item  {
            margin: 0;
            margin-top: 10px;
            appearance: none;
            display: inline-block;
            height: rem(57);
            width: auto;
            min-width: rem(200);
            padding-left: rem(30);
            padding-right: rem(30);
            border-top: none;
            border-left: none;
            border-right: none;
            border-bottom: solid rem(2);
            text-transform: uppercase;
            text-align: center;
            font-family: $maven;
            font-size: rem(14);
            font-weight: 700;
            letter-spacing: rem(1);
            transition: $ease;

            &:hover {
                text-decoration: none;
                background: $mainBranding;
                color: $white;
                border-bottom-color: $mainBranding--darken--02;
            }
        }

        .action.action-select-shipping-item {
            background: #ce5959;
            color: #fff;
            border-bottom-color: #a8050d;
        }

        .action.edit-address-link {
            background: $grey--05;
            color: $colorFont;
            border-bottom-color: $taupe--light;

            // display: none;
        }
    }
}

.new-address-popup {
    button.action.action-show-popup {
        margin: 0;
        display: inline-block;
        height: rem(57);
        width: auto;
        min-width: rem(200);
        padding-left: rem(30);
        padding-right: rem(30);
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: solid rem(2);
        text-transform: uppercase;
        text-align: center;
        font-family: $maven;
        font-size: rem(14);
        font-weight: 700;
        letter-spacing: rem(1);
        transition: $ease;
        background: $corail;
        color: $white;
        border-bottom-color: $corail--dark;
        border-radius: 0;

        &:hover {
            background: $mainBranding;
            color: $white;
            border-bottom-color: $mainBranding--darken--02;
        }
    }
}