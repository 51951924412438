.customer-account-login .page-title-wrapper h1 {
    position: relative;
    font-family: $fontAlt;
    text-transform: uppercase;
    color: $purple--dark;
    text-align: center;
    letter-spacing: rem(8);
    font-size: rem(30);
    padding-bottom: rem(20px) !important;
    margin-bottom: rem(54px);
    margin-top: 0;

    @include bp-lt($medium) { font-size: rem(28); }
    @include bp-lt($small) { font-size: rem(26); }
    @include bp-lt($mobileL) { font-size: rem(22); }

    &:after {
        content: '';
        position: absolute;
        left: 50%;
        bottom: 0 !important;
        transform: translateX(-50%);
        display: block;
        width: rem(50);
        height: rem(1);
        background: $corail;
    }
}

.customer-account-login .c-breadcrumb {
    background: #F9F9F9;
}

.customer-account-login .o-page--bkgGrey {
    background: #fff;
}

.customer-account-login .page.messages {
    margin: 0 auto;
    width: 100%;
    float: none;
    max-width: rem(907px);

    .messages {
        padding: 0 10px;
    }
}

.customer-account-login #maincontent {
    padding: 50px 0 60px;

    @media (min-width: 768px){
        padding: 50px 0 120px;
    }
}
.customer-account-createpassword div.mage-error,
.contact-index-index div.mage-error,
.customer-account-create .c-condition__check div.mage-error,
.customer-account-create .c-input__holder .input-box select + .mage-error,
.customer-account-create .c-input__holder .input-box input + .mage-error,
.customer-account-forgotpassword .c-input__holder .input-box input + .mage-error {
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translateY(100%);

    font-size: 12px;
    color: $error;
    line-height: 1;
    padding-top: 4px;

    @include bp-lt($small){
        font-size: 10px;
    }
}

.customer-account-create .medium-6.password-field div.mage-error {
    display: block;
    font-size: 12px;
    color: $error;
    line-height: 1;
    padding-top: 4px;
    margin-top: -1.5rem;
    margin-bottom: 1.5rem;

    @include bp-lt($small){
        font-size: 10px;
    }
}

.customer-account-create .c-input__holder.type_boutique div.mage-error {
    @include bp-lt($small){
        transform: translateY(200%);
    }
}

.contact-index-index .c-condition__check div.mage-error,
.customer-account-create .c-condition__check div.mage-error {
    right: auto;
    left: 0;
}

.customer-account-create .is-adress.has-error + .is-adress input {
    border-color: $error;
}

.customer-account-create .c-input__holder .input-box #street_1 + .mage-error {
    bottom: rem(-48);
}

.customer-account-login .login-container {
    max-width: 1600px;
    margin: 0 auto;

    .block-customer-login.row:before,
    .block-customer-login.row:after {
        content: " ";
        display: none;
    }

    .row.block {
        display: flex;
        flex-wrap: wrap;
        margin: 0 auto;
        justify-content: center;
        width: 100%;
        float: none;
        max-width: rem(907px);
    }

    .col-12 {
        flex: 0 0 100%;
        max-width: 100%;
        position: relative;
        width: 100%;
        padding-right: 10px;
        padding-left: 10px;
    }

    @media (min-width: 768px){
        .col-md-6 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 50%;
            flex: 0 0 50%;
            max-width: 50%;
        }
    }

    .account-login,
    .block-new-customer {
        background-color: white;
        border: 1px solid #E5DFDF;
        border-radius: 0;
        text-align: center;


        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        padding: 40px 24px 28px;
    }

    .block-new-customer {
        padding: 40px 24px;
    }

    .mb-md-0 {
        margin-bottom: 40px;

        @media (min-width: 768px){
            margin-bottom: 0;
        }
    }

    .block-title strong,
    .content > h2 {
        font-size: 16px;
        font-family: $maven;
        font-weight: 400;
        color: $black;
        letter-spacing: 1.14px;
        text-align: center;
        line-height: 28px;
        margin: 0;
        border: none;
        text-transform: uppercase;
        text-align: center;
    }

    .actions-toolbar,
    .actions-toolbar div.primary {
        margin: 0;
    }

    .buttons-set .button,
    .actions-toolbar .action.create.primary {
        margin: 28px auto 0;

        display: flex;
        align-items: center;
        justify-content: center;

        height: rem(50);
        width: auto;
        min-width: 207px;

        padding-left: rem(30);
        padding-right: rem(30);

        border: none;
        border-bottom: solid rem(2);

        text-transform: uppercase;
        text-align: center;

        font-family: $maven;
        font-size: rem(14);
        font-weight: 400;

        letter-spacing: rem(1);
        transition: $ease;

        background: $corail;
        color: $white;
        border-bottom-color: $corail--dark;

        &:hover {
            background: $mainBranding;
            color: $white;
            border-bottom-color: $mainBranding--darken--02;
        }
    }

    .account-login .buttons-set {
        position: static;
    }

    .account-login .content {
        border: none;
        padding: 0;
    }

    .account-login {
        background-color: #F9F9F9;
        border: none;

        form {
            width: 100%;
        }

        p.required {
            font-family: $maven;
            font-size: 12px;
            color: #000000;
            letter-spacing: 0.36px;
            text-align: center;
            font-style: normal;
            margin: 0;
            line-height:15px;
        }

        .btn--password {
            font-family: $maven;
            font-size: 13px;
            font-weight: 600;
            color: #FF3E3E;
            letter-spacing: 0.39px;
            text-align: center;
            line-height: 28px;
            text-decoration: underline;
            display: inline-block;
            transition: color 0.3s ease-in-out;

            &:hover {
                color: rgba(#FF3E3E, 0.4);
            }
        }

        .buttons-set {
            padding: 0;
        }

        .buttons-set .button {
            margin-top: 24px;
        }

        .content {
            text-align: center;
        }

        .c-input__holder .input-box input + .mage-error {
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            transform: translateY(100%);

            font-size: 12px;
            color: $error;
            line-height: 1;
            padding-top: 4px;
        }

        .form-list .c-input__holder label {
            font-weight: 400;
            background: #F9F9F9;
        }

        .form-list li:not(:last-child) .c-input__holder,
        .content > h2 {
            margin-bottom: 30px;
        }
    }
}
