// ========================================= //
// blocks.nav
// ========================================= //

div.c-account {
    position: relative;
    padding-top: rem(72);

    @include bp-lt($small){
        padding-top: rem(40);
    }

    .columns.medium-10.medium-centered {
        position: static;
    }

    .page.messages .message-error.error.message {
        max-width: calc(83.33333% - 36px);
        margin: 10px auto;

        @include bp-lt($small){
            max-width: calc(100% - 30px);
        }
    }

    .c-nav__link {
        display: block;
        text-align: center;
        margin-bottom: rem(25);
        background: none;

        ul {
            list-style: none;
            margin: 0;

            li {
                display: inline-block;

                @include bp-lt($small) {
                    display: block;
                }

                &:after {
                    content: '|';
                    color: rgba($white, .3);
                    font-size: rem(13);
                    margin-left: rem(10);
                    margin-right: rem(10);

                    @include bp-lt($small) {
                        display: none;
                    }
                }

                &:last-of-type:after { display: none; }

                &.current {
                    a {
                        color: $mainBranding;
                    }
                }
            }

            a {
                font-size: rem(14);
                color: $subBranding;
                font-family: $fontBody;

                @include bp-lt($medium) {
                    font-size: rem(12);
                }

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    $height--button--large: rem(141);
    .c-nav__button {
        display: block;
        margin-bottom: rem(40);
        max-width: rem(1412);
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        background: none;
        box-shadow: 35vw 0 0 0 #F9F9F9, -35vw 0 0 0 #F9F9F9;
        padding: rem(22);
        padding-left: rem(10);
        padding-right: rem(10);
        background: #F9F9F9;

        ul {
            list-style: none;
            margin-left: rem(-7);
            margin-right: rem(-7);
            @include clearfix();

            padding: 0;

            li {
                display: block;
                width: 20%;
                height: $height--button--large;
                padding-left: rem(7);
                padding-right: rem(7);
                float: left;

                @include bp-lt($small) {
                    height: rem(40);
                }

                a, strong {
                    position: relative;
                    display: block;
                    width: 100%;
                    height: $height--button--large;
                    padding-top: rem(90);
                    padding-left: rem(20);
                    padding-right: rem(20);
                    color: $purple--dark;
                    text-align: center;
                    font-size: rem(14);
                    font-weight: 400;
                    font-family: $fontBody;
                    text-transform: uppercase;
                    letter-spacing: rem(1.17);

                    &:before {
                        content: '';
                        display: block;
                        position: absolute;
                        bottom: rem(-14);
                        left: 50%;
                        transform: translateX(-50%);
                        display: block;
                        border-top: solid rem(15) $corail;
                        border-left: solid rem(15) transparent;
                        border-right: solid rem(15) transparent;
                        opacity: 0;
                        transition: $ease;
                    }

                    @include bp-lt($medium) {
                        font-size: rem(11);
                        padding-top: rem(80);
                    }

                    @include bp-lt($small) {
                        height: auto;
                        padding: 20px;
                        font-size: 0;
                        line-height: 0;
                    }

                    &:after {
                        @include bp-lt($small) {
                            text-indent: 0;
                            font-size: rem(30);
                            width: auto;
                            height: auto;
                            padding: 0;
                            top: 0;
                            line-height: 40px;
                        }
                    }
                }

                a {
                    background: $white;
                    transition: $ease;
                    text-decoration: none;

                    display: flex;
                    flex-flow: row wrap;
                    align-items: flex-end;
                    justify-content: center;
                    padding-bottom: rem(12);
                    line-height: 1.28;

                    @include bp-lt($small) {
                        height: auto;
                        padding: 20px;
                        font-size: 0;
                        line-height: 0;
                    }

                    &:hover {
                        background: $corail;
                        color: $white;
                        text-decoration: none;


                        &:after {
                            color: $white;
                        }
                    }
                }

                &.current a {
                    background: $subBranding;
                    color: $white;

                    &:before {
                        opacity: 1;
                    }

                    &:after {
                        color: $white;
                    }
                }
            }
        }
    }
}

[class*='c-nav__item'] {
    a:after,
    strong:after {
        font-size: rem(45);
        color: $subBranding;
        position: absolute;
        width: rem(45);
        height: rem(45);
        line-height: rem(45);
        top: rem(20);
        right: 0;
        left: 0;
        margin-left: auto;
        margin-right: auto;
        z-index: $zindex--hover;
        font-family: $iconFont;
        text-align: center;
        transition: $ease;
    }

    &[class*='dashboard'] {
        a:after,
        strong:after {
            content: "\EA46"
        }
    }

    &[class*='history'] {
        a:after,
        strong:after {
            content: "\EA42"
        }
    }

    &[class*='book'] {
        a:after,
        strong:after {
            content: "\EA03"
        }
    }

    &[class*='edit'] {
        a:after,
        strong:after {
            content: "\EA45";
        }
    }

    &[class*='wishlist'] {
        a:after,
        strong:after {
            content: "\EA05"
        }
    }

    &[class*='logout'] {
        a:after,
        strong:after {
            content: "\EA0B"
        }
    }

    &[class*='documents'] {
        a:after,
        strong:after {
            content: "\EA44"
        }
    }

    &[class*='reliquat'] {
        a:after,
        strong:after {
            content: "\EA44"
        }
    }
}

