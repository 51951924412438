// ========================================= //
// pageMagento
// ========================================= //

$inputH : 48;

[class*='c-page__header--account'] + [class*='c-page__content'] {
    margin-top: rem(-124);
}

.account-create,
.account-login {
    position: relative;

    .fieldset,
    .content {
        padding: rem(40) rem(50);
        border-bottom: solid rem(1) $grey--08;

        @include bp-lt($small) {
            padding: rem(30) rem(20);
        }

        p.required {
            font-size: rem(12);
            font-family: $maven;
            font-style: italic;
            margin-top: rem(10);
        }
    }

    .legend, h2 {
        margin-bottom: rem(36);
        margin-left: rem(10);
        display: inline-block;
        font-family: $oswald;
        font-size: rem(24);
        color: $colorFont;
        text-transform: uppercase;
        letter-spacing: rem(2);
        border-bottom: solid rem(2) $subBranding;

        @include bp-lt($small) {
            font-size: rem(20);
        }
    }

    ul.form-list {
        margin: 0;
        list-style: none;
    }
}

.form-list {
    // background-color: red;

    .c-input__holder {
        position: relative;
        margin-bottom: rem(24);
        border: solid rem(1) $grey--02;

        &.type_boutique {
            @include bp-lt(500px){
                margin-bottom: 35px;
            }
        }

        p {
            position: absolute;
            margin-bottom: 0;
            font-family: $maven;
            font-size: rem(10);
            color: darken($grey--04, 30%);
        }

        #shipping_address_different + label {
            &::before {
                top: -1px;
            }

            &::after {
                top: 3px;
            }
        }

        label {
            position: absolute;
            left: rem(7);
            top: 0;
            transform: translateY(-50%);
            display: inline-block;
            width: auto;
            height: rem(12);
            padding: 0;
            padding-left: rem(5);
            padding-right: rem(5);
            font-family: $maven;
            font-size: rem(10);
            color: $colorFont;
            text-transform: uppercase;
            letter-spacing: rem(1);
            background: $white;
            font-weight: 700;
            line-height: 1;

            &.required {
                em {
                    color: $subBranding;
                    float: right;
                }
            }
        }

        select {
            background-color: $white;
            border: none;
            margin-bottom: 0;
        }

        select.required-entry.mage-error + .mage-error {
            display: none;
        }

        input, textarea {
            padding-left: rem(10);
            padding-right: rem(10);
            border: none;
            box-shadow: none;
            background: transparent;
            font-family: $maven;
            font-size: rem(15);
            color: $colorFont;
            margin-bottom: 0;
        }

        input {
            height: rem($inputH);
        }

        textarea {
            height: rem(134);
        }

        &.has-error {
            border-color: $error;

            label { color: $error; }
        }
    }

    .input-box {
        label {
            margin-bottom: 0 !important;
        }
    }

    .wide {
        padding-left: $gutter;
        padding-right: $gutter;
    }

    .wide + .wide {
        input {
            padding-left: rem(10);
            padding-right: rem(10);
            border: none;
            box-shadow: none;
            background: transparent;
            font-family: $maven;
            font-size: rem(15);
            color: $colorFont;
            border: solid rem(1) $grey--02;
            border-top: none;
            height: rem($inputH);
            margin-top: rem(-24);
        }

        margin-bottom: rem(24);
    }

    .fields {
        @include clearfix;
    }

    .field {
        &.name-prefix {
            max-width: rem(145);
        }
    }
}

.c-account .actions-toolbar,
.buttons-set {
    position: absolute;
    padding-top: rem(45);
    text-align: center;
    width: 100%;

    @include bp-lt($mobileM) {
        padding-top: rem(24);
    }

    .required {
        position: absolute;
        font-family: $maven;
        font-size: rem(15);
        color: $colorFont;
        padding-top: rem(17);

        @include bp-lt($small) {
            transform: translateY(-150%);
        }
    }

    p.back-link,
    .button {
        display: inline-block;
        margin-left: rem(5);
        margin-right: rem(5);
        vertical-align: top;
    }

    .secondary a,
    .button,
    .back-link a.back-link,
    .back-link a,
    a.f-left {
        display: inline-block;
        vertical-align: top;
        height: rem(57);
        width: rem(150);
        padding-left: rem(30);
        padding-right: rem(30);
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: solid rem(2);
        text-transform: uppercase;
        text-align: center;
        font-family: $maven;
        font-size: rem(14);
        font-weight: 700;
        letter-spacing: rem(1);
        transition: $ease;

        @include bp-lt($mobileM) {
            width: 100% !important;
            margin-bottom: rem(14);
            margin-left: 0;
            margin-right: 0;
        }

        &:hover {
            background: $mainBranding;
            color: $white;
            border-bottom-color: $mainBranding--darken--02;
        }
    }

    .button {
        background: $corail;
        color: $white;
        border-bottom-color: $corail--dark;
    }

    .back-link {
        @include bp-lt($mobileS) {
            width: 100%;
            margin-left: 0 !important;
            margin-right: 0 !important;

            a {
                margin-bottom: 0 !important;
            }
        }
    }

    .back-link a,
    a.f-left {
        padding-top: rem(17);
        padding-bottom: rem(17);
        color: $colorFont;
        border-bottom-color: $subBranding--lighten--01;
        background: $grey--05;

        small {
            display: none;
        }
    }

    .back-link a.back-link,
    a.f-left {
        background: $white;
        border-bottom-color: $subBranding--lighten--01;
    }

    a.f-left {
        width: auto;
    }
}

.account-create,
.account-login {
    .page-title {
        display: none;
    }
}

.account-create{
    .news{
        margin-top: 20px;
    }
    form{
        .check-newsletter{
            display: block;
            // margin-top: 1.25rem;
            font-family: $maven ;
            font-weight: 700;
            line-height: 1.5;
            p{
                // font-weight: bold;
                font-size: 15px;
            }
            a{
                color: $corail;
            }
        }
    }

    form > div.fieldset.c-condition__check {
        em {
            color: $subBranding;
        }
    }

    form > div.fieldset.c-condition__check + div.fieldset.c-condition__check {
        padding-top: 30px;

        @include bp-lt($small) {
            padding-bottom: 40px;
        }
    }

    .buttons-set {

        @include bp-lt($mobileM) {
            display: flex;
            flex-flow: row wrap;

            .back-link {
                order: 1;
            }

            .button {
                order: 0;
            }
        }

    }

    .buttons-set .required {

        @include bp-lt($small) {
            transform: translateY(-200%);
            padding-left: 25px;
        }
    }
}