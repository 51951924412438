// ========================================= //
// footer
// ========================================= //

.c-footer__main {
    @include clearfix;
    border-bottom: $border--grey--02;

    .columns {
        @include clearfix;
    }

    .columns.medium-6 {
        @include bp-lt($tablet) {
            width: 100%;
        }
    }

    .row > .columns:last-of-type {
        border-left: $border--grey--02;

        @include bp-lt($tablet) {
            border-left: none;
            border-top: $border--grey--02;
        }
    }
}

.c-footer__main__logo,
.c-footer__main__contact {
    width: 50%;
    float: left;
    height: rem($footerItemH);

    @include bp-lt($mobileL) {
        width: 100%;
        height: auto;
        float: none;
        padding-top: rem(12);
        padding-bottom: rem(12);
        text-align: center;
    }
}

.c-footer__main__logo {
    @include bp-lt($medium) {
        text-align: right;
        padding-left: rem(24);
        padding-right: rem(24);
    }

    @include bp-lt($mobileL) {
        text-align: center;
    }
}

.c-footer__main__contact {
    [class*="c-button"] {
        height: rem(49);
    }

    span {
        display: inline-block;
        margin-bottom: rem(14);
        font-family: $maven;
        font-weight: 700;
        font-size: rem(14);
        text-transform: uppercase;
        letter-spacing: rem(1);
        line-height: rem(24);

        @include bp-lt($tablet) {
            display: block;
        }
    }
}

$titleW : 215;
.c-footer__submenu--title,
.c-footer__submenu {
    margin-top: rem(40);

    @include bp-lt($medium) {
        margin-top: rem(24);
    }
}

.c-footer__submenu--title {
    width: rem($titleW);
    float: left;
    padding-left: rem(27);
    font-family: $oswald;
    font-size: rem(21);
    color: $purple--dark;

    @include bp-lt($medium) {
        width: 100%;
        float: none;
    }
}

.c-footer__submenu {
    width: calc(100% - #{$titleW}px);
    float: right;

    @include bp-lt($medium) {
        width: 100%;
        float: none;
        padding-left: rem(24);
        padding-right: rem(24);
    }

    ul {
        @include clearfix;
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            width: 50%;
            float: left;
            margin-bottom: rem(14);

            @include bp-lt($mobileM) { width: 50%; }

            &:nth-child(odd) {
                clear: both;
            }
        }

        a {
            position: relative;
            display: inline-block;
            font-family: $maven;
            font-size: rem(15);
            margin-left: rem(25);
            margin-bottom: rem(5);
            letter-spacing: rem(1);
            color: $mainBranding;

            @include links($mainBranding, $mainBranding, 1);

            &:before {
                position: absolute;
                top: rem(3);
                left: rem(-25);
                content: '\EA03';
                font-size: rem(12);
                font-family: $iconFont;
                color: $subBranding;
            }
        }
    }
}

.c-footer__baseline {
    padding-top: rem(10);
    padding-bottom: rem(10);

    @include bp-lt($mobileL) {
        text-align: center;
    }

    ul {
        display: inline-block;
        list-style: none;
        margin: 0;

        @include bp-lt($mobileL) {
            width: 100%;
            display: block;
        }

        li {
            display: inline-block;
            font-family: $maven;
            font-size: rem(13);
            color: $colorFont;
            vertical-align: top;

            &:first-of-type:before { display: none; }

            &:before {
                content: '|';
                margin-left: rem(8);
                margin-right: rem(10);
            }

            a {
                position: relative;
                display: inline-block;
                color: $colorFont;
                letter-spacing: rem(1);

                @include links($colorFont, $colorFont, 1);
            }
        }
    }

    a {
        display: inline-block;
        float: right;

        @include bp-lt($mobileL) {
            float: none;
        }
    }
}
