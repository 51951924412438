// ========================================= //
// ui
// ========================================= //

::-moz-selection { color: $selectionColor;  background: $selectionBackground; }
::selection      { color: $selectionColor;  background: $selectionBackground; }

* {
    outline: none;
}

.o-table {
    display: table;
    width: 100%;
    height: 100%;
}

[class*='o-table__cell'] {
    display: table-cell;

    &[class*='--valignMiddle'] {
        vertical-align: middle;

        &[class*='--tabletDown'] {
            vertical-align: top;

            @include bp-lt($tablet) {
                vertical-align: middle;
            }
        }
    }

    &[class*='--valignBottom'] {
        vertical-align: bottom;
    }
}

html {
    font-size: 16px!important;
    @include bp-lt($large) { font-size: 15px!important; }
    @include bp-lt($desktop) { font-size: 14px!important; }
    @include bp-lt($small) { font-size: 13px!important; }
}

body {
    width: 100%;

    &.is-fixed {
        position: fixed;
        overflow: hidden;
    }
}

// over write foundation row
.row {
    max-width: $layoutWidth;

    &.row--full {
        max-width: 100%;
    }

    .row {
        margin: 0 -$gutter;
    }

    [class*='column'] {
        padding-left: $gutter;
        padding-right: $gutter;
    }
}

