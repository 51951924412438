// ========================================= //
// Magento/ block-addresses-default
// ========================================= //

.block-addresses-default {

    .block-content {

        margin: $spacer * -1;

        .box {
            padding: $spacer;
            border-style: solid;
            border-color: red;
            border-width: 0 0 1px 0;
        }

    }


    // M E D I A   Q U E R I E S
    @include bp-gt(768px)
    {

        .block-content {
            display: flex;

            .box {
                flex-basis: 0;
                flex-grow: 1;
                border-width: 0 1px 0 0;
            }

            .box + .box {
                margin-top: 0 !important;
            }
        }
    };

    @include bp-gt(1024px)
    {

    };

    @include bp-gt(1440px)
    {

    };

}
