// ========================================= //
// Magento/ modal
// ========================================= //

// Css vars and extends from src/assets/scss/modules/_modals.scss

$modal-MQL: 'sm';
$modal-backdrop-bg-color: $corail;
$modal-backdrop-opacity: .8;
$modal-bg-color: $white;
$modal-close-color: $white;

body.checkout-index-index._has-modal {
    overflow: hidden
}

body.checkout-index-index > .modals-wrapper {

    .modals-overlay {
        background-color: rgba($modal-backdrop-bg-color,$modal-backdrop-opacity);
        position: fixed;
        z-index: $zindex--offcanvas;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        pointer-events: none;
    }

    .modal-slide,
    .modal-popup {
        pointer-events: none;
        position: fixed;
        z-index: $zindex--offcanvas;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        min-width: 0;
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s .3s, opacity .3s ease;

        &._inner-scroll {
            overflow-y: scroll;
        }

        &._show {
            visibility: visible;
            opacity: 1;
            transition: opacity .3s ease;
            pointer-events: auto;
        }
    }

    .modal-inner-wrap {

        background: $modal-bg-color;
        margin: $spacer auto;
        max-width: rem(790);
        width: calc(100% - #{$spacer * 2});
        left: inherit;

        #shipping-new-address-form {
            padding-left: 0;
            padding-right: 0;
        }

        .action-close {
            position: absolute;
            top: 0;
            right: 0;
            appearance: none;
            touch-action: manipulation;
            border: none;
            background: transparent;

            margin: 0;
            padding: 0 rem(20);
            width: auto;
            height: 100%;
            line-height: 1;
            text-decoration: none;
            text-align: center;
            opacity: 0.75;

            font-family: Arial, Baskerville, monospace;
            color: $white;
            cursor: pointer;

            &::before {
                content: '×';
                font-size: 20px;
                line-height: 1;
                color: $white;
                transition: $ease;
            }

            span {
                @include sr-only;
            }

            &:hover, &:focus {
                opacity: 1;
            }
        }

        .modal-header {
            background-color: $purple--dark;
            color: $white;
            padding: rem(20);
            position: relative;
            margin-bottom: 40px;

            .modal-title {
                margin-bottom: 0;
                color: inherit;
                text-align: center;
                border: none;
                padding: 0;
                text-transform: uppercase;

                font-family: $fontAlt;
                font-weight: 300;
                font-size: rem(24);
                text-transform: uppercase;
                text-align: center;
                letter-spacing: rem(8);


                &::after {
                    content: none;
                }
            }
        }

        .modal-content {
            padding: 0 2rem;

            fieldset {
                margin-bottom: $spacer;
            }
        }

        .modal-footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding: rem(25) 20px;
            margin-top: 30px;

            @include bp-lt($small){
                flex-flow: column wrap;

                button.action.primary, button {
                    margin: 10px 0;
                }
            }

            button.primary {
                margin: 0;
                display: inline-block;
                height: rem(57);
                width: auto;
                min-width: rem(200);
                padding-left: rem(30);
                padding-right: rem(30);
                border-top: none;
                border-left: none;
                border-right: none;
                border-bottom: solid rem(2);
                text-transform: uppercase;
                text-align: center;
                font-family: $maven;
                font-size: rem(14);
                font-weight: 700;
                letter-spacing: rem(1);
                transition: $ease;
                background: $corail;
                color: $white;
                border-bottom-color: $corail--dark;

                &:hover {
                    background: $mainBranding;
                    color: $white;
                    border-bottom-color: $mainBranding--darken--02;
                }
            }

            button.secondary {
                margin: 0;
                display: inline-block;
                height: rem(57);
                width: auto;
                min-width: rem(200);
                padding-left: rem(30);
                padding-right: rem(30);
                border-top: none;
                border-left: none;
                border-right: none;
                border-bottom: solid rem(2);
                text-transform: uppercase;
                text-align: center;
                font-family: $maven;
                font-size: rem(14);
                font-weight: 700;
                letter-spacing: rem(1);
                transition: $ease;
                background: $white;
                color: $colorFont;
                border-bottom-color: $grey--04;
                text-decoration: none;

                &:hover {
                    background: $mainBranding;
                    color: $white;
                    border-bottom-color: $mainBranding--darken--02;
                }
            }
        }

    }



}
