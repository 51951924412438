.customer-account-logoutsuccess {
    #maincontent {
        padding-bottom: rem(70);
        padding-top: rem(70);
        background-color: #ce5959;
        background-image: url(#{$img}c-bkg__illus--item.png);
        background-repeat: no-repeat;
        background-size: auto 100%;
    }

    .customer_account_logoutSuccess,
    .container-fluid {
        margin: 0 auto;
        width: 100%;
        max-width: 78.5em;
    }

    .wrapper p {
        font-family: $maven;
        font-size: rem(15);
        line-height: rem(28);
        color: $white;
        letter-spacing: rem(0.25);
        margin: 0;
        text-align: center;

        @include bp-lt($small) {
            font-size: rem(14);
            line-height: rem(26);
        }
    }

    .page-title {
        position: relative;
        font-family: $oswald;
        text-transform: uppercase;
        color: $white;
        text-align: center;
        letter-spacing: rem(4);
        font-size: rem(30);
        margin: 0;

        padding-bottom: rem(20);
        margin-bottom: rem(35);

        @include bp-lt($medium) { font-size: rem(28); }
        @include bp-lt($small) { font-size: rem(26); }
        @include bp-lt($mobileL) { font-size: rem(22); }

        @include bp-lt($mobileM) {
            padding-left: rem(24);
            padding-right: rem(24);
        }

        &:before {
            content: '';
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translateX(-50%);
            display: block;
            width: rem(31);
            height: rem(2);
            background: $white;
        }
    }

}