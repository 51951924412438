// ========================================= //
// wysiwyg
// ========================================= //
$marginWysiwyg: 24;

.c-wysiwyg {
    @import "_wysiwygContent";
}

.c-table__wrapper {
    width: 100%;
    overflow-y: auto;
    _overflow: auto;
    margin: 0 0 rem($marginWysiwyg);

    &::-webkit-scrollbar
    {
        -webkit-appearance: none;
        width: rem(14);
        height: rem(14);
    }

    &::-webkit-scrollbar-thumb
    {
        border-radius: rem(8);
        border: rem(3) solid $white;
        background-color: $mainBranding;
    }

    table {
        margin-bottom: 0;
        min-width: 500px;
    }

    table,
    table tr {
        height: auto!important;
    }
}


// .cms-page-view .c-page__content .c-wysiwyg {
//     display: flex;
//     flex-flow: row wrap;
//     align-items: flex-start;
//     justify-content: flex-start;

//     > div {
//         float: none !important;
//     }
// }

