.o-section--gammes {
    margin-bottom: 119px;
    padding-top: 20px;

    @include bp-lt($medium){
        margin-bottom: 50px;
    }

    @include bp-lt($small){
        margin-bottom: 0;
        padding-top: 0;
    }

    .grid {
        display: grid;
        grid-gap: 20px;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: 1fr 1fr;
        width: 100%;

        @include bp-lt($tablet){
            grid-template-columns: repeat(2, 1fr);
        }

        
        @include bp-lt($mobileXl){
            grid-template-columns: repeat(2, 1fr);
        }

        .c-item--large {
            grid-column: 1 / 3;

            @include bp-lt($mobileXl){
                grid-column: auto;
            }
        }

        .c-item--tall {
            grid-row-end: span 2;

            @include bp-lt($mobileXl){
                grid-row-end: auto;
            }
        }
    }

    .c-item {
        overflow: hidden;
        position: relative;
        display: block;

        @include bp-lt($mobileXl){
            height: 200px;

            img {
                object-fit: cover;
            }

            &:not(:last-child){
                margin-bottom: 20px;
            }
        }


        img {
            width: 100%;
            height: 100%;
        }

        &:hover .c-wrapper__text {
            background-color: rgba($corail , 1);
        }
    }


    .c-item .c-wrapper__text {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        background-color: rgba($corail , 0.85);
        padding: 32px 10px 24px;
        transition: $ease;

        @include bp-lt($medium){
            padding: 20px 10px;
        }

        @include bp-lt($small){
            z-index: 70;
            padding: 10px;
        }

        &::before {
            content:'';
            width: 46px;
            height: 2px;
            background-color: $white;
            display: inline-block;
            vertical-align: top;
            position: relative;
            top: 6px;
            margin-right: 14px;

            @include bp-lt($medium){
                top: 4px;
            }

            @include bp-lt($small){
                top: 4px;
            }
        }

        span {
            font-size: rem(24);
            font-family: $oswald;
            color: $white;
            text-transform: uppercase;
            line-height: 1.25;
            letter-spacing: rem(1);
            position: relative;

            @include bp-lt($medium){
                font-size: rem(20);
            }

            @include bp-lt($small){
                font-size: 16px;
            }
        }

        span, &::before {
            margin-left: -10px;
        }
    }

    .c-item--large .c-wrapper__text {
        max-width: calc(50% - 10px);

        @include bp-lt($small){
            max-width: 100%;
        }
    }
}