// ========================================= //
// nav
// ========================================= //

$navAsideH : $navH;
$navFont : $maven;
$navColor : $colorFont;
$menuColor : rgba($mainBranding, .95);

.o-nav {
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    .c-nav__level0 {
        display: block;
        text-align: center;
        width: 100%;
        height: rem($navH);
        @include clearfix;

        // hotfix whitespace
        letter-spacing: -0.31em;
        text-rendering: optimizespeed;
        font-family: FreeSans, Arimo, "Droid Sans", Helvetica, Arial, sans-serif;

        > li {
            // position: relative;
            display: inline-block;
            width: auto;

            // hotfix whitespace
            letter-spacing: normal;
            word-spacing: normal;
            text-rendering: auto;

            > a {
                position: relative;
                z-index: $zindex--hover;
                display: block;
                height: rem($navH);
                width: 100%;
                padding-left: rem(25);
                padding-right: rem(25);
                text-align: center;
                color: $navColor;
                font-family: $navFont;
                font-size: rem(14);
                font-weight: 700;
                letter-spacing: rem(1);
                text-transform: uppercase;
                transition: $ease;
                letter-spacing: rem(.5);
                background: transparent;

                @include bp-lt($large) {
                    font-size: rem(13);
                    padding-left: rem(25);
                    padding-right: rem(25);
                }

                @include bp-lt($bp1500) {
                    padding-left: rem(20);
                    padding-right: rem(20);
                }

                @include bp-lt($medium) {
                    padding-left: rem(15);
                    padding-right: rem(15);
                }

                &.c-link__custom {
                    font-family: $damion;
                    text-transform: capitalize;
                    font-size: rem(26);
                    font-weight: 400;
                    background: $corail;
                    color: $white;
                }

                &.is-active {
                    background: $subBranding;
                    color: $white;
                }
            }
        }
    }

    .c-nav__megaMenu {
        display: none;
        position: absolute;
        top: rem($navH);
        left: 0;
        z-index: $zindex--content;
        width: 100%;
        background: $menuColor;

        @include bp-lt($medium) {
            display: block;
            position: static;
        }

        &.is-visible {
            display: block;
        }
    }

    .c-nav__level1 {
        position: relative;
        max-width: $layoutWidth;
        margin-left: auto;
        margin-right: auto;
        @include clearfix;

        &.is-visible {
            display: block;
        }

        &.c-list__style{
            padding: rem(20) 0;

            @include bp-lt($desktop){
                padding: rem(24) 0 0;
            }

            @include bp-lt($medium){
                padding:0;
            }

            @include bp-gt($medium) {
                display: grid;
                grid-template-columns: repeat(8, 1fr);
                gap: 10px;

                > li:nth-child(2) {
                    grid-column-start: 1;
                }
            }

        }

        > li {
            display: block;
            width: 25%;
            float: left;
            padding-left: rem(20);
            padding-right: rem(20);
            margin-bottom: rem(14);
            text-align: left;

            @include bp-lt($medium) {
                width: 100%;
                padding-left: 0;
                padding-right: 0;
                margin-bottom: 0;
                float: none;
            }

            &.c-item__thumb {
                display: block;
                position: absolute;
                right: 0;
                height: 100%;
                padding-left: rem(24);
                padding-top: rem(24);
                padding-bottom: rem(24);
                border-left: solid rem(1) $mainBranding--lighten--01;

                @include bp-lt($medium) {
                    position: static;
                    height: auto;
                    padding: rem(24);
                }
            }

            &.c-item__wrapper {
                position: static;
                padding: 0;
                width: auto;
                height: 100%;
                display: block;

                &:last-of-type{
                    padding-right: 0;
                }

                &:first-child{
                    @include bp-lt($desktop){
                        padding-left: 100px;
                    }
                }

                @include bp-lt($medium){
                    height: auto;
                    padding: 0;
                }

                a{
                    display: block;

                    .c-span__nav {
                        width: 100%;
                        display: block;
                        text-align: center;
                        color: $white;
                        text-transform: uppercase;
                        font-family: $maven;
                        font-weight: 700;
                        font-size: rem(15);
                        letter-spacing: 1px;
                        padding: rem(15) 0;
                        transition: $ease;
                        @include bp-lt($medium){
                            padding: rem(12) 0;
                            text-align: left;
                        }
                    }

                    img{
                        @include bp-lt($medium){
                            display: none;
                        }
                    }
                }


                &:hover {
                    .c-span__nav{
                        color: $taupe--light;

                        @include bp-lt($medium){
                            color: $subBranding;
                        }
                    }
                }
            }

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    .c-nav__level2--label {
        padding-top: rem(48);
        padding-bottom: rem(24);

        @include bp-lt($medium) {
            padding-top: 0;
            padding-bottom: 0;
        }

        a {
            display: inline-block;
            position: relative;
            font-family: $maven;
            font-weight: 700;
            letter-spacing: rem(1);
            color: $white;
            text-transform: uppercase;
            transition: $ease;

            @include links($colorLink, $colorLink, 1);

            &:after {
                @include bp-lt($medium) {
                    display: none;
                }
            }

            @include bp-lt($medium) {
                display: block;
                width: 100%;
                min-height: rem(45);
                height: auto;
                padding-top: rem(10);
                padding-bottom: rem(10);
            }
        }
    }

    .c-nav__level2--label + .c-nav__level2 {
        @include bp-lt($medium) {
            border-bottom: solid rem(1) rgba($white, .6);
        }
    }

    .c-nav__level2 {
        padding-top: rem(48);
        padding-bottom: rem(24);

        @include bp-lt($medium) {
            padding-top: 0;
            padding-bottom: 0;
        }

        a {
            position: relative;
            display: inline-block;
            font-family: $maven;
            font-size: rem(14);
            margin-left: rem(25);
            margin-bottom: rem(5);
            letter-spacing: rem(1);
            color: $white;
            transition: $ease;

            @include bp-lt($medium) {
                display: block;
                width: 100%;
                margin: 0;
                padding-top: rem(10);
            }

            @include links($white, $white, 1);

            &:after {
                @include bp-lt($medium) {
                    display: none;
                }
            }

            &:before {
                position: absolute;
                top: rem(3);
                left: rem(-25);
                content: '\EA03';
                font-size: rem(12);
                font-family: $iconFont;
                color: $subBranding;
            }
        }
    }

    .c-nav__level2--label + .c-nav__level2 {
        padding-top: 0;
    }

    .c-nav__baseline {
        clear: both;
        width: 100%;
        height: rem(48);
        border-top: solid rem(1) $mainBranding--lighten--01;
        border-bottom: solid rem(1) $mainBranding--lighten--01;
        background: $mainBranding--darken--01;

        &, p {
            font-family: $maven;
            font-weight: 700;
            font-size: rem(16);
            color: $white;
            letter-spacing: rem(1);
            text-transform: uppercase;

            span {
                color: $subBranding;
            }
        }

        @include bp-lt($medium) {
            display: none;
        }

        p {
            margin: 0;
        }

        span {
            color: $subBranding;
        }
    }
}

.o-header .o-nav .c-nav__level2 li.c-nav__hide {
    @include bp-lt($medium) {
        display: none !important;
    }
}

.c-nav__icon {
    float: left;
    width: rem($navH);
    height: rem($navH);
    cursor: pointer;
}

.no-touchevents {
    .o-nav .c-nav__level0 > li:hover {
        @include bp-gt(1024px) {
            & > a {
                background: $subBranding;
                color: $white;
            }

            .c-nav__megaMenu { display: block; }
        }
    }
}

.touchevents {
    .o-nav .c-nav__level0 > li.over {
        @include bp-gt(1024px) {
            & > a {
                background: $subBranding;
                color: $white;
            }

            .c-nav__megaMenu { display: block; }
        }
    }
}
