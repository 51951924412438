// ========================================= //
// search
// ========================================= //
$inputSide : $navH;

.c-search {
    position: relative;
    height: rem($navH);
    background: $grey--05;

    .c-input__holder {
        position: static;
        border: none;
        margin-bottom: 0;
        @include clearfix;
    }

    .c-search__submit {
        width: rem($inputSide);
        height: rem($inputSide);
        float: left;
        border: none;
        font-size: 0;
        background: url(#{$img}c-icon__search.svg) no-repeat 50% 50% transparent;

        @include bp-lt($small){
            background-size: 15px auto;
            width: 30px;
        }
    }

    .c-search__input {
        width: calc(100% - #{$inputSide}px);
        height: rem($inputSide);
        float: right;
        border: none;
        box-shadow: none;
        background: transparent;
        font-family: $maven;
        font-size: rem(15);
        margin-bottom: 0;

        @include placeholder-color($grey--04);

        @include bp-lt($small){
            width: calc(100% - 30px);
        }
    }
}

.search-autocomplete {
    border-left: $border--grey--02;
    border-right: $border--grey--02;
    border-bottom: $border--grey--02;
    background: $grey--05;
    width: 100% !important;
    left: 0 !important;
    top: rem($inputSide);
    z-index: 42;

    ul {
        list-style: none;
        margin: 0;

        li {
            padding: rem(10);
            display: block;
            font-family: $maven;
            color: $colorFont;
            font-size: rem(15);
            background: transparent;
            transition: $ease;

            &:last-of-type {
                margin-bottom: 0;
            }

            .amount {
                margin-right: rem(7);
                color: $subBranding;
                font-weight: 700;
                transition: $ease;
            }

            &:hover {
                background: $subBranding;
                color: $white;

                .amount {
                    color: $white;
                }
            }
        }
    }
}

#layer-product-list .search.results {
    .c-category__filter__title {
        margin-bottom: 0;
    }

    .c-category__filter {
        padding-top: rem(48);
        padding-bottom: rem(48);

        @include bp-lt($small){
            padding-top: rem(24);
            padding-bottom: rem(24);
        }
    }

    .row.c-grid__product.products.wrapper.grid.products-grid {
        @include bp-lt(1200px){
            padding-right: 16px;
            padding-left: 16px;
        }

        @include bp-lt(1024px){
            padding-right: 8px;
            padding-left: 8px;
        }

        @include bp-lt($tablet) {
            padding-right: 8px;
            padding-left: 8px;
        }

        @include bp-lt($mobileXl) {
            padding-right: 8px;
            padding-left: 8px;
        }
    }
}

.no-results.c-page__content{
    padding: rem(48) rem(44) rem(48);

    p {
        margin: 0;
        text-align: center;
    }
}

.catalogsearch-result-index #layered-filter-block-container {
    display: none !important;
}