// ========================================= //
// Magento/ opc-summary-wrapper
// ========================================= //
$opc-summary-wrapper-MQL: 1200px;
$opc-summary-wrapper-bg: $white;
$opc-summary-wrapper-spacing-h: $spacer * 0.8;
$opc-summary-wrapper-spacing-v: $spacer;

.opc-summary-wrapper {

    .opc-block-summary {
        padding: 0;
        background: #fff;
        border: 1px solid $grey--02;

        @media (min-width: 768px) and (max-width: 1024px) {
            margin-top: rem(53);
        }

        .block.items-in-cart {
            margin: 0;
        }

        .product-image-container .product-image-wrapper {
            border: 1px solid $grey--02;
        }
    }

    > .opc-block-summary {
        border: 1px solid $grey--02;
        background: $white;
    }

    .opc-block-summary > span {
        padding: rem(10) rem(20);
        margin: 0;
        background: $purple--dark;
        font-size: rem(16);
        line-height: (28/16);
        letter-spacing: em(1.14, 16);
        font-weight: 400;
        text-transform: uppercase;
        color: $white;
        text-align: center;
    }

    .opc-block-summary .items-in-cart>.title {
        padding: rem(18) rem(22);

        &::before {
            content:"\EA24";
            top: 50%;
            right: rem(27);
            color: $corail;
            width: auto;
            height: auto;
        }

        &::after {
            display: none;
        }

        span, strong {
            font-family: $maven;
            font-size: rem(15);
            color: $black;
            letter-spacing: 0.45px;
            line-height: 28px;
        }
    }

    [class^="opc-block-"] {
        position: relative;
    }

    [class^="opc-block-"] + [class^="opc-block-"] {
        border-top: 1px solid #D2E3E9;
    }

    .title {
        display: block;
        font-family: $maven;
        font-size: rem(21px);
        font-weight: $font-weight-base;
        letter-spacing: em(1.17px,21px);

        &[aria-expanded] {
            position: relative;

            &::before {
                position: absolute;
                right: -0.45em;
                top: 0;
                width: 1.6em;
                height: 1.6em;
                display: flex;
                align-items: center;
                justify-content: center;

                content: "\EA01";
                color: $red;
                font-family: "iconfont";
                font-style: normal;
                font-weight: normal;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;

                transition: transform .3s ease;
                transform-origin: center;
            }
        }

        &[aria-expanded="false"] {
            &::before {
                transform: translateY(-50%) rotate(180deg);
            }
        }

        &[aria-expanded="true"] {
            &::before {
                transform: translateY(-50%);
            }
        }
    }

    .table-totals {

        text-align: right;

        .table-caption {
            display: none;
        }

        tr {

            th, td {
                width: 50%;
                white-space: normal;

                span {
                    font-size: 1em;
                    font-family: inherit;
                    text-align: inherit;
                }
            }

            th + td {
                padding-left: rem(10px);
            }

            th {
                font-weight: $font-weight-base;
                text-align: right;
            }

            &.grand.totals.excl,
            &.grand.totals.incl {
                background: no-repeat linear-gradient(red, red) right 0px top rem(10px);
                background-size: 66px 1px;

                th, td {
                    padding: rem(20px) 0 0 0;
                }
            }
        }

    }

    .items-in-cart .title {
        font-family: $maven;
        font-weight: $font-weight-base;
        font-size: rem(16px);
        letter-spacing: (0.89,16);
        text-align: left;

        strong {
            font-weight: inherit;
        }
    }

    .content .minicart-items {
        $root: &;

        padding-left: 0;
        list-style: none;
        margin: $opc-summary-wrapper-spacing-v 0 0 0;
        border-top: 1px solid $grey--02;
        padding-top: $opc-summary-wrapper-spacing-v;
        padding-left: $opc-summary-wrapper-spacing-h;
        padding-right: $opc-summary-wrapper-spacing-h;
        margin-left: $opc-summary-wrapper-spacing-h * -1;
        margin-right: $opc-summary-wrapper-spacing-h * -1;

        > li {

        }

        > li + li {
            margin-top: $spacer * 0.5;
        }
    }

    .shipping-information {

        .shipping-information-title {
            font-family: $maven;
            font-size: rem(16px);
            letter-spacing: em(0.89,16);
            font-weight: $font-weight-base;
            margin-bottom: rem(9px);

            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .action {
            appearance: none;
            background: transparent;
            border: none;
            padding: 0;
            color: $white;
            font-family: $maven;
            font-size: 1rem;

            transition: color .3s ease;

            &::before {
                font-family: "iconfont";
                font-style: normal;
                font-weight: normal;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                font-size: rem(26);
            }

            span {
                @include sr-only;
            }

            &:hover {
                cursor: pointer;
                color: $red;
                transition-duration: .1s;
            }
        }

        .action-edit::before {
            content: "\EA16";
        }

    }

    .action-close {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;

        // @extend .c-button--transparent;
        // @extend .c-button--wa;
        height: 40px;

        span {
            @include sr-only;
        }

        &::before {
            content: "\EA1E";
            font-family: "iconfont";
            font-style: normal;
            font-weight: normal;
            font-size: rem(24);
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }
    }

    .minicart-items .minicart-items-wrapper {
        padding: 0;
        margin: 0;
        max-height: none;

        .product {
            padding: rem(12);
            padding-right: rem(12);
        }

        .product-image-container {
            width: rem(48) !important;
            height: rem(48) !important;

            img {
                display: block;
            }
        }

        .product-item-details {
            padding-left: rem(64);
        }

        .minicart-items {
            padding: 0;
            margin: 0;
            border: none;
        }

        .product-item {
            margin: 0;
            padding: 0;
        }

        .product .product-item-name-block {
            padding: 0;
            padding-right: 10px;
            width: 100%;
            max-width: rem(200px);

            @include bp-lt($tablet){
                max-width: calc(100% - #{rem(75)});
            }
        }

        .product .subtotal {
            max-width: calc(100% - 200px);
            width: 100%;

            @include bp-lt($tablet){
                max-width: rem(75);
            }

            .cart-price .price {
                font-family: $maven;
                font-size: rem(14);
                color: $black;
                letter-spacing: 1px;
                line-height: (28/14);
                font-weight: 400;
            }
        }

        .product-item-name-block strong {
            font-family: $maven;
            font-size: rem(12);
            color: $black;
            letter-spacing: 0.86px;
            line-height: (14/12);
            font-weight: 400;
            margin: 0;
            margin-top: rem(5);
        }

        .product-item .product .product-item-inner {
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            justify-content: flex-start;
            margin: 0;
        }

        .product-item-name-block .details-qty {
            margin-top: 0;
            line-height: 1;

            .value,
            .label {
                background:none;
                font-family: $maven;
                font-size: rem(12);
                color: $black;
                letter-spacing: 0.36px;
                line-height: (19/12);
                font-weight: 400;

                margin: 0;
                padding: 0;
            }
        }
    }

    // M E D I A   Q U E R I E S
    @include bp-lt(768px)
    {
        position: fixed;
        z-index: $zindex--offcanvas !important;
        top: 0;
        bottom: 0;
        right: -100%;

        background-color: $opc-summary-wrapper-bg;
        padding: 0;
        overflow-y: scroll;

        transition: right .3s ease, box-shadow .3s ease;

        &._show {
            right: 0;
            box-shadow: 0 3px 4px 0 rgba(0,0,0,0.14), 0 3px 3px -2px rgba(0,0,0,0.12), 0 1px 8px 0 rgba(0,0,0,0.20);
        }

    };

    @include bp-gt(768px)
    {
        z-index: 0 !important;

        [class^="opc-block-"] + [class^="opc-block-"] {
            margin-top: $opc-summary-wrapper-spacing-v;
            border-top: none;
        }

        .title {
            text-align: center;
        }

        .action-close {
            display: none;
        }

    };

}
