// ========================================= //
// imageGrid
// ========================================= //

.c-grid__image {
    @include clearfix;
    max-width: rem(1600);
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    @include bp-lt($mobileL) {
        width: 100%;
    }

    .c-grid__item--2 {
        width: 63.1%;

        @include bp-lt($tablet) {
            width: 100%;
        }
    }

    .c-grid__item--1 {
        width: 36.9%;

        @include bp-lt($tablet) {
            width: 50%;
        }
    }

    .c-grid__item--1-3 {
        width: percentage(1/3);

        @include bp-lt($tablet) {
            width: 50%;
        }
    }

    [class*='c-grid__item'] {
        float: left;
        display: block;
        position: relative;

        &:nth-of-type(1) [class*='c-grid__image__title'] {
            bottom: rem(74);
            left: 0;

            @include bp-lt($small) {
                bottom: 0;
            }
        }

        &:nth-of-type(2) [class*='c-grid__image__title'] {
            top: 0;
            left: 0;
        }

        &:nth-of-type(3) [class*='c-grid__image__title'] {
            top: 50%;
            transform: translateY(-50%);
            right: 0;
        }

        &:nth-of-type(4) [class*='c-grid__image__title'] {
            bottom: 0;
            left: 0;
        }

        &:nth-of-type(5) [class*='c-grid__image__title'] {
            top: 0;
            right: 0;
        }

        .c-grid__image__picture {
            overflow: hidden;
            background: $mainBranding;

            h2 {
                margin: 0;
            }

            .c-hideForTablet {
                display: block;

                @include bp-lt($tablet) {
                    display: none;
                }
            }

            .c-showForTablet {
                display: none;

                @include bp-lt($tablet) {
                    display: block;
                }
            }

            img {
                transform: scale(1);
                transition: $ease;
            }
        }

        &:hover {
            .c-grid__image__picture img {
                transform: scale(1.1);
            }
        }
    }
}

[class*='c-grid__image__title'] {
    position: absolute;
    z-index: $zindex--content;
    width: auto;
    height: rem(140);
    color: $colorFont;

    @include bp-lt($desktop) {
        height: rem(120);
    }

    .c-cat {
        position: relative;
        font-family: $maven;
        font-weight: 700;
        font-size: rem(14);
        letter-spacing: rem(1);
        text-transform: uppercase;

        @include bp-lt($desktop) {
            font-size: rem(12);
        }

        &:before {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            display: block;
            width: rem(40);
            height: rem(2);
            background: $corail;

            @include bp-lt($desktop) {
                width: rem(20);
            }
        }
    }

    .c-title {
        font-family: $oswald;
        font-size: rem(36);
        letter-spacing: rem(2);
        text-transform: uppercase;

        @include bp-lt($desktop) {
            font-size: rem(26);
        }

        @include bp-lt($small) {
            font-size: rem(18);
        }
    }

    &[class*='--corail'] {
        background: rgba($corail, .9);
        color: $white;

        .c-cat:before {
            background: $grey--04;
        }
    }

    &[class*='--textLeft'] {
        padding-right: rem(40);
        text-align: left;

        .c-cat {
            padding-left: rem(56);

            @include bp-lt($desktop) { padding-left: rem(36); }
            @include bp-lt($small) { padding-left: rem(26); }

            &:before {
                left: 0;
            }
        }

        .c-title {
            padding-left: rem(78);

            @include bp-lt($desktop) { padding-left: rem(58); }
            @include bp-lt($small) { padding-left: rem(38); }
        }
    }

    &[class*='--textRight'] {
        padding-left: rem(40);
        text-align: right;

        .c-cat {
            padding-right: rem(56);

            @include bp-lt($desktop) { padding-right: rem(36); }
            @include bp-lt($small) { padding-right: rem(26); }

            &:before {
                right: 0;
            }
        }

        .c-title {
            padding-right: rem(78);

            @include bp-lt($desktop) { padding-right: rem(58); }
            @include bp-lt($small) { padding-right: rem(38); }
        }
    }
}

.c-grid__image__picture {
    position: relative;
    z-index: $zindex--background;
}
