// ========================================= //
// filter
// ========================================= //

.c-category__filter {
    padding-top: rem(48);
    padding-bottom: rem(24);
    background: $white;
    border-bottom: $border--grey--02;

    @include bp-lt($tablet) {
        padding-top: rem(24);
    }
}

.c-category__filter__title {
    margin-bottom: rem(24);
    font-family: $maven;
    font-size: rem(16);
    font-weight: 700;
    color: $colorFont;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: rem(1);


}

.c-category__filter__toggle {
    display: none;

    .c-filter__hide {
        display: none;
    }
}

.c-category__filter__row--inner {
    max-width: $layoutWidth;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    .filter-options {
        display: flex;
        gap: 10px;
        width: 100%;

        @media screen and (max-width: 1200px){
            padding: 0 16px;
        }

        @media screen and (max-width: 1024px){
            padding: 0 7px;
        }

        @include bp-lt($tablet) {
            display: grid;
            grid-template-columns: repeat(8, 1fr);
            column-gap: 20px;
            grid-template-columns: repeat(4, 1fr);
            padding: 0 16px;
        }

        @include bp-lt($mobileXl) {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

.c-category__filter__row {
    width: 100%;
    transition: $ease;
    background: $white;
    box-shadow: 0 rem(2) rem(4) rgba($black, 0);
    @include clearfix;

    &.is-stuck {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 60;
        padding-top: rem(20);
        box-shadow: 0 rem(2) rem(4) rgba($black, .25);

        @include bp-lt($tablet) {
            padding-top: 0;
        }

        .c-category__filter__holder {
            margin-bottom: rem(20);
        }

        select {
            height: rem(36);
        }

        .c-category__filter__toggle {
            height: rem(40);
            padding-left: rem(10);
            padding-right: rem(10);
            font-size: rem(18);
            font-family: $maven;
            color: $colorFont;
            cursor: pointer;

            @include bp-lt($tablet) {
                display: block;
            }

            [class*='c-fonticon'] {
                position: relative;
                top: rem(2);
                margin-right: rem(10);
            }
        }

        .c-category__filter__row--inner {
            @include bp-lt($tablet) {
                display: none;

                &.is-visible {
                    display: block;
                }
            }
        }
    }
}

.o-nav--sticked .c-category__filter__row.is-stuck {
    top: rem($navH--isStuck);
}

.c-category__filter__holder {
    width: 100%;
    float: left;
    margin-bottom: rem(24);

    select {
        margin-bottom: 0;

        @include bp-lt($medium) {
            padding-left: rem(10);
        }
    }
}

.c-category__content__filter {
    text-align: right;
    padding-top: rem(40);
    padding-bottom: rem(30);
    @include clearfix;

    .columns.toolbar.toolbar-products {
        padding-right: 0;
        padding-left: 0;

        @media screen and (max-width: 1200px){
            padding-right: 16px;
            padding-left: 16px;
        }

        @media screen and (max-width: 1024px){
            padding-right: 8px;
            padding-left: 8px;
        }

        @include bp-lt($tablet) {
            padding-right: 16px;
            padding-left: 16px;
        }

        @include bp-lt($mobileXl) {
            padding-right: 8px;
            padding-left: 8px;
        }
    }

    .c-item {
        display: inline-block;
        text-align: left;
        margin-left: rem(30);
        margin-bottom: rem(10);
        @include clearfix;

        @include bp-lt($mobileXl) {
            display: block;
            width: 50%;
            float: left;
            margin-left: 0;
            margin-right: 0;
            padding-left: rem(10);
            padding-right: rem(10);
        }

        label {
            display: inline-block;
            width: rem(80);
            font-family: $maven;
            font-size: rem(15);
            color: $colorFont;
            font-weight: 700;
        }

        select {
            display: inline-block;
            width: rem(160);
            height: rem(36);
            background-color: $white;
            margin-bottom: 0;

            @include bp-lt($mobileXl) {
                width: 100%;
            }
        }
    }
}