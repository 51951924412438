// ========================================= //
// news
// ========================================= //

.c-news__list {
    @include bp-lt($layoutWidth) {
        padding-left: rem(24);
        padding-right: rem(24);
    }
}

.c-news__item {
    margin-bottom: rem(48);
    border: $border--grey--02;
    background: $white;

    .row.collapse {
        display: flex;
        flex-flow: row wrap;
    }

    &:nth-child(even) {
        .row {
            flex-flow: row-reverse wrap;
        }
    }

    .columns {
        float: none;
        height: auto;
    }

    .js-itemEqualizer {
        position: relative;
        height: 100%;
    }

    .c-news__thumb {
        position: absolute;
        top: 0; left: 0;
        width: 100%; height: 100%;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: cover;

        @include bp-lt($small) {
            position: static;
            background: none;
        }

        img {
            opacity: 0;

            @include bp-lt($small) {
                opacity: 1;
            }
        }
    }

    .c-news__content {
        padding: rem(30);
    }

    .c-news__content__title {
        font-family: $oswald;
        font-size: rem(20);
        text-transform: uppercase;
        color: $colorFont;
        letter-spacing: rem(1);
        margin-bottom: rem(5);
    }

    .c-news__content__date {
        position: relative;
        font-family: $maven;
        font-size: rem(14);
        font-weight: 700;
        color: $subBranding;
        text-transform: uppercase;
        letter-spacing: rem(.5);
        margin-bottom: rem(30);
        padding-bottom: rem(30);

        &:before {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            display: block;
            width: rem(31);
            height: rem(2);
            background: $subBranding;
        }
    }
}