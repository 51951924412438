// ========================================= //
// pageContact
// ========================================= //

.c-contact__content {
    margin-top: rem(-50);
    background: $white;
    box-shadow: rem(1) 0 rem(6) rgba($black, .08);

    @include bp-lt($desktop) {
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;
    }

    .row.collapse {
        display: flex;
        flex-flow: row wrap;
    }

    .js-itemEqualizer {
        @include bp-lt($small) {
            height: auto !important;
        }
    }

    .columns.medium-4 {
        padding: rem(48) rem(24);
        background: $subBranding;
        text-align: center;
        font-family: $maven;
        font-size: rem(15);
        color: $white;
        letter-spacing: rem(.25);

        span,
        strong {
            display: block;
            line-height: rem(25);

            &.has-margin--btm {
                margin-bottom: rem(25);
            }
        }

        a {
            display: inline-block;
            position: relative;
            color: $white;
            transition: $ease;

            @include links($white, $white, 1);
        }
    }
}
