// ========================================= //
// paginate
// ========================================= //

.c-paginate {
    padding: rem(50) 0;
    background: $white;
    border-top: $border--grey--02;

    ol {
        list-style: none;
        margin: 0;

        li {
            display: inline-block;
            margin-left: rem(5);
            margin-right: rem(5);
            vertical-align: top;
            transition: $ease;

            a {
                display: block;
                padding-left: rem(6);
                padding-right: rem(6);
                transition: $ease;
            }

            &, a {
                font-family: $maven;
                font-size: rem(16);
                color: $colorFont;
                font-weight: 700;
            }

            &:hover a {
                color: $subBranding;
            }

            &.current {
                padding-left: rem(6);
                padding-right: rem(6);
                border-bottom: solid rem(2) $subBranding;
            }
        }
    }
}

.blog-index-index .c-paginate {
    @include bp-lt($small){
        overflow: hidden;
    }
}