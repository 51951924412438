// ========================================= //
// Magento/ opc-estimated-wrapper
// ========================================= //
$opc-estimated-wrapper-MQL: 'lg';
$opc-estimated-wrapper-padding: $spacer;

#checkout .opc-estimated-wrapper {
    border: 1px solid #E5DFDF !important;
    position: relative;
    margin: 0;
    padding: 0;
    background: white;

    max-width: none !important;
    width: 100% !important;
    flex: auto !important;
    margin: 0 calc(4.33% + .3125rem) 30px;

    .estimated-block {
        padding: $opc-estimated-wrapper-padding;

        .estimated-label {
            display: block;
            font-family: $maven;
            font-size: rem(21px);
            font-weight: $font-weight-base;
            letter-spacing: em(1.17px,21px);
        }

        .estimated-price {
            font-weight: $font-weight-bold;
        }
    }

    .minicart-wrapper {
        position: absolute;
        top: 50%;
        right: $opc-estimated-wrapper-padding;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        background-color: white;
        margin-top: 0;

        button.action {

            &.showcart {
                flex-direction: row;
                padding: 10px;
                border: 1px solid #463b41;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #463b41;

                &::before, .counter {
                    color: #fff !important;
                }

                .counter.qty {
                    height: auto;
                    line-height: 1;
                    background: none;
                    margin: 0;
                    padding: 0;
                }

                .counter .counter-number {
                    text-shadow: none;
                }

                &::before {
                    content: "\EA02";
                    font-family: "iconfont";
                    font-style: normal;
                    font-weight: normal;
                    line-height: 1;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    margin-right: .5em;
                }

                &:hover {
                    background-color: white!important;

                    &::before, .counter {
                        color: #463b41 !important;
                    }
                }
            }
        }
    }

    // M E D I A   Q U E R I E S
    @include bp-gt($opc-estimated-wrapper-MQL)
    {
        display: none;
    };

}

.checkout-index-index .page-header {
    margin-bottom: 0;
}
.checkout-index-index .modal-custom.opc-sidebar.opc-summary-wrapper.custom-slide {
    // M E D I A   Q U E R I E S
    @include bp-lt(768px){
        padding-left: 0;
        padding-right: 0;

        .opc-block-summary {
            margin-bottom: 0;
        }

        .action-close {
            transition: $ease;
            margin: 0;

            &:hover {
                color: $subBranding;
            }
        }

        .action-close::before {
            content: "\EA1E";
            font-family: "iconfont";
            vertical-align: unset;
            padding: 0 10px;
        }

        .opc-sidebar {
            background: white;

            .ship-to {
                border-top: 0 !important;
            }
        }
    }
}

.checkout-index-index .modal-custom.opc-sidebar.opc-summary-wrapper.custom-slide._show + .modal-custom-overlay{
    z-index: 50 !important;
}