// ========================================= //
// blocks
// ========================================= //
.block.auto-list .c-grid__product--related .row {
    width: 100%;
}

.o-section__selection {
    padding-bottom: 20px;

    .c-grid__product {
        @include bp-lt($small) {
            margin-bottom: 0;
        }
    }

    .c-tabs .c-grid__product .row .row {
        display: flex;
        flex-flow: row wrap;
        width: 100%;

        &::before, &::after {
            display: none !important;
        }

        @media screen and (max-width: 1135px){
            margin: 0;
        }
    }

    .c-tabs .c-grid__product .row .row > .columns {
        height: auto;
        margin-bottom: 2.5rem;

        @include bp-lt($small) {
            margin-bottom: 1.25rem;
        }
    }

    .c-tabs .c-grid__product .row .row > .columns .c-grid__product__item {
        height: 100%;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: flex-start;
        margin: 0;

        .c-product__content__info--buttons,
        .c-grid__product__item--footer {
            width: 100%;
            margin-top: auto;
            margin-bottom: 0;
        }
    }
}

.catalogsearch-result-index {
    .c-grid__product.products-grid .product-items {
        display: flex;
        flex-flow: row wrap;
        width: 100%;
        margin: 0 auto;
    }

    .c-grid__product.products-grid .product-items > .columns {
        height: auto;
        margin-bottom: 2.5rem;

        @include bp-lt($small) {
            margin-bottom: 1.25rem;
        }
    }

    .c-grid__product.products-grid .product-items > .columns .c-grid__product__item {
        height: 100%;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: flex-start;
        margin: 0;

        .c-product__content__info--buttons,
        .c-grid__product__item--footer {
            width: 100%;
            margin-top: auto;
            margin-bottom: 0;
        }
    }
}

.catalog-product-view,
.catalog-category-view #layer-product-list {
    .c-grid__product.row .row {
        display: flex;
        flex-flow: row wrap;

        &::after, &::before {
            display: none !important;
        }
    }

    .c-grid__product.row .row > .columns {
        height: auto;
        margin-bottom: 2.5rem;

        @include bp-lt($small) {
            margin-bottom: 1.25rem;
        }

        &:nth-child(odd) {
            clear: both;
        }
    }

    .c-grid__product.row .row > .columns .c-grid__product__item {
        height: 100%;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: flex-start;
        margin: 0;

        .c-product__content__info--buttons,
        .c-grid__product__item--footer {
            width: 100%;
            margin-top: auto;
            margin-bottom: 0;
        }
    }
}

.page.messages {

    .messages {
        max-width: $layoutWidth;
        margin: 0 auto;
    }

    .messages > .message {
        padding: rem(20);
        border: 1px solid rgba(149, 157, 165, 0.2);
        background-color: rgba(149, 157, 165, 0.1);
        border-radius: 4px;
        margin: 10px 0 10px;
    }
}

.loading-mask .loader img + p {
    display: none;
}

.catalog-category-view #axeptio_main_button {
    @media screen and (max-width:1350px) {
        bottom: 60px !important;
    }
}

.catalog-category-view .axeptio_mount {
    @media screen and (max-width:1350px) {
        z-index: 100;
        position: relative;
    }
}

.cms-page-view #maincontent.o-page--bkgGrey {
    padding-bottom: 40px;
}