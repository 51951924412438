// ========================================= //
// Magento/ table-order-items
// ========================================= //

table.table-order-items {

    margin-bottom: 0;
    text-align: left;

    .col {
        width: auto;

        .product {
            border: none;
            text-align: inherit;
            display: inherit;
            border-radius: 0;
        }

        .product {
            border: none;
            text-align: inherit;
            display: inherit;
            border-radius: 0;
        }

        .item-options {
            display: inline;
            margin: 0;

            dt, dd {
                display: inline;
                margin: 0;
            }

            dt::after {
                content: ': ';
            }
        }

    }

    .id { }

    .date,
    .shipping,
    .total,
    .status {
        text-align: center;
    }

    .actions {

        font-size: rem(13);
        line-height: 1;

        ul {
              padding-left: 0;
  list-style: none;
            margin: 0;

            li {
                margin-bottom: 0;
                padding-left: 0;

                &::before { content: none; }
            }
        }

        .action {
            display: flex;
            align-items: center;

            color: $white;
            text-decoration: none;

            &:hover, &:focus {
                span {
                    text-decoration: underline;
                }
            }

            &::before {
                font-family: "iconfont";
                font-style: normal;
                font-weight: normal;
                line-height: 1;
                color: $red;
                width: 1.5em;
            }

            &.view::before  { content: "\EA0E"; }
            &.print::before { content: "\EA19"; }
            &.suivi::before { content: "\EA06"; }
            &.order::before { content: "\EA1A"; }
        }
    }

    // M E D I A   Q U E R I E S
    @include bp-gt(768px)
    {

    };

    @include bp-gt(1024px)
    {

    };

    @include bp-gt(1440px)
    {

    };

}
