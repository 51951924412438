// ========================================= //
// navOffCanvas
// ========================================= //

$border--offcanvas: solid rem(1) rgba($white, .1);
$navAsideH : 45;
$offCanvas--trigger : $medium;
$colorIcon : $subBranding;
$navBackgrond : $subBranding;
$navColor : $white;
$fontBody : $maven;

.o-header .o-nav {
    @include bp-lt($offCanvas--trigger) {
        position: fixed;
        top: 0;
        z-index: 105;
        width: rem(280);
        height: 100%;
        border-right: $border--offcanvas;
        background: $navBackgrond;

        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        overflow-scrolling: touch;
        -ms-overflow-style: none;
        &::-webkit-scrollbar { width: 0 !important }

        .c-nav__level0 {
            padding-left: 0;
            height: auto;

            &:before { display: none; }

            > li {
                position: relative;
                display: block;
                width: 100%;
                float: none;
                border-bottom: $border--offcanvas;

                a {
                    padding-left: rem(20);
                    padding-right: rem(20);
                    height: rem($navAsideH);
                    text-align: left;
                    color: $navColor;

                    &:hover {
                        background: $navColor;
                        color: $navBackgrond;
                    }
                }
            }
        }

        .c-nav__level1 {
            position: fixed;
            display: block;
            float: none;
            width: rem(280);
            top: 0;
            height: 100%;
            transform: inherit;
            background: $navBackgrond;
            padding-top: 0;
            border-right: $border--offcanvas;

            overflow-y: scroll;
            -webkit-overflow-scrolling: touch;
            overflow-scrolling: touch;
            -ms-overflow-style: none;
            &::-webkit-scrollbar { width: 0 !important }

            > li {
                position: relative;

                a {
                    height: rem($navAsideH);
                    color: $navColor;
                    border-bottom: $border--offcanvas;
                }
            }
        }

        .c-nav__level1 { z-index: 110; }
    }

    .c-nav__show {
        position: absolute;
        top: 0;
        right: 0;
        display: none;
        width: rem($navAsideH);
        height: rem($navAsideH);
        background: $navBackgrond;
        border-left: $border--offcanvas;
        text-align: center;
        color: $white;
        font-size: rem(20);
        cursor: pointer;
        transition: $ease;
        z-index: $zindex--hover;

        @include bp-lt($offCanvas--trigger) {
            display: block;
        }

        &:hover {
            background: $white;
            color: $navBackgrond;
        }

        [class*='c-fonticon'] {
            position: relative;
            top: rem(4);
        }
    }

    .c-close {
        position: relative;
        display: none;
        width: 100%;
        height: rem($navAsideH);
        background: $mainBranding;
        border-left: $border--offcanvas;
        text-align: center;
        color: $white;
        font-size: rem(16);
        cursor: pointer;
        transition: $ease;
        text-transform: uppercase;
        z-index: $zindex--hover;
        border-bottom: $border--offcanvas;

        @include bp-lt($offCanvas--trigger) {
            display: block;
        }

        .c-text {
            font-size: rem(14);
        }

        [class*='c-fonticon'] {
            position: absolute;
            top: 0;
            right: 0;
            width: rem($navAsideH);
            height: rem($navAsideH);
            font-size: rem(20);
            border-left: $border--offcanvas;
            text-align: center;
            background: transparent;
            transition: $ease;

            &:before {
                position: relative;
                top: rem(15);
            }
        }

        &:hover [class*='c-fonticon'] {
            background: $navBackgrond;
        }
    }

    li.c-nav__hide {
        position: relative;
        display: none !important;
        width: 100%;
        height: rem($navAsideH) !important;
        color: $white;
        border-bottom: $border--offcanvas;
        text-align: center;
        text-transform: uppercase;
        cursor: pointer;

        @include bp-lt($offCanvas--trigger) {
            display: block !important;
        }

        .c-text {
            font-size: rem(12);
            font-family: $fontBody;
        }

        [class*='c-fonticon'] {
            position: absolute;
            top: 0;
            left: 0;
            width: rem($navAsideH);
            height: rem($navAsideH);
            border-right: $border--offcanvas;
            background: transparent;
            transition: $ease;

            &:before {
                position: absolute;
                top: rem(12);
                left: rem(12);
                font-size: rem(20);
            }
        }

        &:hover {
            [class*='c-fonticon'] {
                background: $white;
                color: $mainBranding;
            }
        }
    }
}

.o-nav,
.o-nav .c-nav__level1 {
    transition: $ease;

    @include bp-lt($offCanvas--trigger) {
        left: rem(-280);
    }

    &.is-inViewport {
        @include bp-lt($offCanvas--trigger) {
            left: 0;
        }
    }
}

.c-nav__icon {
    $sideMenu: rem(30);
    position: relative;
    display: none;

    @include bp-lt($offCanvas--trigger) {
        display: block;
    }

    span {
        $strokeHeight: 2px;

        display: block;
        text-indent: -9999px;
        width: $sideMenu;
        height: $strokeHeight;
        position: relative;
        top: rem(28);
        left: rem(14);
        transform: rotate(0deg);
        transition: $ease;
        pointer-events: none;
        border-radius: rem(1);

        @include bp-lt($mobileM) {
            left: rem(12);
        }

        &:before,
        &:after {
            display: block;
            position: absolute;
            content: '';
            width: $sideMenu;
            height: $strokeHeight;
            transition: $ease;
            border-radius: rem(2);
        }

        &:before {
            top: rem(-11);
            transform: rotate(0deg);
        }

        &:after {
            bottom: rem(-11);
            transform: rotate(0deg);
        }
    }

    span, span:before, span:after {
        background: $colorIcon;
    }

    &.is-active {
        span {
            background-color: transparent;
            transform: rotate(180deg);

        &:before {
                top: 0;
                transform: rotate(45deg);
            }

            &:after {
                bottom: 0;
                transform: rotate(-45deg);
            }
        }
    }
}

.c-nav__close {
    position: fixed;
    z-index: 100;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    display: none;
    background: rgba($mainBranding, .6);
    transition: opacity .3s ease;

    &.is-active {
        display: block;
    }
}
