// ========================================= //
// pageHeader
// ========================================= //

[class*='c-page__header'] {
    padding-top: rem(40);

    &[class*='--paddingTop'] {
        padding-top: rem(110);

        @include bp-lt($medium) {
            padding-top: rem(40);
        }
    }

    &[class*='--bkgCorail'] {
        padding-bottom: rem(124);
        background-color: $corail;
        background-image: url(#{$img}c-bkg__illus--item.png);
        background-repeat: no-repeat;
        background-size: contain;

        [class*='c-section__title'] {
            color: $white;

            &:before {
                background: $subBranding--lighten--01;
            }
        }
    }

    &.has-altTitle {
        [class*='c-section__title']:nth-child(1) {
            margin-bottom: 0;
            padding-bottom: 0;

            &:before {
                display: none;
            }
        }
    }

    [class*='c-section__title'] {
        padding-bottom: rem(40);
        margin-bottom: rem(47);

        @include bp-lt($small) {
            padding-bottom: rem(20);
            margin-bottom: rem(24);
        }
    }


    &>.row {
        position: relative;
    }

    .o-share {
        position: absolute;
        top: rem(10);
        right: 0;

        @include bp-lt($desktop) {
            right: rem(24);
        }
        @include bp-lt($small) {
            right: 0;
        }
    }
}

.c-page__header--category {
    padding-top: 0;

    .row.collapse.row--full {
        display: flex;
        flex-flow: row wrap;
    }

    .columns {
        float: none;
    }

    .columns:nth-child(1) {
        position: relative;

        @include bp-lt($tablet) {
            width: 100%;
        }

        .c-background {
            position: absolute;
            top: 0;
            right: 0;
            display: block;
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: cover;

            @include bp-lt($tablet) {
                display: none;
            }
        }

        img {
            opacity: 0;

            @include bp-lt($tablet) {
                opacity: 1;
            }
        }
    }

    .columns:nth-child(2) {
        padding: rem(25) rem(40);
        background: $subBranding;

        @include bp-lt($tablet) {
            width: 100%;
        }

        [class*='c-page__header'] [class*='c-section__title'] {
            padding-bottom: rem(15);
            margin-bottom: rem(20);
        }

        .c-wysiwyg p {
            color: $white;
            margin-bottom: 0;
        }
    }

    .js-itemEqualizer {
        @include bp-lt($tablet) {
            height: auto !important;
        }
    }
}

.c-page__header--category__content {
    max-width: #{$layoutWidth / 2};
    padding: 0;

    @include bp-lt($layoutWidth) {
        max-width: 100%;
    }

    .c-wysiwyg {
        h2 {
            color: $white;
            margin-bottom: rem(10);
        }
    }
}

.c-page__header.has-share {
    h1.c-section__title {
        padding-left: rem(100);
        padding-right: rem(100);

        @include bp-lt($small) {

            padding-left: rem(30);
            padding-right: rem(30);
        }
    }

    .addthis_sharing_toolbox .tac_activate {
        @include bp-lt($small) {
            // padding-right: rem(30);
            width: 100%;
            top: rem(-52);
        }
    }
}

body{
    &[class*='cms-']{
        .c-page__header.has-share {

            .addthis_sharing_toolbox .tac_activate {
                @include bp-lt($small) {

                    top: 5px;
                }
            }
        }
    }
}