// ========================================= //
// cart
// ========================================= //

.c-table__shoppingCart {}

.c-shoppingCart {
    @include bp-lt($medium) {
        padding-left: rem(24);
        padding-right: rem(24);
    }

    .columns.large-4.medium-6,
    .columns.large-6.end.medium-6 {
        @include bp-lt($medium) {
            width: 100%;
        }
    }
}

$border: $border--grey--02;

.cart-table {
    width: 100%;
    margin-bottom: rem(38);

    thead {
        background: $white;

        @include bp-lt($small) {
            display: none;
        }

        tr th {
            height: rem(48);
            font-family: $maven;
            font-size: rem(12);
            text-transform: uppercase;
            color: $colorFont;
        }
    }

    tbody {
        tr {
            background: $white;

            @include bp-lt($tablet) {
                border-bottom: $border;

                &:last-of-type {
                    border-bottom: none;
                }
            }
        }
    }

    thead,
    tbody {
        tr th,
        tr td {
            border-bottom: $border;
        }
    }

    tr {
        @include bp-lt($tabletS) {
            position: relative;
            display: block;
            @include clearfix;
        }
    }

    .medium-hide {
        @include bp-lt($medium) {
            display: none;
        }
    }

    .c-quantity {
        display: inline-block;
    }

    .product-cart-image {
        width: rem(109);
        height: rem(109);
        padding: rem(14);

        @include bp-lt($tablet) {
            width: rem(80);
        }

        @include bp-lt($tabletS) {
            float: left;
            display: block;
            border: none;
            height: rem(80);
        }

        img {
            width: 100%;
            border: $border;
        }
    }

    .product-cart-info {
        @include bp-lt($tabletS) {
            float: left;
            width: calc(100% - 80px);
            display: block;
            border: none;
            min-height: rem(80);
            padding-right: rem(63);
            padding-left: rem(10);
            padding-top: rem(20);
            vertical-align: middle;

            @include bp-lt($mobileM) {
                padding-right: rem(50);
            }
        }

        .c-title {
            display: block;
            margin-bottom: rem(6);
            font-family: $maven;
            font-size: rem(14);
            font-weight: 700;
            color: $colorFont;
            text-transform: uppercase;
        }

        .c-info {
            font-family: $maven;
            font-size: rem(13);
            color: $colorLink;

            &:before {
                content: '\EA0C';
                position: relative;
                top: rem(-1);
                font-family: $iconFont;
                font-size: rem(5);
                margin-right: rem(5);
            }
        }
    }

    .product-cart-price,
    .product-cart-total {
        text-align: center;
        padding-left: rem(5);
        padding-right: rem(5);

        .c-priceBox .c-price,
        .c-priceBox .c-price--alt {
            white-space: nowrap;
            font-family: $maven;
            font-weight: 700;

            @include bp-lt($tablet) {
                font-size: rem(14);
            }
        }
    }

    .product-cart-total {
        @include bp-lt($tabletS) {
            float: left;
            width: 50%;
            display: block;
            height: rem(60);
            padding-right: rem(43);
            border-left: none;
            border-right: none;
            border-top: $border;
        }
    }

    .product-cart-actions {
        font-family: $maven;
        font-size: rem(24);
        font-weight:  300;
        color: $colorFont;
        text-align: center;
        width: rem(115);

        @include bp-lt($tabletS) {
            float: left;
            width: 50%;
            display: block;
            height: rem(60);
            border-left: none;
            border-top: $border;
        }
    }

    .product-cart-remove {
        text-align: center;
        background: $white;

        @include bp-lt($tabletS) {
            width: rem(43);
            height: 100%;
            position: absolute;
            right: 0;
            border-left: $border;
        }

        [class*='c-fonticon__icon'] {
            font-size: rem(14);
            color: $colorLink;
        }
    }
}

.c-price__shoppingCart {
    background: $subBranding;
    color: $white;
    margin-bottom: rem(70);

    @include bp-lt($tablet) {
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .checkout-methods-items {
        background-color: #B25252;

        .c-button--white {
            margin: 1.25rem 0 rem(30);
            font-weight: 400;

            &.disabled:focus,
            &.disabled:hover {
                background: $purple--dark;
            }
        }
    }

    [class*='c-item__price'] {
        font-family: $maven;
        margin-bottom: rem(10);
        @include clearfix;

        &:last-of-type {
            margin-bottom: 0;
        }

        .c-label {
            float: left;
            width: calc(100% - 150px);
            padding-top: rem(2);
            font-size: rem(13);
            padding-right: rem(18);
            text-align: right;

            @include bp-lt($mobileM) {
                width: calc(100% - 90px);
            }
        }

        .c-price {
            float: right;
            width: 150px;
            font-size: rem(15);

            @include bp-lt($mobileM) {
                width: 90px;
            }
        }

        &[class*='--total'] {
            font-weight: 700;
            margin-bottom: 0;

            .c-label {
                padding-top: rem(5);
                font-size: rem(20);
            }

            .c-price {
                font-size: rem(24);
            }
        }
    }
}

.c-price__shoppingCart--price,
.c-item__price--total {
    padding: rem(24);
    border-bottom: solid rem(1) rgba($grey--08, .2);
}

.c-price__shoppingCart--delivery {
    padding: rem(24);
    background: $mainBranding--darken--03;

    .c-title {
        margin-bottom: rem(20);
        text-transform: uppercase;
        font-family: $oswald;
        font-size: rem(18);
        letter-spacing: rem(2);
        text-align: center;
    }

    .c-title,
    .c-input__datePicker {
        max-width: rem(198);
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
}

.c-price__shoppingCart--action {
    padding: rem(24);

    [class*='c-button'] {
        font-size: rem(17);
        max-width: rem(240);
        width: 100%;
    }
}

.c-shoppingCart--promo {
    background: $orange--light;
    border-bottom: 1px solid rgba($grey--02,0.28);
    padding: rem(24);

    .c-title {
        margin-bottom: rem(12);
        text-transform: uppercase;
        font-family: $maven;
        font-size: rem(14);
        line-height: rem(28);
        letter-spacing: rem(1);
        color: $white;
    }

    .c-label {
        @include bp-lt($medium) {
            display: none;
        }
    }

    .c-input__inline {
        display: flex;
        flex-flow: row wrap;
        align-items: stretch;
        justify-content: flex-start;
        width: 100%;
    }

    .c-input__holder {
        float: none;
        width: calc(100% - 54px);
        border: 0;
        height: auto;
    }

    .c-input {
        background-color: $white;
        height: 100%;

        input {
            padding: 10px 20px;
            height: 100%;
        }

        input::placeholder {
            color: $black;
            letter-spacing: 0.45px;
        }
    }

    form .form__discount__submit {
        display: inline-block;
        position: relative;
        background-color: $white;
        width: 53px;
        height: 48px;
        transition: background-color 0.3s ease-in-out;

        &:hover {
            background-color: $corail;

            &::after {
                color: $white;
            }
        }

        &::after {
            content: '\EA10';
            position: absolute;
            top: calc(50% + 1px);
            left: 50%;
            transform: translate(-50%, -50%);
            font-family: $iconFont;
            font-size: rem(15);
            color: $orange--light;
            cursor: pointer;
            pointer-events: none;
            transition: color 0.3s ease-in-out;
        }
    }

    form input.c-button--corail--noBorder {
        background: none;
        float: none;
        width: 100%;
        height: 100%;
    }
}

.checkout-cart-index .c-section__title {
    letter-spacing: rem(8);
    padding-bottom: 23px;
    line-height: 1.5;

    &::before {
        width: rem(50);
        height: 1px;
    }
}

.checkout-cart-index .c-price__shoppingCart--delivery {
    background-color: #B25252;
}

.checkout-cart-index .alert-box {
    max-width: 100%;

    @include bp-lt($medium){
        max-width: calc(100% - 42px);
    }
}

.checkout-cart-index {
    > .loading-mask {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 100;
    }

    > .loading-mask .loader {
        height: 100%;
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: center;
        background:rgba($black, .2);
    }

    #maincontent.o-page--bkgGrey > .row > .messages {
        padding: 0 10px;

        @include bp-lt($medium) {
            padding-left: rem(34);
            padding-right: rem(34);
        }
    }

    #maincontent.o-page--bkgGrey > .row > .messages .message {
        max-width: $layoutWidth;
        border: none;
        border-radius: rem(5);
        background: $mainBranding !important;
        min-height: rem(75);
        padding: 0.875rem 1.5rem 0.875rem 0.875rem;
        margin: 0 auto;
        margin-bottom: rem(20);

        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: flex-start;

        &:before {
            content: '\EA20';
            position: absolute;
            border-radius: 50%;
            border: rem(2) solid $white;
            display: block;
            width: rem(50);
            height: rem(50);
            padding: rem(1) rem(8);
            font-size: rem(30);
            font-family: "iconfont";
            color: $white;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }

        &.success:before { content: '\EA1F'; }
        &.error:before { content: '\EA1E'; }
        &.notice:before { content: '\EA1E'; }

        > div {
            font-size: rem(15);
            font-family: $maven;
            padding-left: rem(80);
            color: $white;
        }
    }

    #shopping-cart-table .product-cart-info .cart.item.message.error {
        font-family: $maven;
        font-size: rem(10);
        line-height: 1.5;
        color: $colorLink;
    }
}