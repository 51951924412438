// ========================================= //
// tooltip
// ========================================= //

.c-tooltip__anchor {
    position: relative;
}

.c-tooltip {
    position: absolute;
    z-index: $zindex--content;
    top: 100%;
    width: auto;
    background: $white;
    box-shadow: rem(1) 0 rem(10) rgba($black, .1);
    border-radius: rem(5);
    opacity: 0;
    transform: translateY(0);
    display: none;
    transition: $ease;

    @include bp-lt($small) {
        width: rem(280);
    }

    &.is-visible {
        @include animation('displayNoneToFadeIn .4s 1');
        display: block;
        opacity: 1;
        transform: translateY(rem(10));
    }

    .c-item {
        white-space: nowrap;
        font-family: $maven;
        font-size: rem(16);
        color: $colorFont;
        margin-bottom: rem(5);

        @include bp-lt($small) {
            white-space: initial;
        }
    }
}

.c-tooltip__inner {
    $side : 8;

    position: relative;
    padding: rem(20) rem(24);

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: rem(57);
        display: block;
        width: rem($side);
        height: rem($side);
        background: $white;
        transform: translateY(-50%) rotate(-45deg);
        box-shadow: rem(1) 0 rem(10) rgba($black, .1);
    }

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: rem(45);
        width: rem(30);
        height: rem(15);
        background: $white;
    }
}
