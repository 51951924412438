// ========================================= //
// Magento/ actions-toolbar
// ========================================= //

$actions-toolbar-mt: $spacer * 3;
$actions-toolbar-gutter: 20px;

.actions-toolbar {

    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    margin: $actions-toolbar-gutter * -0.5;
    margin-top: $actions-toolbar-mt - ($actions-toolbar-gutter * 0.5);

    // E L E M E N T S
    > * {
        margin: $actions-toolbar-gutter * 0.5;
    }

    .action {
        // // @extend .c-button--ter;
    }

    .action.primary {
        // // @extend .c-button--main;
    }

    // M E D I A   Q U E R I E S
    @include bp-gt(768px)
    {
    };

    @include bp-gt(1024px)
    {

    };

    @include bp-gt(1440px)
    {

    };

}
