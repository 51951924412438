// ========================================= //
// titles
// ========================================= //

[class*='c-section__title'] {
    position: relative;
    font-family: $oswald;
    text-transform: uppercase;
    color: $colorFont;
    text-align: center;
    letter-spacing: rem(4);
    font-size: rem(30);
    padding-bottom: rem(30);
    margin-bottom: rem(34);

    @include bp-lt($medium) { font-size: rem(28); }
    @include bp-lt($small) { font-size: rem(26); }
    @include bp-lt($mobileL) { font-size: rem(22); }

    @include bp-lt($mobileM) {
        padding-left: rem(24);
        padding-right: rem(24);
    }

    &:before {
        content: '';
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        display: block;
        width: rem(31);
        height: rem(2);
        background: $subBranding;
    }

    &[class*='--white'] {
        color: $white;

        &:before { background: $white; }
    }

    &[class*='--big'] {
        font-size: rem(36);
        padding-bottom: rem(36);
        margin-bottom: rem(40);

        @include bp-lt($medium) { font-size: rem(34); }
        @include bp-lt($small) { font-size: rem(30); }
    }

    &[class*='--textLeft'] {
        text-align: left;
        &:before {
            left: 0;
            transform: none;
        }
    }

    &[class*='--alt'] {
        color: $subBranding;
        font-family: $maven;
        font-size: rem(16);
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: rem(.5);
    }
}
