// ========================================= //
// comparator
// ========================================= //

.c-comparator {
    $titleH : 61;
    $comparatorH : 254;

    bottom: 0;
    left: 0;
    z-index: 80;
    width: 100%;
    height: rem($comparatorH);
    background: linear-gradient(to bottom, $subBranding--darken--01, $subBranding 80%);
    transform: translateY(0);
    transition: $ease;

    &.is-fixed { position: fixed; }
    &.is-hide { transform: translateY(100%) translateY(#{$titleH}px); }
    &.is-partialHide { transform: translateY(100%); }

    .c-comparator__header {
        position: absolute;
        top: 0;
        left: calc((100% - #{$layoutWidth}) / 2);
        transform: translateY(-100%);
        max-width: rem(440);
        width: 100%;
        height: rem($titleH);
        padding-left: rem(34);
        padding-right: rem(34);
        background: $subBranding;
        border-radius: rem(5) rem(5) 0 0;
        color: $white;
        cursor: pointer;
        @include clearfix;

        @include bp-lt($large) {
            top: rem(1);
        }

        @include bp-lt($desktop) {
            left: rem(24);
        }

        @include bp-lt($small) {
            transform: translateY(-95%);
            left: 0;
            max-width: 100%;
        }
    }

    .c-comparator__title {
        height: rem($titleH);
        float: left;
        text-transform: uppercase;
    }

    [class*='c-comparator__toggle'] {
        height: rem($titleH);
        float: right;

        &[class*='--expand'] {
            display: none;
        }

        [class*='c-fonticon'] {
            position: relative;
            top: rem(2);
        }
    }

    .c-comparator__content {
        $actionW : 247;
        $actionW--desktop : 150;
        $itemH : 158;

        padding-top: calc((#{$comparatorH}px - #{$itemH}px) / 2);

        @include bp-lt($desktop) {
            padding-left: rem(24);
            padding-right: rem(24);
        }

        @include bp-lt($medium) {
            padding-top: rem(24);
        }

        .columns {
            @include clearfix;
        }

        .c-scroll-x {
            @include bp-lt($medium) {
                width: 100%;
                height: auto;
                margin-bottom: rem(10);
                overflow-x: scroll;
                overflow-y: hidden;
                -webkit-overflow-scrolling: touch;
                overflow-scrolling: touch;
                -ms-overflow-style: none;
                &::-webkit-scrollbar { width: 0 !important }

                &::-webkit-scrollbar
                {
                    -webkit-appearance: none;
                    width: rem(14);
                    height: rem(8);
                }

                &::-webkit-scrollbar-thumb
                {
                    border-radius: rem(8);
                    border: none;
                    background-color: rgba($white, .2);
                }
            }
        }

        .c-comparator__item {
            float: left;
            width: calc(100% - #{$actionW}px);
            counter-reset: section;
            @include clearfix;

            @include bp-lt($desktop) {
                width: calc(100% - #{$actionW--desktop}px);
            }

            @include bp-lt($medium) {
                width: 100%;
                float: none;
                text-align: center;
                white-space: nowrap;
            }
        }

        .c-comparator__item--grid {
            width: 20%;
            padding-right: rem(35);
            float: left;

            @include bp-lt($medium) {
                width: rem(190);
                float: none;
                display: inline-block;
                padding-right: 0;
            }

            &:last-of-type {
                @include bp-lt($medium) {
                    width: rem(160);
                }
            }

            &:last-of-type .c-comparator__item--holder {
                &:after { display: none; }
            }
        }

        .c-comparator__item--holder {
            position: relative;
            width: 100%;
            height: rem($itemH);
            float: left;
            background: transparent;
            border: solid rem(1) rgba($white, .2);

            @include bp-lt($medium) {
                width: rem(160);
            }

            &:before {
                counter-increment: section;
                content: counter(section);
                position: absolute;
                top: rem(40);
                display: block;
                width: 100%;
                font-family: $oswald;
                font-weight: 300;
                font-size: rem(48);
                text-align: center;
                color: rgba($white, .2);
            }

            &:after {
                content: '+';
                position: absolute;
                top: rem(-5);
                right: rem(-26);
                display: block;
                line-height: rem($itemH);
                font-family: $oswald;
                font-size: rem(48);
                font-weight: 300;
                color: $white;
            }
        }

        .c-comparator__action {
            width: #{$actionW}px;
            float: right;

            @include bp-lt($desktop) {
                width: #{$actionW--desktop}px;
            }

            @include bp-lt($medium) {
                width: 100%;
                float: none;
            }

            a {
                display: block;
                width: 100%;
                height: rem($itemH);
                background: rgba($white, .2);
                font-family: $maven;
                text-align: center;
                color: $white;
                text-transform: uppercase;
                font-weight: 700;
                font-size: rem(18);
                border-bottom: solid rem(2) $white;
                transition: $ease;

                @include bp-lt($medium) {
                    height: rem(40);
                    max-width: rem(256);
                    margin-left: auto;
                    margin-right: auto;
                }

                &:hover {
                    background: $mainBranding;
                }
            }
        }
    }

    &.is-hide,
    &.is-partialHide {
        .c-comparator__toggle--expand { display: block; }
        .c-comparator__toggle--contract { display: none; }
    }
}

.c-comparator__item--content {
    position: relative;
    z-index: 20;
    height: 100%;
}

.c-comparator__item--delete {
    $side : 14;

    position: absolute;
    z-index: 30;
    top: rem(10);
    right: rem(10);
    display: block;
    width: rem($side);
    height: rem($side);
    background: url(#{$img}c-icon__delete.svg) no-repeat 50% 50%;
    background-size: contain;
}

.c-comparator__item--thumb {
    position: absolute;
    z-index: 25;
    top: 0; left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    object-fit: cover;
}
