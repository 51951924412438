// ========================================= //
// pageProduct
// ========================================= //

.c-product__content {}

.c-product__content__header {
    background: $white;
    border-bottom: $border--grey--02;

    >.row>.columns {
        @include clearfix;
    }
}

.c-product__content__thumb {
    position: relative;
    width: 44.6%;
    float: left;
    border-left: $border--grey--02;
    border-right: $border--grey--02;

    @include bp-lt($tablet) {
        float: none;
        width: 100%;
        height: auto !important;
        max-width: rem(560);
        margin-left: auto;
        margin-right: auto;
        margin-bottom: rem(72);
        border-left: none;
        border-right: none;
        border-bottom: $border--grey--02;
    }

    [class*='c-product__label'] {
        top: rem(26);
        left: rem(-11);

        @include bp-lt($desktop) {
            top: rem(20);
            left: rem(20);
        }
    }
}

.c-product__content__gallery {
    position: relative;

    .c-item__download {
        display: block;
        position: absolute;
        z-index: 30;
        top: rem(30);
        right: rem(30);
        font-size: rem(22);
        color: $colorFont;
    }

    .js-slick__gallery {
        a {
            cursor: zoom-in;
        }
    }

    .js-slick__controls {
        width: 100%;
        position: absolute;
        bottom: 0;
        transform: translateY(50%);
    }
}

.c-product__content__info {
    width: 55.4%;
    float: left;
    padding-top: rem(24);
    padding-left: rem(50);
    padding-right: $gutter;

    @include bp-lt($tablet) {
        float: none;
        width: 100%;
        height: auto !important;
        padding-left: rem(48);
        padding-right: rem(48);
    }

    .row.not-connected {
        margin-bottom: 20px;
    }

    .not-connected p {
        font-family: $maven;
        font-size: rem(15);
        line-height: rem(28);
        color: $colorFont;
        margin-bottom: rem(12);

        @include bp-lt($small) {
            font-size: rem(14);
            line-height: rem(26);
        }
    }
}

.c-product__content__info--header {
    position: relative;
    padding-right: rem(115);
    border-bottom: $border--grey--02;

    @include bp-lt($mobileXl) {
        padding-right: rem(30);
    }

    .o-share {
        position: absolute;
        right: 0;
        top: rem(10);
    }

    [class*='c-section__title'] {
        @include bp-lt($mobileL) {
            padding-left: 0;
            padding-right: rem(24);
        }
    }
}

.addthis_sharing_toolbox .tac_activate {
    position: relative;
    right: 0;
    left: auto;
    width: rem(100);
    padding: rem(5) rem(5);
    background: none;

    &,
    .tac_float b {
        color: $black;

    }

    @include bp-lt($mobileXl) {
        // padding-right: rem(30);
        width: 100%;
        // top: rem(-52);
        transform: translateY(-125%)
    }
}

.c-product__content__info--content {
    padding-top: rem(24);
    padding-bottom: rem(24);

    .wk-container {
        float: right;
        padding: 0;
        position: relative;

        .wk-oosn-emailBox {
            display: block;
        }

        .c-button--corail {
            width: 100%;
            float: none;
        }

        #wk-oosn-warning.message.error {
            margin: 0 0 40px;

            @include bp-lt($desktop) {
                margin: 0 0 52px;
            }
        }

        #wk-oosn-warning.message.success {
            margin: 0 0 52px;
        }

        #wk-oosn-warning.message {
            width: 100%;

            b {
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);

                background: #fff;
                z-index: 100;

                box-shadow: 0 rem(3) rem(16) rgba($black, .1);

                font-family: $maven;
                font-size: 10px;
                font-weight: 400;
                line-height: 14px;
                color: $colorFont;

                padding: 10px 20px;
                display: block;
                width: 100%;
            }
        }

        &, .wk-oosn-notificationcontainer {
            margin: 0;
        }
    }

    .row>.columns {
        @include clearfix;
    }

    .c-stock--info {
        position: relative;
        width: 50%;
        float: left;
        padding-left: rem(20);
        margin-bottom: rem(20);
        font-family: $maven;
        font-size: rem(12);
        font-weight: 700;
        text-transform: uppercase;
        color: $colorFont;

        @include bp-lt($mobileL) {
            width: 100%;
            float: none;
        }

        span {
            display: block;
            font-size: rem(10);
        }

        &:before {
            $side: 7;

            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            display: block;
            width: rem($side);
            height: rem($side);
            background: $green;
            border-radius: 50%;
        }

        &.is-outOfStock:before {
            background: $corail;
        }
    }

    .c-sale--info {
        font-family: $maven;
        font-size: rem(15);
        color: $colorFont;
        text-align: right;

        @include bp-lt($mobileL) {
            text-align: left;
            margin-bottom: rem(24);
        }

        span {
            margin-right: rem(13);
        }

        span,
        img {
            display: inline-block;
            vertical-align: middle;
        }
    }

    .c-priceGrid,
    .c-quantity {
        display: inline-block;
        vertical-align: top;
        // margin-bottom: rem(14);
    }

    #product_addtocart_form {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
    }

    [class*='c-button'] {
        float: right;
        margin-bottom: 0;

        @include bp-lt($desktop) {
            padding-left: rem(15);
            padding-right: rem(15);
        }

        @include bp-lt($mobileL) {
            display: block;
            width: 100%;
            margin-top: rem(24);
        }
    }

    .c-priceBox {

        .c-price--alt,
        .c-price {
            vertical-align: middle;
        }

        .c-price--alt {
            font-size: rem(16);
            top: rem(1);
        }

        .c-price {
            font-size: rem(32);
        }
    }

    .c-price.old-price .c-priceBox .price {
        font-size: rem(16);
    }

    .price-final_price .price-wrapper:not(.c-price--alt) .price,
    .c-price.special-price .c-priceBox .price {
        font-size: rem(32);
    }

    .c-priceGrid {
        margin-right: rem(20);

        @include bp-lt($desktop) {
            margin-right: rem(10);
        }

        @include bp-lt($small) {
            margin-top: rem(20);
        }

        a {
            font-size: rem(14);
            font-family: $maven;
            color: $subBranding;
            text-decoration: underline;
            letter-spacing: rem(.5);
        }
    }

    .c-priceGrid + .c-quantity {
        @include bp-lt($small) {
            margin-top: rem(20);
        }
    }

    .c-quantity {
        float: right;
        margin-bottom: 0;

        @include bp-lt($mobileL) {
            width: 100%;
            margin-top: 10px;
        }

        .c-title,
        .c-update--count {
            display: inline-block;
            vertical-align: middle;
        }

        .c-title {
            text-transform: uppercase;
            font-size: rem(14);
            letter-spacing: rem(.5);
            font-weight: 700;

            @include bp-lt($tablet) {
                font-size: rem(12);
            }
        }

        .c-update--count {
            button {
                color: $subBranding;
            }

            input {
                border: $border--grey--02;
            }
        }

        .c-quantity {
            margin-right: 10px;
        }
    }
}

.c-product__content__info--buttons,
.c-product__content__info--footer {
    border-top: $border--grey--02;
    padding-top: rem(24);
    padding-bottom: rem(10);

    [class*='c-button'] {
        margin-bottom: rem(14);

        @include bp-lt($mobileL) {
            width: 100%;
        }
    }

    [class*='c-button']:first-of-type {
        margin-right: rem(20);

        @include bp-lt($mobileL) {
            margin-right: 0;
        }
    }
}

.c-product__desc {
    >.row>.columns {
        @include clearfix;
    }

    .c-product__desc--long {
        float: left;
        width: 65%;
        padding-right: rem(50);
        border-right: $border--grey--02;

        @include bp-lt($medium) {
            width: 60%;
            padding-right: rem(40);
        }

        @include bp-lt($tablet) {
            width: 100%;
            padding-right: 0;
            border-right: none;
            border-bottom: $border--grey--02;
            padding-bottom: rem(48);
            margin-bottom: rem(48);
            height: auto !important;
        }

        .c-wysiwyg {
            margin-bottom: rem(24);
        }
    }

    .c-product__desc--size {
        float: right;
        width: 35%;
        padding-left: rem(50);

        @include bp-lt($medium) {
            width: 40%;
            padding-left: rem(40);
        }

        @include bp-lt($tablet) {
            width: 100%;
            padding-left: 0;
            height: auto !important;
        }
    }
}

.c-product__desc__carac {
    font-family: $maven;
    color: $colorFont;
    letter-spacing: rem(.5);

    .c-item {
        margin-bottom: rem(5);
    }
}

.c-product__desc--size--title {
    margin-bottom: rem(10);
    padding-left: rem(7);
    font-family: $maven;
    color: $colorFont;
    font-size: rem(15);
    font-weight: 700;
    text-transform: uppercase;
}

.c-product__nav {}

.c-product__nav--prev,
.c-product__nav--next {
    $itemH: 60;

    position: fixed;
    z-index: $zindex--fixed;
    top: 40%;
    transform: translateY(-50%);
    display: block;
    width: rem(48);
    height: rem($itemH);
    color: $white !important;
    box-shadow: rem(1) 0 rem(7) rgba($black, .35);

    @include bp-lt($small) {
        top: 30%;
    }

    .c-icon {
        position: relative;
        z-index: $zindex--content;
        height: rem($itemH);
        text-align: center;
        cursor: pointer;
        background: $mainBranding;

        [class*='c-fonticon'] {
            position: relative;
            top: rem(2);
        }
    }

    .c-label {
        position: absolute;
        z-index: $zindex--background;
        height: rem($itemH);
        width: rem(110);
        background: $mainBranding--lighten--05;
        top: 0;
        font-family: $maven;
        font-weight: 700;
        font-size: rem(14);
        line-height: rem(17);
        text-transform: uppercase;
        transition: $ease;
        box-shadow: rem(1) 0 rem(7) rgba($black, .35);

        span {
            display: block;
        }
    }
}

.c-product__nav--prev {
    left: 0;

    .c-label {
        text-align: left;
        padding-left: rem(12);
        left: rem(48);
        transform: translateX(-100%);
    }

    &:hover .c-label {
        transform: translateX(0);
    }
}

.c-product__nav--next {
    right: 0;

    .c-label {
        text-align: right;
        padding-right: rem(12);
        left: rem(-110);
        transform: translateX(100%);
    }

    &:hover .c-label {
        transform: translateX(0);
    }
}

.c-category {
    &__content {

        .products-grid .product-items {
            @include bp-lt($medium){
                margin: 0;
                padding: 0 rem(10);
            }
        }

        .products-grid .product-items li.product-item {
            @include bp-lt($medium){
                padding: 0 rem(10);
            }
        }

        .block-push-product.columns {
            margin-left: 2%;
            padding: 0;
            width: calc((100% - 6%) / 4);

            &:nth-child(3n + 1) {
                margin-left: 2%;
            }

            &:nth-child(4n + 1) {
                margin-left: 0;
            }

            @include bp-lt($medium){
                padding: 0 rem(10);
            }

            @include bp-bt($tablet, $small){
                margin-left: 2%;
                padding: 0 rem(10);
                width: calc((100% - 4%) / 3);

                &:nth-child(3n + 1) {
                    margin-left: 0;
                }
            }

            @include bp-lt($small){
                width: 50%;
                margin: 0;
            }
        }
    }
}
