// ========================================= //
// slider
// ========================================= //

.js-slick__controls {
    $side : 30;

    position: relative;
    padding-left: rem($side + 10);
    padding-right: rem($side + 10);

    .slick-arrow {
        position: absolute;
        z-index: 30;
        top: 50%;
        transform: translateY(-50%);
        width: rem($side);
        height: rem($side);
        margin: 0;
        padding: 0;
        font-size: 0;
        opacity: 1;
        transition: $ease;
        background-color: transparent;
        background-position: 50% 50%;
        background-repeat: no-repeat;

        &.slick-prev {
            background-image: url(#{$img}c-icon__prev.svg);
            left: 0;
        }

        &.slick-next {
            background-image: url(#{$img}c-icon__next.svg);
            right: 0;
        }

        &.slick-disabled {
            opacity: 0;
            cursor: default;
            pointer-events: none;
        }
    }

    .c-slick__controls__item {
        padding: rem(10) rem(15);

        @include bp-lt($small) {
            padding-left: rem(5);
            padding-right: rem(5);
        }

        img {
            display: block;
            margin-left: auto;
            margin-right: auto;
            opacity: 1;
            box-shadow: 0 rem(1) rem(4) rgba($black, .1);
            transition: $ease;
        }

        .c-thumb {
            background-color: $white;
            cursor: pointer;
        }

        &.slick-current img {
            opacity: .4;
            box-shadow: 0 rem(1) rem(9) rgba($black, .3);
        }
    }
}
