// ========================================= //
// form
// ========================================= //
.c-form__holder {
    padding: rem(48) rem(30);

    .column+.column:last-child,
    .columns+.column:last-child,
    .column+.columns:last-child,
    .columns+.columns:last-child {
        float: left;
    }

    .c-info {
        display: inline-block;
        position: relative;
        font-family: $maven;
        font-size: rem(14);
        color: $colorFont;

        span {
            color: $error;
            margin-right: rem(5);
        }
    }

    .condition_check span.is-required {
        color: $error;
    }

    .condition_check {
        padding-bottom: 10px;
    }

    .condition_check div.mage-error {
        transform: none;
        left: 1.562rem;
        right: auto;
        bottom: 4px;
    }

    input[type="submit"] {
        min-width: 0;
    }
}

.c-condition__check {
    .c-input__holder {
        border: none;
    }

    .field .row .columns > p {
        margin-bottom: 0;
    }

    .validation-advice {
        color: $error;
        font-family: $maven;
        font-size: rem(10);
        text-transform: uppercase;
        letter-spacing: rem(1);
        font-weight: 700;
        position: absolute;
    }
}

.c-condition__check label[for="rgpd"],
.c-info__policy p {
    font-family: $maven;
    font-size: rem(15);
    color: $colorFont;
    font-weight: 700;

    a {
        color: $corail;
    }
}

.c-info__policy {
    margin-top: rem(20);
}

// .customer_account_login.page-title-wrapper {
//     display: none;
// }
