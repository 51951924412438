// ========================================= //
// priceBox
// ========================================= //

.c-priceBox {
    font-family: $oswald;
    font-weight: 300;
    letter-spacing: rem(1);

    .c-price,
    .c-price--alt {
        margin-left: rem(4);
        margin-right: rem(4);
        display: inline-block;
        vertical-align: bottom;
    }

    .c-price {
        color: $mainBranding;
        font-size: rem(16);
    }

    .c-price--alt {
        position: relative;
        top: rem(-1);
        color: $subBranding;
        font-size: rem(13);
        text-decoration: line-through;
    }
}
