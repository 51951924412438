// ========================================= //
// cartQuantity
// ========================================= //

[class*='c-update--count'] {
    $side : 33;
    @include clearfix;

    button {
        display: block;
        float: left;
        width: rem(30);
        height: rem($side);
        padding: 0;
        margin: 0;
        text-align: center;
        color: $white;
        background: none;
        font-family: $maven;
        font-size: rem(24);
        pointer-events: auto;
    }

    .c-input {
        display: block;
        float: left;
        width: rem($side);
        height: rem($side);
        padding: 0;
        margin: 0;
        text-align: center;
        border: none;
        box-shadow: none;
        color: $colorFont;

        &[readonly] {
            background: $white;
            font-family: $maven;
            font-size: rem(14);
        }
    }

    &[class*='--corail'] {
        button { color: $subBranding; }
        .c-input { border: $border--grey--02; }
    }
}
