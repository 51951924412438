// ========================================= //
// page
// ========================================= //

[class*="o-page"] {
    &[class*='--bkgGrey'] {
        background-color: $grey--05;
    }

    &[class*='--paddingBtm'] {
        padding-bottom: rem(72);
    }

    &[class*='--fullHeight'] {
        height: 100vh;
    }
}
.table_toggle {
    background-color: $corail;
    color: $white;
    padding: 8px;
    cursor:pointer;
    
    .c-fonticon__icon--shevronDown, .table_toggle .c-fonticon__icon--shevronUp {
        float: right;
    }
}

