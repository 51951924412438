// ========================================= //
// breadcrumb
// ========================================= //

$breadcrumbH : 42;

.c-breadcrumb {
    border-bottom: solid rem(1) $grey--07;
    background-color: $grey--05;

    .c-backToShop {
        position: absolute;
        right: 0;
        top: 0;
        height: rem($breadcrumbH);
        font-family: $maven;
        font-size: rem(14);
        text-transform: uppercase;
        font-weight: 700;
        color: $colorFont;

        @include bp-lt($layoutWidth) {
            right: rem(24);
        }

        span.o-corail {
            margin-right: rem(10);
        }

        .c-holder {
            position: relative;
            transition: $ease;
            @include links($subBranding, $subBranding, 1);
        }

        @include bp-lt($mobileL) {
            display: none;
        }
    }
}

.c-breadcrumb__list {
    margin: 0;
    list-style: none;
    height: rem($breadcrumbH);

    @include bp-lt($medium) {
        padding-left: rem(10);
    }

    li {
        display: inline-block;
        height: rem($breadcrumbH);
        padding-top: rem(10);
        font-size: rem(13);
        color: $colorFont;

        @include bp-lt($tabletS) {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: rem(250);
        }

        @include bp-lt($mobileL) {
            max-width: rem(200);
        }

        @include bp-lt($mobileM) {
            max-width: rem(150);
        }

        @include bp-lt($small) {
            display: none;

            &:first-of-type { display: inline-block; }
            &:last-of-type { display: inline-block; }
        }

        &, a {
            font-family: $maven;
        }

        a {
            position: relative;
            display: inline-block;
            font-size: rem(13);
            color: $colorFont;
            font-weight: 700;
            transition: $ease;

            @include links($subBranding, $subBranding, 1)
        }

        &:after {
            content: '>';
            display: inline-block;
            margin-left: rem(7);
            margin-right: rem(7);
            color: $subBranding;
        }

        &:last-of-type:after { display: none; }
    }
}
