// ========================================= //
// sections
// ========================================= //

[class*='o-section'] {
    padding-top: rem(48);
    padding-bottom: rem(48);

    @include bp-lt($medium) {
        padding-left: rem(24);
        padding-right: rem(24);
    }

    &[class*='--bkgGreyLight'] {
        background-color: $grey--05;
    }

    &[class*='--bkgGreyDark'] {
        background-color: $grey--06;
    }

    &[class*='--bkgCorail'] {
        background-color: $corail;
    }

    &[class*='--bkgGradientCorail'] {
        background-color: $corail;
        background: linear-gradient(225deg, $corail 0%, #bb4b4b 90%);
    }

    &[class*='--bkgWhite'] {
        background-color: $white;
    }

    &[class*='--borderTop'] {
        border-top: $border--grey--02;
    }

    &[class*='--borderBottom'] {
        border-bottom: $border--grey--02;
    }
}
