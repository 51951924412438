// ========================================= //
// buttons
// ========================================= //

[class*="c-button"] {
    display: inline-block;
    height: rem(57);
    width: auto;
    min-width: rem(200);
    padding-left: rem(30);
    padding-right: rem(30);
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: solid rem(2);
    text-transform: uppercase;
    text-align: center;
    font-family: $maven;
    font-size: rem(14);
    font-weight: 700;
    letter-spacing: rem(1);
    transition: $ease;

    &[class*="--corail"] {
        background: $corail;
        color: $white;
        border-bottom-color: $corail--dark;
    }

    &[class*="--lightCorail"] {
        background: $mainBranding--lighten--03;
        color: $white;
        border-bottom-color: $white;
    }

    &[class*="--white"] {
        background: $white;
        color: $colorFont;
        border-bottom-color: $grey--04;
    }

    &[class*="--grey"] {
        background: $grey--05;
        color: $colorFont;
        border-bottom-color: $taupe--light;
    }

    &[class*='--small'] {
        height: rem(48);
    }

    &[class*='--noBorder'] {
        border-bottom: 0;
    }

    &:hover {
        background: $mainBranding;
        color: $white;
        border-bottom-color: $mainBranding--darken--02;
    }
}

button.disabled,
button[disabled] {
    background: $corail;
    color: $white;
    border-bottom-color: $corail--dark;
}

button:focus,
.button:focus,
button.disabled:hover,
button.disabled:focus,
button[disabled]:hover,
button[disabled]:focus,
.button.disabled:hover,
.button.disabled:focus,
.button[disabled]:hover,
.button[disabled]:focus {
    background: $mainBranding;
    color: $white;
    border-bottom-color: $mainBranding--darken--02;
}

.c-action__gototop {
    $side : rem(48);

    position: fixed;
    z-index: 200;
    width: $side;
    height: $side;
    bottom: rem(28);
    right: rem(28);
    background: $subBranding;
    margin: 0;
    opacity: 0;
    transform: translateY(200%);
    pointer-events: none;
    padding: 0;
    z-index: $zindex--fixed - 1;
    transition: $ease;

    @include bp-lt($small) {
        bottom: rem(14);
        right: rem(14);
        width: rem(38);
        height: rem(38);
    }

    img {
        position: relative;
        top: rem(-3);
    }

    &.is-visible {
        opacity: .9;
        pointer-events: auto;
        transform: translateY(0);
    }

    &:hover, &:focus, &:active {
        opacity: 1;
        background: $subBranding;
    }
}
