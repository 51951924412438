// ========================================= //
// productItem
// ========================================= //

.c-grid__product__item {
    position: relative;
    background: $white;
    margin-bottom: rem(36);
    border: $border--grey--02;
    box-shadow: 0 0 0 rgba($black, .1);
    transition: $ease;

    &:hover {
        box-shadow: 0 rem(3) rem(16) rgba($black, .1);
    }

    &.has-promo {
        position: relative;

        a {
            display: block;
            position: absolute;
            top: 0; left: 0;
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: 50% 0%;
            background-color: $subBranding;
        }

        img {
            display: none;
        }
    }

    [class*='c-product__label'] {
        left: rem(20);
        top: rem(-7);
    }
}

.c-grid__product__item--content {
    position: relative;
    z-index: $zindex--background;
}

.c-grid__product__item--picture {
    width: 100%;
    text-align: center;
    overflow: hidden;

    .product-image-container {
        width: 100%!important;
    }

    span.product-image-wrapper {
        padding: 0 !important;
        width: auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    img {
        max-height: rem(230);
        height: 100%;
        width: auto;
        object-fit: contain;
    }
}

.c-grid__product__item--hoverHolder {
    position: relative;
}

.c-grid__product__item--hover {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    align-content: center;
    padding: rem(20);
    width: 100%;
    height: 100%;
    max-height: rem(230);
    z-index: $zindex--content;
    background: rgba($mainBranding, .9);
    opacity: 0;
    transition: $ease;

    @include bp-lt($mobileL) {
        padding-top: rem(10);
    }
}

.c-grid__product__item--date,
.c-grid__product__item--quantity,
.c-grid__product__item--addToCart {
    position: relative;
    transform: translateY(rem(50));
    display: block;
    max-width: rem(210);
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    transition: $ease;
    pointer-events: none;
}

.c-grid__product__item--date {
    margin-bottom: rem(15);
    font-family: $maven;
    color: $white;
    text-align: center;

    @include bp-lt($mobileL) {
        margin-bottom: rem(10);
    }

    span, strong { display: block; }

    span {
        font-size: rem(15);
        line-height: rem(18);
        letter-spacing: rem(1);
    }

    strong {
        font-size: rem(18);
        font-weight: 700;
        letter-spacing: rem(2);

        @include bp-lt($mobileL) {
            font-size: rem(16);
        }
    }
}

.c-grid__product__item--hover > .c-grid__product__item--quantity {
    padding: 0;
    padding-left: 0;
    background: none;
    margin-bottom: 0;

    @include bp-lt($tablet) {
        padding: 0;
    }

    @include bp-lt($mobileL) {
        margin-bottom: 0;
    }
}

.c-grid__product__item--quantity {
    background: rgba($white, .14);
    font-family: $maven;
    color: $white;
    text-align: center;
    height: auto;
    margin: 0 auto;
    margin-bottom: rem(25);
    padding: rem(14) 0;
    padding-left: rem(5);

    @include bp-lt($tablet) {
        padding: rem(8) 0;
        padding-left: rem(5);
    }

    @include bp-lt($mobileL) {
        margin-bottom: rem(10);
    }

    .c-title,
    .c-update--count {
        display: inline-block;
        vertical-align: middle;
    }

    .c-title {
        text-transform: uppercase;
        font-size: rem(14);
        letter-spacing: rem(0.6);
        font-weight: 700;

        @include bp-lt($tablet) {
            font-size: rem(12);
        }

        @include bp-lt($mobileL) {
            display: none;
        }
    }

    .c-grid__product__item--addToCart {
        margin-bottom: 0;
    }
}

.c-grid__product__item--addToCart {
    height: rem(61);
    padding-left: $gutter;
    padding-right: $gutter;
    background: $white;
    text-align: center;
    font-family: $maven;
    font-weight: 700;
    font-size: rem(14);
    letter-spacing: rem(0.6);
    color: $mainBranding;
    text-transform: uppercase;
    transition: $ease;
    pointer-events: auto;

    @include bp-lt($tablet) {
        height: auto;
    }

    @include bp-lt($mobileL) {
        font-size: rem(12);
    }

    &:hover {
        background: $mainBranding;
        color: $white;
    }
}

.c-grid__product__item--baseline {
    position: relative;
    z-index: $zindex--content;
    padding: rem(10);
    text-align: center;
    background: $white;
    height: auto!important;
    border-top: $border--grey--02;
}

.c-grid__product__item--title {
    display: block;
    margin-bottom: rem(5);
    color: $mainBranding;
    font-family: $maven;
    font-weight: 700;
    font-size: rem(14);
    text-transform: uppercase;
    letter-spacing: rem(1);
    line-height: rem(18);
    transition: $ease;

    font-size: rem(14);
    font-family: $maven;
    font-weight: 700;
    color: $mainBranding--lighten--02;
    text-transform: uppercase;
    line-height: 1.3;
    word-break: break-word;

    &:focus,
    &:hover {
        color: $subBranding;
    }
}

.c-product__content__info--buttons,
.c-grid__product__item--footer {
    border-top: $border--grey--02;
    @include clearfix;

    .c-item {
        display: block;
        width: 50%;
        height: rem(46);
        float: left;
        font-family: $maven;
        font-weight: 700;
        font-size: rem(14);
        letter-spacing: rem(0.6);
        text-align: center;
        text-transform: uppercase;
        color: $mainBranding;
        background: transparent;
        transition: $ease;

        @include bp-lt($mobileL) { font-size: rem(13); }
        @include bp-lt($mobileM) { font-size: rem(11); }

        &:hover {
            color: $white;
            background: $mainBranding;
        }

        &:first-of-type {
            border-right: $border--grey--02;
        }
    }
}

%hover {
    .c-grid__product__item--hover {
        opacity: 1;
    }

    .c-grid__product__item--date,
    .c-grid__product__item--quantity,
    .c-grid__product__item--addToCart {
        transform: translateY(0);
        pointer-events: initial;
    }
}

.c-grid__product__item--hoverHolder {
    &.is-visible {
        @extend %hover;
    }
}

.no-touchevents {
    .c-grid__product__item--hoverHolder {
        &:hover {
            @extend %hover;
        }
    }
}
