// ========================================= //
// Magento/ opc-wrapper
// ========================================= //
$opc-wrapper-MQL: 'lg';
$opc-wrapper-checkoutSteps-padding: 1rem;

.opc-wrapper {

    // E L E M E N T S
    #checkoutSteps {

        $root: &;

        padding-left: 0;
        list-style: none;
        overflow: visible;
        margin: 0;
        border: none;

        > li {
            padding: $opc-wrapper-checkoutSteps-padding;
            position: relative;
        }

        > li:not([style="display: none;"]) + li:not([style="display: none;"]) {
            margin-top: rem(53);
        }

        > li:not([style="display: none;"]) {
            border: 1px solid $grey--02;
        }

        .step-title {
            display: block;
            padding: rem(10) rem(20);
            margin: 0;
            background: $purple--dark;
            font-size: rem(16);
            line-height: (28/16);
            letter-spacing: em(1.14, 16);
            font-weight: 400;
            text-transform: uppercase;
            color: $white;
            text-align: center;
            border-bottom: 0;
        }

        li.checkout-payment-method,
        li.checkout-shipping-address {
            padding: 0;

            .step-title {
                padding: rem(10) rem(20);
                margin: 0;
                background: $purple--dark;
                font-size: rem(16);
                line-height: (28/16);
                letter-spacing: em(1.14, 16);
                font-weight: 400;
                text-transform: uppercase;
                color: $white;
                text-align: center;
                border-bottom: 0;

            }

            .field.addresses {
                margin-left: ($opc-wrapper-checkoutSteps-padding * -1);
                margin-right: ($opc-wrapper-checkoutSteps-padding * -1);
                padding: ($opc-wrapper-checkoutSteps-padding * 0.5) ($opc-wrapper-checkoutSteps-padding);
            }

        }

        #shipping-method-buttons-container {
            margin: 0;
        }

        li.checkout-shipping-method {
            padding-top: 0;
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 24px;

            .no-quotes-block {
                padding-left: rem(36);
                padding-right: rem(36);
            }
        }

        li.checkout-payment-method {
            .checkout-agreements.fieldset {
                .checkout-agreement {
                    &::before {
                        display: none;
                    }
                }

                .checkout-agreement input {
                    appearance: none;
                    outline: none;
                    box-shadow: none;
                    background-color: $white;
                    border-color: $grey;
                    border-style: solid;
                    border-width: 1px;
                    color: green;
                    font-family: $maven;
                    font-size: 16px;
                    overflow: hidden;

                    display: block;
                    width: 100%;

                    transition-duration: .1s;
                    transition-timing-function: ease;
                    transition-property: border, background-color;

                    &::placeholder, option, optgroup {
                        font-family: $maven;
                        letter-spacing: em(1.14);
                        opacity: 1;
                    }

                    &::placeholder {
                        color: black;
                    }

                    & ~ div:not(.nested):not(.cartQuantity__btn), & ~ span {
                        display: block;
                        font-size: 0.65em;
                        font-family: $maven;
                        line-height: 1.1;
                        position: absolute;
                        width: 100%;
                        float: right;
                    }

                    & ~ .input__message {
                        bottom: 3rem;
                        text-align: right;

                        &:not(:empty) {
                            margin-bottom: 0.9em;
                        }
                    }

                    & ~ div:not(.nested):not(.cartQuantity__btn), & ~ .help-block {
                        top: 100%;
                        &:not(:empty) {
                            margin-top: 0.5em;
                        }
                    }

                    &::-ms-clear {
                        display: none;
                    }

                    min-height: 3rem;
                    line-height: 3rem;
                    padding-top: 0;
                    padding-bottom: 0;
                    padding-right: 1rem;
                    padding-left: 1rem;

                    flex-basis: 1rem;
                    flex-grow: 0;
                    width: 1rem;
                    height: 1rem;
                    min-height: 0;
                    padding-left: 0;
                    padding-right: 0;
                    margin: 0.2em 0;
                    background-color: $white;
                    box-shadow: inset 0 0 0 6px $white;
                    border-width: 1px;
                    flex: 1 0 auto;
                    cursor: pointer;

                    & + label {
                        flex-basis: auto !important;
                        text-align: left !important;
                        padding: 0;
                        font-family: $maven;
                        font-weight: 700;
                        font-size: 15px;
                        line-height: (22/15);
                        letter-spacing: 0.45px;
                        order: inherit;
                        flex-basis: 0;
                        margin:0;
                        background: none;
                        text-align: left;
                        color: #000000;
                        font-weight: 400;

                        button {
                            text-align: left;
                        }
                    }

                    &:checked {
                        background-color: grey;
                    }

                    & + label {
                        white-space: normal;
                    }
                }

                .checkout-agreement input {
                    left: auto;

                    &[type=radio],
                    &[type=checkbox] {
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        appearance: none;
                    }

                    /**
                    * Giving Checkbox & Radio not checked a new appearance
                    */

                    &[type=radio],
                    &[type=checkbox] {
                        width: 15px;
                        height: 15px;
                        border: solid 1px #E5DFDF;
                        position: relative;
                        margin: 0;
                        margin-right: 10px;
                        top: 7px;
                    }

                    /**
                    * Giving Checkbox & Radio checked state a new appearance
                    */

                    &[type=radio]:checked::before,
                    &[type=checkbox]:checked::before {
                        content: "";
                        width: 7px;
                        height: 7px;
                        background-color: #CF5959;
                        position: absolute;
                        top: 3px;
                        left: 3px;
                    }

                    /**
                    * Rounding Radio inputs new appearance
                    */

                    &[type=radio],
                    &[type=radio]:checked::before {
                        border-radius: 100%;
                    }
                }

                .label {
                    background: none;

                    &::before, &::after {
                        display: none;
                    }
                }

                .label button {
                    font-family: $maven;
                    font-size: 15px;
                    color: #000000;
                    letter-spacing: 0.45px;
                    line-height: 28px;
                }
            }

            .payment-methods {
                @include bp-lt(767px){
                    margin: 0;
                }
            }

            .payments > .fieldset {

                padding: 0;
                margin: 0;

                legend {
                    @include sr-only;
                }

                legend + br {
                    display: none;
                }

            }

            .field.billing-address-same-as-shipping-block {
                margin-bottom: $opc-wrapper-checkoutSteps-padding;
                padding-left: $opc-wrapper-checkoutSteps-padding;

                & + fieldset {
                    background-color: white;
                }
            }

            .payment-group {
                padding-bottom: rem(24);
            }

            .step-title {
                margin-bottom: rem(24);
            }

            .billing-address-details {
                background-color: white;
                border: 1px solid $grey--02;
                padding: 14px 20px;

                font-family: $maven;
                font-size: 15px;
                color: #000000;
                letter-spacing: 0.45px;
                line-height: 28px;
                margin-bottom: 10px;

                button {
                    background: $corail;
                    color: $white;
                    border-bottom: solid rem(2);
                    border-bottom-color: $corail--dark;

                    height: rem(57);
                    width: auto;
                    min-width: rem(200);

                    margin: 0 auto;
                    margin-top: 20px;
                    padding-left: rem(30);
                    padding-right: rem(30);
                    display: block;

                    text-transform: uppercase;
                    text-align: center;
                    font-family: $maven;
                    font-size: rem(14);
                    font-weight: 700;
                    letter-spacing: rem(1);
                    transition: $ease;

                    &:hover {
                        background: $mainBranding;
                        color: $white;
                        border-bottom-color: $mainBranding--darken--02;
                    }
                }

                a {
                    color: inherit;
                }
            }

            .message {
                &:first-child {
                    margin-top: $opc-wrapper-checkoutSteps-padding;
                }
                &:last-child {
                    margin-bottom: $opc-wrapper-checkoutSteps-padding;
                }
            }

            fieldset.field {
                appearance: none;
                border: none;
                padding: 0;
                margin-bottom: 1.84615rem;
            }

            .field-select-billing .control::before {
                content: "\EA01";
                font-family: "iconfont";
                font-style: normal;
                font-weight: normal;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                display: block;
                position: absolute;
                right: 20px;
                bottom: 1.5rem;
                -webkit-transform: translateY(50%);
                transform: translateY(50%);
                width: 1em;
                text-align: center;
                color: $red;
            }

            .check {
                margin-top: $opc-wrapper-checkoutSteps-padding;
            }

            .payment-method + .payment-method {
                // margin-top: $opc-wrapper-checkoutSteps-padding;

                .payment-method-title {
                    border-top: 0;
                }
            }

            .payment-method .payment-method-title {
                padding: 0;
            }

            .payment-method {
                padding: 0 rem(44);

                @include bp-lt(640px){
                    padding: 0 rem(20);
                }

                .actions-toolbar {
                    padding: 0;
                    margin: 0;

                    .primary {
                        float: none;
                        margin-left: auto;
                        margin-right: 0;
                    }

                    .primary button {
                        display: inline-block;
                        margin: 0;
                        height: rem(57);
                        width: auto;
                        min-width: rem(200);
                        padding-left: rem(30);
                        padding-right: rem(30);
                        border-top: none;
                        border-left: none;
                        border-right: none;
                        border-bottom: solid rem(2);
                        text-transform: uppercase;
                        text-align: center;
                        font-family: $maven;
                        font-size: rem(14);
                        font-weight: 700;
                        letter-spacing: rem(1);
                        transition: $ease;
                        background: $corail;
                        color: $white;
                        line-height: 1;
                        border-bottom-color: $corail--dark;

                        &:hover {
                            background: $mainBranding;
                            color: $white;
                            border-bottom-color: $mainBranding--darken--02;
                        }
                    }
                }

                .billing-address-same-as-shipping-block {
                    padding: 0;

                    label {
                        margin-bottom: 0;

                        &::before {
                            width: 15px;
                            height: 15px;
                            top: 6px;

                            @include bp-lt($small){
                                top: 1Px;
                            }
                        }

                        &::after {
                            width: 7px;
                            height: 7px;
                            top: 10px;
                            left: 4px;

                            @include bp-lt($small){
                                top: 5px;
                            }
                        }

                        span {
                            font-family: $maven;
                            font-size: 15px;
                            color: #000000;
                            letter-spacing: 0.45px;
                            line-height: 28px;

                            @include bp-lt($small){
                                font-size: 12px;
                                line-height: 1.4;
                            }
                        }
                    }
                }

                .checkout-billing-address .fieldset {
                    .control {
                        width: 100%;
                        float: none;
                        position: relative;

                        &::before {
                            content:'\EA24';
                            bottom: auto;
                            top: 50%;
                            transform: rotate(180deg) translateY(50%);
                            font-size: 10px;
                            color: #463B41;
                        }

                        select {
                            margin: 0;
                            height: 50px;
                            padding-right: 30px;
                        }
                    }

                    .actions-toolbar .primary {
                        margin: 0;
                        width: 100%;
                        display: flex;
                        flex-flow: row-reverse wrap;
                        align-items: center;
                        justify-content: center;
                        position: relative;
                    }

                    .actions-toolbar .action.action-cancel {
                        font-family: $maven;
                        font-size: 13px;
                        color: #000000;
                        letter-spacing: 0.39px;
                        background: none;
                        border: none;
                        font-weight: 400;
                        text-decoration: underline;
                        margin-right: 10px;
                        width: auto;
                        min-width: 0;
                        padding: 0;
                        height: auto;
                        line-height: 1;
                        text-transform: none;

                        position: absolute;
                        left: 0;

                        @include bp-lt($small){
                            position: static;
                        }

                        @include bp-lt(335px){
                            margin-top: 10px;
                            margin-right: 0;
                        }

                        &::before {
                            content:'<';
                        }
                    }

                    .billing-address-form .field {
                        position: relative;

                        &.choice {
                            &::before {
                                display: none;
                            }

                            .label {
                                transform: none;
                                position: relative;
                                left: 0;
                                padding: 0;
                                padding-left: 34px;

                                font-family: $maven;
                                font-size: 15px;
                                color: #000000;
                                letter-spacing: 0.45px;
                                line-height: 28px;
                                display: inline-block;
                                word-break: break-word;
                                white-space: normal;
                                text-align: left;
                                box-shadow: none;

                                &::before {
                                    top: 6px;
                                }

                                &::after {
                                    top: 10px;
                                }
                            }
                        }

                        select {
                            background-image: url(#{$img}select-bg.svg);
                            background-size: 10px auto;
                            background-position: center right 20px;
                            background-color: white;
                        }

                        &.street legend.label {
                            width: auto !important;
                            height: auto !important;
                            padding: 1px 7px!important;
                            margin: 0 !important;
                            overflow: hidden !important;
                            clip: auto !important;
                            white-space: nowrap !important;
                            border: 0 !important;
                        }

                        .control._with-tooltip input {
                            width: 100%;
                        }

                        .field-tooltip.toggle {
                            display: none;
                        }

                        &.street .field.additional {
                            margin-top: 0 !important;
                        }

                        &.street .field._required .control input {
                            margin-bottom: 0 !important;
                        }

                        select.select,
                        .control input {
                            height: 50px;
                            margin-bottom: 0;
                            padding: 0 15px;
                            font-family: $maven;
                            font-size: 15px;
                            letter-spacing: 0.45px;
                            color: #000000;
                            box-shadow: none;
                        }

                        .control::before {
                            display: none;
                        }

                        .label {
                            background: white;
                            font-family: $maven;
                            font-size: 10px;
                            color: #000000;
                            letter-spacing: 0.71px;
                            font-weight: 400;
                            float: none;
                            width: auto;
                            padding: 1px 7px;
                            position: absolute;
                            left: 20px;
                            top: 0;
                            transform: translateY(-50%);
                            z-index: 1;

                            &::after {
                                font-size: 10px;
                            }
                        }

                    }
                }

                .label {
                    &::before {
                        width: 15px;
                        height: 15px;
                        top: 10px;
                    }

                    &::after {
                        width: 7px;
                        height: 7px;
                        top: 14px;
                        left: 4px;
                    }
                }
            }
        }

    }

    .new-address-popup {

        margin-top: 0;
        text-align: center;

        // E L E M E N T S
        .action {
            // @extend .c-button--ter;
        }

        .action.primary {
            // @extend .c-button--main;
        }

    }

    .actions-toolbar {
        margin-top: $opc-wrapper-checkoutSteps-padding - 0.5rem;
    }

    #opc-shipping_method {
        position: relative;
        .actions-toolbar {
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
        }
    }

    #checkout-step-payment,
    .table-checkout-shipping-method {


        .radio {
            appearance: none;
            outline: none;
            box-shadow: none;
            background-color: $white;
            border-color: $grey;
            border-radius: 5px 5px 0 0;
            border-style: solid;
            border-width: 1px;
            color: green;
            font-family: $maven;
            font-size: 16px;
            overflow: hidden;

            display: block;
            width: 100%;

            transition-duration: .1s;
            transition-timing-function: ease;
            transition-property: border, background-color;

            &::placeholder, option, optgroup {
                font-family: $maven;
                letter-spacing: em(1.14);
                opacity: 1;
            }

            &::placeholder {
                color: black;
            }

            & ~ div:not(.nested):not(.cartQuantity__btn), & ~ span {
                display: block;
                font-size: 0.65em;
                font-family: $maven;
                line-height: 1.1;
                position: absolute;
                width: 100%;
                float: right;
            }

            & ~ .input__message {
                bottom: 3rem;
                text-align: right;

                &:not(:empty) {
                    margin-bottom: 0.9em;
                }
            }

            & ~ div:not(.nested):not(.cartQuantity__btn), & ~ .help-block {
                top: 100%;
                &:not(:empty) {
                    margin-top: 0.5em;
                }
            }

            &::-ms-clear {
                display: none;
            }

            min-height: 3rem;
            line-height: 3rem;
            padding-top: 0;
            padding-bottom: 0;
            padding-right: 1rem;
            padding-left: 1rem;

            flex-basis: 1rem;
            flex-grow: 0;
            width: 1rem;
            height: 1rem;
            min-height: 0;
            padding-left: 0;
            padding-right: 0;
            margin: 0.2em 0;
            background-color: $white;
            box-shadow: inset 0 0 0 6px $white;
            border-width: 1px;
            border-radius: 50%;

            & + label {
                font-family: $maven;
                font-weight: 700;
                font-size: 15px;
                line-height: (28/15);
                letter-spacing: 0.45px;
                order: inherit;
                flex-basis: 0;
                margin:0;
                background: none;
                text-align: left;
                color: #000000;
                font-weight: 400;
            }

            &:checked {
                background-color: grey;
            }

            & + label {
                white-space: normal;
            }
        }

        thead {
            @include sr-only;
        }

        .row {
            flex-wrap: nowrap;
        }

        .col-method {
            flex: 0 0 1rem;
        }
        .col-price {
            font-weight: $font-weight-bold;
            margin-right: 1rem;
            flex: 0 0 6rem;
        }
        .col-carrier {
            flex: 0 0 auto;
            width: auto;
            max-width: 100%;
        }

    }

    // M E D I A   Q U E R I E S
    @include bp-gt($opc-wrapper-MQL)
    {
        // E l e m e n t s
        #checkoutSteps {
            .step-title {
                text-align: left;
            }
            li.checkout-payment-method {
                .field-select-billing .control::before {
                    right: 1rem;
                }
            }
        }

        .new-address-popup {
            text-align: left;
        }

    };

}

#checkout-shipping-method-load {
    .table-checkout-shipping-method {
        width: 100%;
        min-width: 0;
        border: 0;
    }

    .col.col-method:first-child {
        width: 35px;
        padding-left: 20px;
    }

    .col.col-method input {
        left: auto;

        &[type=radio],
        &[type=checkbox] {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
        }

        /**
        * Giving Checkbox & Radio not checked a new appearance
        */

        &[type=radio],
        &[type=checkbox] {
            width: 15px;
            height: 15px;
            border: solid 1px #E5DFDF;
            position: relative;
            margin: 0;
        }

        /**
        * Giving Checkbox & Radio checked state a new appearance
        */

        &[type=radio]:checked::before,
        &[type=checkbox]:checked::before {
            content: "";
            width: 7px;
            height: 7px;
            background-color: #CF5959;
            position: absolute;
            top: 3px;
            left: 3px;
        }

        /**
        * Rounding Radio inputs new appearance
        */

        &[type=radio],
        &[type=radio]:checked::before {
            border-radius: 100%;
        }
    }

    tr td, tr td .price {
        font-family: $maven;
        font-weight: 400;
        font-size: 15px;
        color: #000000;
        letter-spacing: 0.45px;
        line-height: 1;
    }

    .col.col-carrier {
        display: none;
    }
}

.opc-wrapper #co-shipping-method-form {
    background: none;
    border: 0;
    padding: 0;
    margin: 0;

    .actions-toolbar {
        position: static;

        .primary button {
            display: inline-block;
            margin: 0;
            height: rem(57);
            width: auto;
            min-width: rem(200);
            padding-left: rem(30);
            padding-right: rem(30);
            border-top: none;
            border-left: none;
            border-right: none;
            border-bottom: solid rem(2);
            text-transform: uppercase;
            text-align: center;
            font-family: $maven;
            font-size: rem(14);
            font-weight: 700;
            letter-spacing: rem(1);
            transition: $ease;
            background: $corail;
            color: $white;
            line-height: 1;
            border-bottom-color: $corail--dark;

            &:hover {
                background: $mainBranding;
                color: $white;
                border-bottom-color: $mainBranding--darken--02;
            }
        }
    }
}

.checkout-success.message-box.checkout-onepage-success-order {

    .action.primary.continue.c-button--corail {
        display: flex;
        align-items: center;
        text-decoration: none;
    }
}

.checkout-payment-method .payment-method._active {
    margin-bottom: 20px;
}

.checkout-payment-method .payment-method._active .payment-method-content {
    display: block
}

.checkout-payment-method .payment-method-content {
    display: none;
    padding: 0 25px;
}
.opc-wrapper #checkoutSteps li.checkout-payment-method .payment-method .checkout-billing-address .fieldset .field.field-select-billing,
.opc-wrapper #checkoutSteps li.checkout-payment-method .payment-method .checkout-billing-address .fieldset .billing-address-form .field {
    margin-bottom: 15px;
}

.opc-wrapper #checkoutSteps li.checkout-payment-method .payment-method .checkout-billing-address .fieldset .billing-address-form .fieldset.address {
    padding: 0;
}

.opc-wrapper #checkoutSteps li.checkout-payment-method .field.billing-address-same-as-shipping-block + fieldset  {
    margin: 0;
    padding: 0;

    .label {
        background: none;
        color: black;
    }

    .field.field-select-billing .label {
        font-family: "Maven Pro",sans-serif;
        font-size: 10px;
        color: #000;
        letter-spacing: 0.71px;
        font-weight: 400;
        width: 100%;
        text-align: left;
        margin: 0 0 10px;
    }

    .field.field-select-billing .control select {
        background-color: white;
    }

    .field.field-select-billing .control::before {
        display: none;
    }
}

.opc-wrapper #checkoutSteps li.checkout-payment-method .checkout-billing-address {
    margin: 0 0 20px
}