// ========================================= //
// magento.checkout
// ========================================= //

.checkout-onepage-index {
    #checkoutSteps {
        ol, ul { list-style: none; }

        p {
            margin-bottom: rem(24);
            font-size: rem(15);
            font-family: $maven;
            color: $colorFont;
        }

        a {
            color: $colorLink;
            text-decoration: underline;
        }
    }

    ol.opc {
        display: block;
        max-width: rem(900);
        margin: 0 auto;
        list-style: none;
        background: $white;
        border: $border;

        li {
            @include clearfix;

            .step-title {
                opacity: .5;
                background: $mainBranding;
                font-family: $maven;
                a { display: none; }
            }

            .number {
                color: $white;
                font-family: $maven;
                background: darken($subBranding, 10%);
            }

            &.active {
                .step-title {
                    opacity: 1;
                    background: $mainBranding;
                }

                .number {
                    color: $white;
                    background: darken($subBranding, 10%);
                }
            }

            &:not(.active) {}

            &.section.allow:not(.active) {
                opacity: .5;
                cursor: pointer;
                transition: all .3s ease;
                &:hover { opacity: 1; }

                a {
                    position: absolute;
                    right: 0; top: 0;
                    display: block;
                    line-height: rem(40);
                    height: rem(40);
                    padding: rem(0) rem(15);
                    color: $white;
                    text-decoration: underline;
                    font-size: rem(14);

                    @include bp-lt($small) {
                        height: rem(50);
                        line-height: rem(50);
                    }

                    @include bp-lt($xsmall) {
                        display: none;
                    }
                }
            }
        }

        .buttons-set {
            position: static;
            padding-top: rem(20);
            width: 100%;
            @include clearfix();

            .back-link {
                float: left;

                a {
                    color: $mainBranding !important;
                    text-decoration: none !important;
                    margin-top: 0 !important;

                    &:hover {
                        color: $white !important;
                    }
                }
            }

            .button { float: right !important; }
        }
    }

    .step-title {
        position: relative;
        padding: rem(5) rem(10) rem(5) rem(70);
        height: rem(40);

        @include bp-lt($small) {
            padding: rem(7) rem(10) rem(5) rem(40);
        }

        @include bp-lt($xsmall) {
            padding-left: rem(10);
        }

        .number {
            position: absolute;
            width: rem(50);
            left: 0; top: 0;
            text-align: center;
            height: 100%;
            font-size: rem(20);
            line-height: rem(20);
            padding-top: rem(10);

            @include bp-lt($small) {
                width: rem(30);
                font-size: rem(20);
                line-height: rem(20);
                padding-top: rem(10);
                font-size: rem(14);
            }

            @include bp-lt($xsmall) {
                display: none;
            }
        }

        h2 {
            display: inline-block;
            color: $white;
            font-size: rem(18);
            font-weight: 600;
            letter-spacing: rem(1);
            text-transform: uppercase;

            @include bp-lt($small) {
                font-size: rem(13);
            }
        }
    }

    .step {
        padding: rem(20);

        h3 {
            font-size: rem(20);
        }

        fieldset {
            margin: rem(5) 0;
        }
    }

    .buttons-set {
        @include clearfix;
        max-width: 320px;
        width: 100%;
        margin: rem(20) auto 0;

        @include bp-lt($small) {
            padding: 0 $gutter/2;
        }

        &#billing-buttons-container {
            padding-top: rem(15);
        }

        &#billing-buttons-container,
        &#shipping-buttons-container,
        &#shipping-method-buttons-container,
        &#boutique-buttons-container,
        &#payment-buttons-container {
            max-width: 100%;
            border-top: rem(1) dashed $grey--02;

            .button {
                float: left;
            }

            a { margin-top: rem(15); }
        }

        &#review-buttons-container {
            max-width: 100%;

            .f-left {
                float: left;

                a { margin-left: rem(5); }
            }
        }

        a {
            float: right;
            margin-bottom: rem(15);
        }
    }

    .fieldset {
        margin-bottom: 0;
        padding: 0 $gutter/2;

        > ul {
            margin-left: 0;
        }
    }
}

#login-form {
    .input-text {
        margin-bottom: rem(5);
    }

    .form-list {
        @include bp-lt($small) {
            margin: 0;
        }
    }
}


#checkout-shipping-method-load,
#checkout-review-load {
    @include bp-lt($small) {
        padding: 0 $gutter/2;
    }
}

#checkout-review-table {
    margin-bottom: rem(20);
    width: 100%;

    td, th {
        border: solid rem(1) $grey--06;
    }

    thead {
        tr {
            th {
                background: $subBranding--lighten--01;
                height: rem(48);
                font-size: rem(15);
                font-weight: 700;
                font-family: $maven;
                color: $white;
                font-size: italic;
                border-left-color: $subBranding--lighten--02;
                border-right-color: $subBranding--lighten--02;

                &:first-of-type { border-left-color: $grey--06; }
                &:last-of-type { border-right-color: $grey--06; }
            }
        }
    }

    tbody {
        tr:nth-child(even) {
            background: $grey--05;
        }

        td {
            height: rem(48);
            font-size: rem(15);
            font-family: $maven;
        }
    }

    tfoot {
        tr.last {
            background: $mainBranding;

            td {
                color: $white;
                font-weight: bold;
                font-size: 1.1rem;
            }
        }
    }

    tr { background: $white; }

    .a-center { text-align: center; }
    .a-right { text-align: right; }
}

.checkout-agreements.fieldset {
    margin: 0;
    padding: 0;

    .checkout-agreement.field {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        input {
            top: 0;
        }
    }

    .checkout-agreement input + label {
        line-height:1;

        button {
            margin: 0;
            background: none;
            height: auto;
            line-height: 1;
            min-height: 0;
            padding: 0;
            display: inline;
        }
    }

    .fieldset > .field {
        margin-bottom: 0;
    }
}
.checkout-agreements {
    p.agree {
        label {
            margin-bottom: 0;
        }
    }
}
